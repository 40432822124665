import React from 'react'
import style from './Leave.module.scss'
import {Fab, Paper, Tooltip, Typography} from "@mui/material";
import * as cMap from "../../../store/stateStr/CommMap";
import RemoveIcon from "@mui/icons-material/Remove";
import {useDispatch} from "react-redux";
import {onWorkDataDelete} from "../../../store/actions/WorkData";

const Leave = ({workdata, idx_day, idx_comm}) => {

    const dispatch = useDispatch()

    console.debug(workdata)
    console.debug(workdata.get('locked'))

    return <Paper className={style.leave} >
        <Typography>
            {workdata.get('paid_leave') === 1 ?
                "Permesso" :
                "Ferie"
            }
        </Typography>
        <Tooltip title={'Cancella commessa'} style={{margin:'auto 0 auto auto'}}>
            <div>
                <Fab
                    // style={{backgroundColor: theme.palette.secondary.main}}
                    color={"secondary"}
                    className={style.circButton}
                    disabled={workdata.get('locked') >= 20}
                    onClick={() => dispatch(onWorkDataDelete(idx_day, idx_comm, workdata.get(cMap.DbKey), workdata.get('locked')))}
                >
                    <RemoveIcon/>
                </Fab>
            </div>
        </Tooltip>
    </Paper>
}

export default Leave;
