import apiV2 from "../../apiV2";
import * as actionType from "./action";
import {enqueueSnackbar} from "./action";
import {fromJS} from "immutable";

export const downloadFdlDataExport = (integration, month, year, onlyData, definitive) => {
    return (dispatch, getState) => {
        apiV2.get(`workdata_fdl_export?integration=${integration}&month=${month}&year=${year}&onlyData=${onlyData}&lockData=${definitive}`, {responseType: 'arraybuffer'})
            .then((res) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/octet-stream'}),);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `data_export_${integration}_${month}-${year}.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch((err) => {
                let message = ''
                if (err.response) {
                    if (err.response.status === 400) message = 'Nessun tariffario valido'
                }
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                }))
            })
    }
};

export const getFdlDataExport = (integration, month, year) => {
    return (dispatch, getState) => {
        apiV2.get(`workdata_fdl_export?integration=${integration}&month=${month}&year=${year}&onlyData=true`)
            .then((res) => {
                dispatch({
                    type: actionType.UPDATE_FDL_EXPORT_DATA,
                    data: fromJS(res.data)
                })
            });
    }
};

export const downloadWdExport = (month, year, definitive) => {
    return (dispatch, getState) => {
        apiV2.get(`workdataExport?month=${month}&year=${year}&onlyData=false&lockData=${definitive}`, {responseType: 'arraybuffer'})
            .then((res) => {
                console.debug(res)
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/octet-stream'}),);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `data_export_${month}-${year}.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch((err) => {
                let message = ''
                if (err.response) {
                    if (err.response.status === 400) message = 'Nessun tariffario valido'
                }
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                }))
            })
    }
};