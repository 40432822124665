import React, {useEffect, useState} from "react";
import './CommissionDialog.scss'
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../Library/TabPanel/TabPanel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import {checkValidity} from "../../Library/Utils/Utils";
import CommStat from "../../Library/CommStat/CommStat";
import {List, Map} from "immutable";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Integration from "./Integration/Integration";
import {useDispatch} from "react-redux";
import {deleteCommission, sendIntegration, update_comm} from "../../../store/actions/MngData";
import ConfirmPopUp from "../../Library/ConfirmPopUp/ConfirmPopUp";

const CommissionDialog = ({commission, edit, onClosePopUp}) => {

    const dispatch = useDispatch()

    const [actTab, setActTab] = useState('0')
    const [tempCommission, setTempCommission] = useState(Map({}))
    const [popUpDeleteCommission, setPopUpDeleteCommission] = useState(false)
    const [popUpDeleteIntegration, setPopUpDeleteIntegration] = useState(false)
    const [popUpForceClose, setPopUpForceClose] = useState(false)

    useEffect(() => {
        setTempCommission(commission)
        setPopUpDeleteCommission(false)
        setPopUpDeleteIntegration(false)

    }, [commission])

    // region commission
    const onAcceptDeleteCommission = () => {
        dispatch(deleteCommission(
            tempCommission.get('client_code'),
            tempCommission.get('commission_code'))
        ).then(() => {
            onForceCLoseDialog()
        })
    }

    const onValueChange = (key, value) => {
        if (key === 'code') {
            value = value.substring(0, 4) + value.substring(7, 11)
        }
        setTempCommission(old => old.updateIn([key], () => value))
    }

    const onSaveComm = (close) => {
        dispatch(update_comm(commission.get('commission_code'), tempCommission)).then(data => {
            if (close) {
                onForceCLoseDialog()
            } else {
                setTempCommission(data)
            }
        })
    }
    // endregion


    // region integration
    const onNewIntegration = () => {
        let next_intg = '01'
        if (tempCommission.get('integrations', List([])).size > 0) {
            next_intg = tempCommission.get('integrations').maxBy(intg => intg.get('integration_code')).get('integration_code')
            next_intg = parseInt(next_intg) + 1
            next_intg = `0${next_intg}`
            next_intg = next_intg.substring(next_intg.length - 2)
        }
        setTempCommission(comm => comm.updateIn(['integrations'], i => i.push(Map({
            client_code: comm.get('client_code'),
            commission_code: comm.get('commission_code'),
            integration_code: next_intg
        }))))
    }

    const onAcceptDeleteIntegration = () => {
        const integrationIndex = tempCommission.getIn(['integrations']).findIndex(i => i.get('integration_code') === actTab.substring(actTab.length - 2))
        setTempCommission(comm => comm.updateIn(['integrations'], i => i.delete(integrationIndex)))
        setPopUpDeleteIntegration(false)
        setActTab('0')
    }

    const onIntgValueChange = (intg_index, key, value) => {
        if (key === 'integration_code') {
            setActTab(`${tempCommission.get('client_code')}${tempCommission.get('commission_code')}_${value}`)
        }
        setTempCommission(old => old.updateIn(['integrations', intg_index, key], () => value))
    }

    // endregion


    const onCLoseDialog = () => {
        if (!commission.equals(tempCommission) && edit) {
            setPopUpForceClose(true)
        } else {
            onForceCLoseDialog()
        }
    }

    const onForceCLoseDialog = () => {
        setPopUpForceClose(false)
        setActTab('0')
        onClosePopUp()
    }


    let integrations = null
    let tabs = null
    if (tempCommission.get('integrations')) {
        integrations = tempCommission.get('integrations').map((intg, index) => {
            return (
                <TabPanel
                    key={`Tab_${intg.get('client_code')}${intg.get('commission_code')}_${intg.get('integration_code')}`}
                    value={actTab}
                    index={`${intg.get('client_code')}${intg.get('commission_code')}_${intg.get('integration_code')}`}>
                    <Integration
                        key={`i_${intg.get('client_code')}${intg.get('commission_code')}_${intg.get('integration_code')}`}
                        integration={intg}
                        index={index}
                        onValueChange={(...args) => onIntgValueChange(...args)}
                        edit={edit}
                    />
                </TabPanel>
            )
        })
        tabs = tempCommission.get('integrations').sort((a, b) => {
            if (a.get('integration_code') < b.get('integration_code')) {
                return -1;
            }
            if (a.get('integration_code') > b.get('integration_code')) {
                return 1;
            }
            if (a.get('integration_code') === b.get('integration_code')) {
                return 0;
            }
        }).map((intg) => {
            return (
                <Tab key={'Tab' + intg.get('integration_code')}
                     value={`${intg.get('client_code')}${intg.get('commission_code')}_${intg.get('integration_code')}`}
                     label={`${intg.get('client_code')}${intg.get('commission_code')}_${intg.get('integration_code')}`}
                />)
        })
    }

    let actIntegration = null
    if (actTab !== '0') {
        const _intgs = tempCommission.get('integrations')
        actIntegration = _intgs?.getIn([_intgs.findIndex(i => i.get('integration_code') === actTab.substring(actTab.length - 2))])
    }


    return (
        <>
            <Dialog open={Boolean(tempCommission.get('client_code'))}
                    onClose={onCLoseDialog}
                    className={'commissions_details'}
                    maxWidth={"lg"}
            >
                {/*<DialogTitle>*/}
                {/*    {`${tempCommission.get('client_code')}${tempCommission.get('commission_code')}`}*/}
                {/*</DialogTitle>*/}
                {/*<Divider/>*/}
                <DialogContent className={'content'}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={actTab}
                            variant="scrollable"
                            onChange={(event, value) => setActTab(value)}
                            scrollButtons="auto"
                            textColor={'secondary'}
                        >
                            <Tab color={'secondary'}
                                 label={`${tempCommission.get('client_code')}${tempCommission.get('commission_code')}`}
                                 value={'0'}/>
                            {tabs}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={actTab} index={'0'}>
                        <div className={'panel'}>
                            <Paper variant={'outlined'} className={'general'} transparent>
                                <Typography className={'title'}>Generali</Typography>
                                <Divider/>
                                <TextField
                                    label={'Codice commessa'}
                                    variant={"outlined"}
                                    type={'number'}
                                    value={tempCommission.get('commission_code', ' ')}
                                    margin={"dense"}
                                    size={"medium"}
                                    className={'textfield'}
                                    color={'secondary'}
                                    disabled={!edit}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (edit) {
                                            if (value.length <= 4) {
                                                onValueChange('commission_code', event.target.value)
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment
                                            position="start">{tempCommission.get('client_code', '')}</InputAdornment>,
                                    }}
                                    error={!checkValidity(tempCommission.get('commission_code', ' '), {
                                        required: true,
                                        isPureNc: true
                                    })}
                                />
                                <TextField
                                    label={'Descrizione'}
                                    variant={"outlined"}
                                    value={tempCommission.get('description')}
                                    margin={"dense"}
                                    size={"medium"}
                                    className={'textfield'}
                                    multiline
                                    rows={6}
                                    color={'secondary'}
                                    disabled={!edit}
                                    onChange={(event) => {
                                        if (edit) {
                                            onValueChange('description', event.target.value)
                                        }
                                    }}
                                />
                            </Paper>
                            <CommStat
                                statistics={tempCommission.getIn(['statistics', 'stat'], Map({})) || Map({})}/>
                        </div>
                    </TabPanel>
                    {integrations}
                </DialogContent>
                {edit &&
                    <DialogActions className={'action'}>
                        <div className={'leftActions'}>
                            <Button
                                variant={"contained"}
                                // className={'action_button'}
                                onClick={onNewIntegration}
                                className={'action_button'}
                                color="secondary"
                                startIcon={<AddCircleRoundedIcon/>}
                            > Aggiungi integrazione
                            </Button>
                            {actTab === '0' ?
                                <Button
                                    variant={"contained"}
                                    // className={'action_button'}
                                    onClick={() => setPopUpDeleteCommission(true)}
                                    className={'action_button'}
                                    startIcon={<DeleteRoundedIcon/>}
                                    color={'error'}
                                > Elimina commessa
                                </Button>
                                :
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={'action_button'}
                                        style={{
                                            backgroundColor: actIntegration?.get('sent') ? '' : '#8e24aa',
                                            color: actIntegration?.get('sent') ? '' : '#FFF'
                                        }}
                                        endIcon={actIntegration?.get('sent') ? <CheckCircleRoundedIcon/> :
                                            <SendRoundedIcon/>}
                                        onClick={() => dispatch(sendIntegration(actIntegration.get('client_code'), actIntegration.get('commission_code'), actIntegration.get('integration_code')))}
                                    >
                                        {actIntegration?.get('sent') ? 'Notifica inviata' : 'invia notifica'}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        onClick={() => setPopUpDeleteIntegration(true)}
                                        className={'action_button'}
                                        color={'error'}
                                        startIcon={<DeleteRoundedIcon/>}
                                    > Elimina integrazione
                                    </Button>
                                </>
                            }
                        </div>
                        <div className={'rightActions'}>
                            <Button
                                variant={"outlined"}
                                color="secondary"
                                className={'action_button'}
                                onClick={onCLoseDialog}
                            > Annulla
                            </Button>
                            <Button
                                variant={"contained"}
                                color="secondary"
                                className={'action_button'}
                                onClick={() => onSaveComm(true)}
                            > Salva e chiudi
                            </Button>
                            <Button
                                variant={"contained"}
                                color="secondary"
                                className={'action_button'}
                                onClick={() => onSaveComm(false)}
                            > Salva
                            </Button>
                        </div>
                    </DialogActions>
                }
            </Dialog>

            <ConfirmPopUp
                open={popUpDeleteIntegration}
                handleAccept={onAcceptDeleteIntegration}
                handleDecline={() => setPopUpDeleteIntegration(false)}
                title={`Eliminazione integrazione`}
                description={`Sei siscuro di voler eliminare questa integrazione?`}
                ko={'Annulla'}
                ok={'Conferma'}
            />
            <ConfirmPopUp
                open={popUpDeleteCommission}
                handleAccept={onAcceptDeleteCommission}
                handleDecline={() => setPopUpDeleteCommission(false)}
                title={`Eliminazione commessa`}
                description={`Sei siscuro di voler eliminare questa commessa?`}
                ko={'Annulla'}
                ok={'Conferma'}
            />
            <ConfirmPopUp
                open={popUpForceClose}
                handleAccept={() => onSaveComm(true)}
                handleDecline={() => onForceCLoseDialog()}
                title={`Chiudere senza salvare`}
                description={`Sei siscuro di voler chiudere senza salvare?`}
                ko={'Chiudi senza salvare'}
                ok={'Salva'}
            />

        </>
    )
}

export default CommissionDialog;