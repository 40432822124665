import React, {useEffect, useState} from 'react';
import style from './WdFdlExport.module.scss';
import MonthPicker from "../Library/MonthPicker/MonthPicker";
import moment from "moment/moment";
import {Autocomplete, Button, FormGroup, TextField} from "@mui/material";
import {downloadFdlDataExport} from "../../store/actions/exportData";
import {useDispatch, useSelector} from "react-redux";
import {getAllFDL} from "../../store/actions/MngData";
import {Alert, AlertTitle} from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ConfirmPopUp from "../Library/ConfirmPopUp/ConfirmPopUp";

const WdFdlExport = () => {

    const dispatch = useDispatch()

    const fdlList = useSelector(state => state.mng_data.fdl)
    const clients = useSelector(state => state.mng_data.mng_data)

    const [date, setDate] = useState(moment().month(moment().month() - 1).startOf('month'))
    const [integration, setIntegration] = useState('')
    const [definitiveExport, setDefinitiveExport] = useState(false)
    const [confirmExport, setConfirmExport] = useState(false)

    useEffect(() => {
        dispatch(getAllFDL())
    }, [])


    const monthCommission = fdlList.filter(f => moment(f.get('emission'), 'DD-MM-YYYY') >= date.startOf('month') && moment(f.get('emission'), 'DD-MM-YYYY') <= date.endOf('month') && f.getIn(['integration', 'contract_type']) === 2)
        .map(f => `${f.get('client_code')}${f.get('commission_code')}_${f.get('integration_code')}`)
        .toSet()
        .toList()
    const monthCommissionWithoutContractType = fdlList.filter(f => moment(f.get('emission'), 'DD-MM-YYYY') >= date.startOf('month') && moment(f.get('emission'), 'DD-MM-YYYY') <= date.endOf('month') && (f.getIn(['integration', 'contract_type']) !== 2 && f.getIn(['integration', 'contract_type']) !== 1))
        .map(f => `${f.get('client_code')}${f.get('commission_code')}_${f.get('integration_code')}`)
        .toSet()
        .toList()

    return <div className={style.wdFdlExport}>
        <div className={style.filterBar}>
            <MonthPicker
                value={date}
                onChange={(n) => {
                    setDefinitiveExport(false)
                    setDate(n)
                }}
            />
            <Autocomplete
                freeSolo
                disableClearable
                options={monthCommission.sortBy(c => c).toJS()}
                groupBy={(option) => option.substring(0, 4)}
                getOptionDisabled={(option) => !Boolean(clients.find(c => c.get('client_code') === option.substring(0, 4))
                    ?.get('rate_plans')
                    .filter(rp => moment(rp.get('valid_from'), 'DD-MM-YYYY').startOf('month') <= date.startOf('month') && moment(rp.get('valid_to'), 'DD-MM-YYYY').startOf('month') >= date.endOf('month')).size)}
                value={integration}
                onBlur={(e) => {
                    setIntegration(e.target.value)
                }}

                className={style.commissionFilter}
                renderInput={(params) => (<TextField
                    {...params}
                    label="Commessa"
                    size={"small"}
                    InputProps={{
                        ...params.InputProps, type: 'search',
                    }}
                />)}
            />
            {/*<TextField*/}
            {/*    label={'Integrazione'}*/}
            {/*    value={integration}*/}
            {/*    onChange={e => setIntegration(e.target.value)}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*    onClick={() => dispatch(getFdlDataExport(integration, date.month() + 1, date.year()))}*/}
            {/*>*/}
            {/*    Visualizza dati*/}
            {/*</Button>*/}
            <FormControlLabel
                control={<Checkbox checked={definitiveExport}/>}
                label="Export definitivo"
                disabled={date.startOf('month') >= moment().startOf('month')}
                onChange={() => setDefinitiveExport(old => !old)}
            />
            <Button
                onClick={() => {
                    if (definitiveExport) {
                        setConfirmExport(true)
                    } else {
                        dispatch(downloadFdlDataExport(integration, date.month() + 1, date.year(), false, false))
                    }
                }}
                disabled={integration === '' }
            >
                Export
            </Button>
        </div>
        <div>
            {monthCommissionWithoutContractType.size > 0 &&
                <Alert severity="error" sx={{margin: '10px 10px auto 10px'}}>
                    <AlertTitle>Attenzione</AlertTitle>
                    Queste commesse hanno degli fdl collegati ma non è stato specificato se sono a corpo o a
                    rendicontazione
                    {monthCommissionWithoutContractType.map(c => <><br/><strong>- {c}</strong></>)}
                </Alert>}
        </div>
        <ConfirmPopUp
            open={confirmExport}
            handleAccept={() => {
                dispatch(downloadFdlDataExport(integration, date.month() + 1, date.year(), false, true))
                setConfirmExport(false)
            }}
            handleDecline={() => {
                setDefinitiveExport(false)
                setConfirmExport(false)
            }}
            title={`Generazioen export definitivo`}
            description={`Attenzaione generando un export definitivo gli utenti non potranno più modificare le ore inserite (appartenenti al filtro selezionato)!!!`}
            ko={'Annulla'}
            ok={'Genera'}
        />
    </div>
}

export default WdFdlExport