import React, {Component} from "react";
import './FdlMng.scss'
import {
    add_comm,
    addFDL,
    deleteFDL,
    getAllFDL,
    getAllUsers,
    getCommissionCodes,
    saveFdlChanges,
    sendFDL,
    updateFDL
} from "../../store/actions/MngData";
import {connect} from "react-redux";
import FdlTable from "../Library/FdlTable/FdlTable";
import PopUpNewFdl from "../Library/PopUpNewFdl/PopUpNewFdl";
import {Autocomplete, Button, Checkbox, FormControlLabel, Paper, TextField, Tooltip} from "@mui/material";
import {fromJS, List, Map} from "immutable";
import YearPicker from "../Library/YearPicker/YearPicker";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ConfirmPopUp from "../Library/ConfirmPopUp/ConfirmPopUp";
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import FdlExport from "./FdlExport/FdlExport";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {isNull} from "lodash";

class FdlMng extends Component {

    state = {
        popUpNewFdl: false,
        fdlChange: List([]),
        render: true,
        filters: Map({
            fdlCode: '',
            technician: [],
            commission: '',
            validated: true,
            notValidated: true,
            signed: true,
            notSigned: true,
            used: true,
            notUsed: true,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth(),
        }),
        popUpDeleteFDLCode: '',
        EditRoundedIcon:'',
        startFDL: {}
        // fdlList: List([])
    }

    constructor() {
        super();
        window.addEventListener("resize", this.debounce((e) => {
            this.setState({render: true})
        }));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debounce((e) => {
            this.setState({render: true})
        }))
    }

    debounce = (func) => {
        var timer;
        return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, 300, event);
        };
    }

    componentDidMount() {
        this.props.getAllFDL()
        //     .then(res => {
        //     console.debug(res)
        //     this.setState({fdlList: res})
        // })
        this.props.getAllUsers()
        this.props.getCommissionCodes()
    }

    cellUpdateHandle = (code, property, value) => {
        const change = fromJS({code, [property]: value})
        if (Boolean(this.state.fdlChange.find(f => f.get('code') === code))) {
            this.setState(old => ({fdlChange: old.fdlChange.update(old.fdlChange.findIndex(f => f.get('code') === code), f => f.merge(change))}))
        } else {
            this.setState(old => ({fdlChange: old.fdlChange.push(change)}))
        }
        this.props.updateFDL(code, property, value).then(() => {
            const oldFdl = this.props.fdlList.get(this.props.fdlList.findIndex(f => f.get('code') === code))
            if (property === 'validated' && value === true && oldFdl.get('not_signed')) this.cellUpdateHandle(code, 'not_signed', false)
            if (property === 'validated' && value === true && oldFdl.get('not_used')) this.cellUpdateHandle(code, 'not_used', false)

            if (property === 'not_signed' && value === true && oldFdl.get('validated')) this.cellUpdateHandle(code, 'validated', false)
            if (property === 'not_signed' && value === true && oldFdl.get('not_used')) this.cellUpdateHandle(code, 'not_used', false)

            if (property === 'not_used' && value === true && oldFdl.get('validated')) this.cellUpdateHandle(code, 'validated', false)
            if (property === 'not_used' && value === true && oldFdl.get('not_signed')) this.cellUpdateHandle(code, 'not_signed', false)

        })
        // this.setState(old => ({
        //     fdlList: old.fdlList.update(old.fdlList.findIndex(f => f.get('code') === code), f => f.update(property, () => value))
        // }))
    }


    popUpNewFdl = (state, data) => {
        if (data !== undefined) {
            const newCode = this.props.fdlList.filter(f => f.get('code').includes(data.code.substring(0, 6))).max((f1, f2) => parseInt(f1.get('code').substring(7, 9)) > parseInt(f2.get('code').substring(7, 9))).get('code')
            const base = newCode.substring(0, 7)
            const progressive = parseInt(newCode.substring(7, 9)) + 1
            data.code = base + progressive.toString().padStart(2, '0')
        }
        if (!state) {
            this.setState({popUpNewFdl: state, startFDL: {}})
        } else {
            this.setState({popUpNewFdl: state, startFDL: data})

        }
    }


    render() {

        const columns = [
            {
                Header: 'Codice',
                accessor: 'code',
                id: 'fdlCode',
                minWidth: 110,
                maxWidth: 110
            },
            {
                Header: 'Tecnico',
                id: 'Tecnico',
                accessor: d => {
                    const user = this.props.users.find(u => u.get('id') === d.technician)
                    if (user) {
                        return `${user.get('surname')} ${user.get('name')}`
                    }
                },
                Cell: row => <td style={{textAlign: 'left'}}>{row.value}</td>,
                minWidth: 200,
                width: 200,
                maxWidth: 500
            },
            {
                Header: 'Data di emissione',
                accessor: 'emission',
                minWidth: 130,
                width: 130
            },
            {
                Header: 'Commessa',
                accessor: d => `${d.client_code}${d.commission_code}_${d.integration_code}`,
                minWidth: 125,
                width: 125
            },
            {
                Header: 'Validato',
                accessor: 'validated',
                width: 100,
                minWidth: 100
            },
            {
                Header: 'Data validazione',
                accessor: 'validated_date',
                width: 130,
                minWidth: 130,
            },
            {
                Header: 'NF',
                accessor: 'not_signed',
                width: 75,
                minWidth: 75
            },
            {
                Header: 'Data NF',
                accessor: 'not_signed_date',
                width: 130,
                minWidth: 130,
            },
            {
                Header: 'NU',
                accessor: 'not_used',
                width: 75,
                minWidth: 75
            },
            {
                Header: 'Data NU',
                accessor: 'not_used_date',
                width: 130,
                minWidth: 130,
            },
            {
                Header: 'Descrizione',
                accessor: 'description',
                minWidth: 150,
                maxWidth: 452,
                width: 520
                // minWidth: 200
            },
            {
                // Header: 'Action',
                accessor: 'code',
                width: 180,
                maxWidth: 210,
                Cell: row => <div
                    style={{display: 'flex', textAlign: 'right', justifyContent: 'flex-end'}}>
                    {!isNull(row.row.original.sent) ? <Tooltip title="FDL inviato">
                            <CheckRoundedIcon style={{
                                height: '35px',
                                width: '35px',
                                color: '#43a047',
                                margin: '7px'
                            }}/>

                        </Tooltip>
                        : null}
                    <Tooltip title="Invia FDL">
                        <IconButton aria-label="delete" disabled={!this.props.fdlManagement}
                                    onClick={() => {
                                        this.props.sendFDL(row.value)
                                    }}>
                            <SendRoundedIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplica FDL">
                        <IconButton aria-label="delete" disabled={!this.props.fdlManagement}
                                    onClick={() => {
                                        this.popUpNewFdl(true, row.row.original)
                                    }}>
                            <LibraryAddRoundedIcon
                                color={this.props.fdlManagement ? 'secondary' : 'disabled'}/>
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Modifica FDL">*/}
                    {/*    <IconButton aria-label="edit" disabled={!this.props.fdlManagement}*/}
                    {/*                onClick={() => {*/}
                    {/*                    this.setState({popUpEditFDLCode: row.value})*/}
                    {/*                }}>*/}
                    {/*        <EditRoundedIcon*/}
                    {/*            color={this.props.fdlManagement ? '' : 'disabled'}/>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    <Tooltip title="Elimina FDL">
                        <IconButton aria-label="delete" disabled={!this.props.fdlManagement}
                                    onClick={() => {
                                        this.setState({popUpDeleteFDLCode: row.value})
                                    }}>
                            <DeleteRoundedIcon
                                color={this.props.fdlManagement ? 'error' : 'disabled'}/>
                        </IconButton>
                    </Tooltip>

                </div>,
                // minWidth: 50,
            },
        ]

        const data = this.props.fdlList.filter(f => {
            if (
                (
                    this.state.filters.get('fdlCode') === '' ||
                    f.get('code').includes(this.state.filters.get('fdlCode'))
                ) && (
                    this.state.filters.get('technician').length === 0 ||
                    this.state.filters.get('technician').includes(f.get('technician')?.toString())
                ) &&
                (
                    f.get('validated') === this.state.filters.get('validated') ||
                    f.get('validated') !== this.state.filters.get('notValidated')
                ) &&
                (
                    f.get('not_signed') !== this.state.filters.get('signed') ||
                    f.get('not_signed') === this.state.filters.get('notSigned')
                ) &&
                (
                    f.get('not_used') !== this.state.filters.get('used') ||
                    f.get('not_used') === this.state.filters.get('notUsed')
                ) &&
                (
                    // this.state.filters.get('commission').includes(`${f.get('client_code')}${f.get('commission_code')}_${f.get('integration_code')}`)

                    (f.get('client_code')?.includes(this.state.filters.get('commission').slice(0, 4)) || this.state.filters.get('commission').slice(0, 4) === '') &&
                    (f.get('commission_code')?.includes(this.state.filters.get('commission').slice(4, 8)) || this.state.filters.get('commission').slice(0, 8) === '') &&
                    (f.get('integration_code')?.includes(this.state.filters.get('commission').slice(9, 12)) || this.state.filters.get('commission').slice(0, 12) === '')
                    // (f.get('commission_code') === this.state.filters.get('commission').slice(4, 8) || this.state.filters.get('commission').slice(0, 8) === '') &&
                    // (f.get('commission_code') === this.state.filters.get('commission').slice(9, 12) || this.state.filters.get('commission').slice(0, 12) === '')

                ) &&
                (
                    moment(f.get('emission'), "DD-MM-YYYY").year() === this.state.filters.get('selectedYear')
                ) &&
                (
                    moment(f.get('emission'), "DD-MM-YYYY").month() === this.state.filters.get('selectedMonth') || (this.state.filters.get('selectedMonth') === null)
                )

            ) {
                return true
            }
        })


        return (
            <>
                <div className={'fdlMng'} ref={(divElement) => {
                    this.divElement = divElement
                }}>
                    {
                        this.props.fdlList.size > 0 &&
                        <FdlTable
                            editPermission={this.props.fdlManagement}
                            height={this.divElement?.clientHeight}
                            columns={columns}

                            data={data.toJS()}
                            onCellChange={this.cellUpdateHandle}
                        />
                    }
                    <div className={'rightBar'}>
                        {/*<WeekPicker/>*/}

                        <YearPicker
                            onYearChange={(v) => this.setState((old) => ({filters: old.filters.update('selectedYear', () => v)}))}
                            onMonthChange={(v) => this.setState((old) => ({filters: old.filters.update('selectedMonth', old_v => old_v !== v ? v : null)}))}
                        />
                        <div className={'textInput'}>

                            <TextField
                                className={'textFilter'}
                                label={'Codice FDL'}
                                variant={"outlined"}
                                color={"secondary"}
                                margin={"dense"}
                                size="small"
                                fullWidth
                                value={this.state.filters.get('fdlCode')}
                                onChange={e => {
                                    const value = e.target.value;
                                    this.setState((old) => ({filters: old.filters.update('fdlCode', () => value)}))
                                }}
                            />
                            <Autocomplete
                                className={'textFilter'}
                                limitTags={2}
                                required
                                multiple={true}
                                variant={'standard'}
                                id="combo-box-demo2"
                                // className={error && (checkTechnician) ? 'textField error' : 'textField'}
                                // style={{margin: '0 0 7px 0'}}
                                fullWidth={true}
                                value={this.state.filters.get('technician')}
                                // options={users.sortBy(u => u.get('surname') + u.get('name')).toJS()}
                                options={this.props.users?.sortBy(u => u.get('surname') + u.get('name')).map(u => u.get('id')?.toString()).toJS()}
                                // getOptionLabel={(option) => `${option.surname} ${option.name}`}
                                onChange={(e, v, r) => this.setState((old) => ({filters: old.filters.update('technician', () => v)}))}
                                getOptionLabel={(option) => {
                                    const user = this.props.users.find(u => u.get('id')?.toString() === option)
                                    return `${user.get('surname')} ${user.get('name')}`
                                }}
                                // style={{width: 300}}
                                color={"secondary"}

                                renderInput={(params) => <TextField {...params}
                                    // className={error && (!checkTechnician) ? 'error' : ''}
                                                                    color={"secondary"}
                                                                    variant={'outlined'}
                                                                    margin={"dense"}
                                                                    size="small"
                                                                    label="Tecnici"/>}
                            />
                            <Autocomplete
                                id={'commissionTextField'}
                                // limitTags={2}
                                className={'textFilter'}
                                // style={{margin: '0 0 7px 0'}}
                                freeSolo
                                // autoHighlight
                                // openOnFocus
                                // autoHighlight
                                // autoComplete
                                // multiple={true}
                                options={this.props.commissionCodes?.toJS()}
                                value={this.state.filters.get('commission')}
                                groupBy={(option) => option.substring(0, 4)}

                                onInputChange={(e, v, r) => this.setState((old) => ({filters: old.filters.update('commission', () => v)}))}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        color={"secondary"}
                                        // className="textfield"
                                        // style={{margin: '0'}}
                                        // id={"Nc-" + this.props.workdata.get(cMap.DbKey)}
                                        variant="outlined"
                                        size="small"
                                        margin={"dense"}
                                        // error={!this.state.nc.valid && this.state.nc.value ? this.state.nc.value.length > 0 : false}
                                        label={"Commessa"}
                                        type="text"
                                        // onBlur={(event) => (this.state.nc.valid || this.state.nc.value.length === 0) ? this.props.onPropsCommChangeHandler(this.state.nc.value, this.props.idx_day, this.props.idx_comm, cMap.Nc, this.props.workdata.get(cMap.DbKey)) : null}
                                    />
                                )}
                            />
                            <div className={'checkFilters'}>
                                <div className={'coupleCheckFilters'}>

                                    <FormControlLabel
                                        className={'check'}
                                        control={<Checkbox checked={this.state.filters.get('validated')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('validated', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Validato"
                                    />
                                    <FormControlLabel
                                        className={'checkNot'}
                                        control={<Checkbox checked={this.state.filters.get('notValidated')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('notValidated', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Non validato"
                                    />
                                </div>
                                {/*<Divider orientation={"horizontal"}/>*/}
                                <div className={'coupleCheckFilters'}>
                                    <FormControlLabel
                                        className={'check'}
                                        control={<Checkbox checked={this.state.filters.get('signed')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('signed', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Firmato"
                                    />
                                    <FormControlLabel
                                        className={'checkNot'}
                                        control={<Checkbox checked={this.state.filters.get('notSigned')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('notSigned', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Non Firmato"
                                    />
                                </div>
                                {/*<Divider/>*/}
                                <div className={'coupleCheckFilters'}>
                                    <FormControlLabel
                                        className={'check'}
                                        control={<Checkbox checked={this.state.filters.get('used')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('used', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Utilizzato"
                                    />
                                    <FormControlLabel
                                        className={'checkNot'}
                                        control={<Checkbox checked={this.state.filters.get('notUsed')}
                                                           onChange={e => {
                                                               const value = e.target.checked;
                                                               this.setState((old) => ({filters: old.filters.update('notUsed', () => value)}))
                                                           }}
                                                           name="checkedA"
                                        />}
                                        label="Non Utilizzato"
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            this.props.fdlManagement &&
                            <div className={'verticalContainer'}>
                                <Paper variant={"outlined"} className={'action'}>
                                    <div>

                                        <Button
                                            className={'button'}
                                            variant={"contained"}
                                            fullWidth
                                            color={"secondary"}
                                            onClick={() => this.props.saveFdlChanges(this.state.fdlChange).then(() => this.setState({fdlChange: List([])}))}
                                            disabled={this.state.fdlChange.size === 0}
                                        >Salva</Button>
                                        <Button
                                            className={'button'}
                                            variant={"contained"}
                                            fullWidth
                                            color={"secondary"}
                                            onClick={() => this.popUpNewFdl(true)}
                                        >Nuovo FDL</Button>
                                    </div>
                                    <div className={'export'}>
                                    <FdlExport columns={columns} data={data} users={this.props.users} month={this.state.filters.get('selectedMonth')} year={this.state.filters.get('selectedYear')}/>

                                    </div>
                                </Paper>
                            </div>
                        }
                    </div>
                </div>
                <PopUpNewFdl
                    state={this.state.popUpNewFdl && this.props.fdlManagement}
                    users={this.props.users}
                    addFDL={this.props.addFDL}
                    closePopUp={this.popUpNewFdl}
                    data={this.state.startFDL}
                    commissionCodes={this.props.commissionCodes}
                />

                <ConfirmPopUp
                    open={Boolean(this.state.popUpDeleteFDLCode)}
                    handleAccept={() => this.props.deleteFDL(this.state.popUpDeleteFDLCode).then(() => this.setState({popUpDeleteFDLCode: ''}))}
                    handleDecline={() => this.setState({popUpDeleteFDLCode: ''})}
                    title={`Eliminazione FDL`}
                    description={`Sei sicuro di voler eliminare questo FDL: ${this.state.popUpDeleteFDLCode}?`}
                    ko={'Annulla'}
                    ok={'Conferma'}
                />
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        fdlList: state.mng_data.fdl,
        users: state.mng_data.users,
        commissionCodes: state.mng_data.commissionCodes,
        fdlManagement: state.auth.user.getIn(['role', 'fdl_management'])
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllFDL: () => dispatch(getAllFDL()),
        getAllUsers: () => dispatch(getAllUsers()),
        addFDL: (...args) => dispatch(addFDL(...args)),
        add_comm: (...args) => dispatch(add_comm(...args)),
        updateFDL: (...args) => dispatch(updateFDL(...args)),
        saveFdlChanges: (...args) => dispatch(saveFdlChanges(...args)),
        deleteFDL: (...args) => dispatch(deleteFDL(...args)),
        sendFDL: (...args) => dispatch(sendFDL(...args)),
        getCommissionCodes: (...args) => dispatch(getCommissionCodes(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FdlMng);