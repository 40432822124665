import React from "react";
import style from './WeekCompilationPopUp.module.scss'
import {Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider} from "@mui/material";
import {fromJS, List, Map, Set} from "immutable";
import WorkData from "../../WorkList/WorkData/WorkData";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {onWdCopy, onWdPaste, weekWorkDataAdd} from "../../../store/actions/WorkData";
import moment from "moment";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import {withTheme} from "@mui/styles";

const wd = Map({
    interlocutor: "",
    place: "",
    client_nc: "",
    locked: null,
    fs: false,
    fdl: "",
    note: "",
    nc: "",
    trs: false,
    id: -1,
    travels: List([]),
    purchases: List([]),
    tasks: List([]),
    worktime: List([
        Map({
            start: '00:00:00',
            end: '00:00:00',
        })
    ])
})

const purch = Map({
    currency: 0,
    description: "",
    id: -1,
    method: 0,
    nc: "",
    total: 0,
})


const trv = Map({
    km: 0,
    end: "00:00",
    highway: false,
    start: "00:00",
    nc: "",
    id: -1,
    plates: null,
})

const worktime = Map({
    end: "00:00",
    start: "00:00",
    id: -1,
})

const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = moment();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = moment();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = moment();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = moment();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = moment();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    {label: 'Reset', getValue: () => [null, null]},
];


class WeekCompilationPopUp extends React.Component {

    state = {
        days: Set([]),
        wdData: List([wd])
    }

    changeSelectedDays = (event, newFormats) => {
        this.setState({days: fromJS(newFormats)})
    }

    getFirstDayOfWeek = (day = moment()) => {
        return day.startOf('week')
    }

    AddComm = () => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.push(wd.update('id', () => new Date().getMilliseconds()))}
        })
    }
    RemoveComm = (_, idxComm) => {
        if (this.state.wdData.size > 1) {
            this.setState((oldState) => {
                return {wdData: oldState.wdData.delete(idxComm)}
            })
        }
    }
    onPropsCommChangeHandler = (value, _, idx_comm, key) => {
        return new Promise((resolve, reject) => {
            if (key === 'fdl' && value !== '') {
                const integration = this.props.fdls.find(f => f.get('code') === value).get('integration')
                const nc = `${integration.get('client_code')}${integration.get('commission_code')}_${integration.get('integration_code')}`
                const clientNc = integration.get('client_comm')
                this.setState((oldState) => {
                    return {
                        wdData: oldState.wdData.updateIn([idx_comm], c => c.merge({
                            key: value,
                            nc: nc,
                            client_nc: clientNc || oldState.wdData.getIn([idx_comm, 'client_comm'])
                        }))
                    }
                })
                resolve(Map({nc: nc, client_nc: clientNc || ''}))
            }
            this.setState((oldState) => {
                return {wdData: oldState.wdData.updateIn([idx_comm, key], () => value)}
            })
            resolve(Map({}))
        })
    }
    onWorkTimeAdd = (_, idxComm) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'worktime'], wt => wt.push(worktime.update('id', () => new Date().getMilliseconds())))}
        })
    }
    onWorkTimeDelete = (_, idxComm, idxWt) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'worktime'], wt => wt.delete(idxWt))}
        })
    }
    onWorkTimeChange = (value, _, idx_comm, idxWt, key) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idx_comm, 'worktime', idxWt, key], () => value)}
        })
    }
    AddTravel = (_, idxComm) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'travels'], t => t.push(trv.update('id', () => new Date().getMilliseconds())))}
        })
    }
    RemoveTravel = (_, idxComm, idxTrv) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'travels'], t => t.delete(idxTrv))}
        })
    }
    onPropsTravelChangeHandler = (value, _, idx_comm, idxTrv, key) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idx_comm, 'travels', idxTrv, key], () => value)}
        })
    }
    AddPurch = (_, idxComm) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'purchases'], p => p.push(purch.update('id', () => new Date().getMilliseconds())))}
        })
    }
    RemovePurch = (_, idxComm, idxPurch) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idxComm, 'purchases'], p => p.delete(idxPurch))}
        })
    }
    onPropsPurchChangeHandler = (value, _, idx_comm, idxPurch, key) => {
        this.setState((oldState) => {
            return {wdData: oldState.wdData.updateIn([idx_comm, 'purchases', idxPurch, key], () => value)}
        })
    }

    onWdPaste = (idxDay, idxComm) => {

        let sourceWorkdata = this.props.wdCopied
        sourceWorkdata = sourceWorkdata.update('id', () => new Date().getMilliseconds())
        sourceWorkdata = sourceWorkdata.delete('day')
        sourceWorkdata = sourceWorkdata.delete('last_update')
        sourceWorkdata = sourceWorkdata.delete('task')
        sourceWorkdata = sourceWorkdata.update('worktime', wd => wd.map(w => w.update('id', i => i + new Date().getMilliseconds())))
        sourceWorkdata = sourceWorkdata.update('travels', trv => trv.map(t => t.update('id', i => i + new Date().getMilliseconds())))
        sourceWorkdata = sourceWorkdata.update('purchases', purch => purch.map(p => p.update('id', i => i + new Date().getMilliseconds())))
        sourceWorkdata = sourceWorkdata.update('tasks', task => task.map(t => t.update('id', i => i + new Date().getMilliseconds())))


        this.setState((oldState) => ({wdData: oldState.wdData.delete(idxComm).push(sourceWorkdata)}))

    };

    onSendData = () => {
        let data = List([])
        this.state.days.forEach(d => {
            this.state.wdData.forEach(wd => {
                const user_id = this.props.user.getIn(['userData', 'id'])
                wd = wd.update('user_id', () => user_id)
                wd = wd.delete('id')
                wd = wd.update('worktime', (old) => old.map(wt => wt.update('user_id', () => user_id)))
                wd = wd.update('worktime', (old) => old.map(wt => wt.delete('id')))
                wd = wd.update('travels', (old) => old.map(trv => trv.update('user_id', () => user_id)))
                wd = wd.update('travels', (old) => old.map(trv => trv.update('day', () => d)))
                wd = wd.update('travels', (old) => old.map(wt => wt.delete('id')))
                wd = wd.update('purchases', (old) => old.map(prc => prc.update('user_id', () => user_id)))
                wd = wd.update('purchases', (old) => old.map(wt => wt.delete('id')))
                wd = wd.update('tasks', (old) => old.map(t => t.update('user_id', () => user_id)))
                wd = wd.update('tasks', (old) => old.map(wt => wt.delete('id')))

                data = data.push(wd.update('day', () => d))
            })
        })

        this.props.weekWorkTimeAdd(data, this.state.days.first())
            .then(() => {
                this.setState({
                    week: new Date(),
                    days: List([0, 1, 2, 3, 4]),
                    wdData: List([wd])
                })
                this.props.handleClose()
            })
    }

    render() {

        let data
        if (this.state.wdData.size > 0) {
            data = this.state.wdData.map((wt, index) => {
                return <WorkData
                    className={style.wt}
                    key={wt.get('id')}
                    hideTasks={true}
                    lockRemoveComm={this.state.wdData.size <= 1}
                    workdata={wt}
                    idx_day={-1}
                    idx_comm={index}
                    lockedData={false}
                    date={this.props.day}
                    listWorkedCommissions={this.props.listWorkedCommissions}
                    mngData={this.props.mngData}
                    machines={this.props.machines}
                    listUserFdl={this.props.fdls}
                    user={this.props.user}
                    onWDAdd={this.AddComm}
                    RemoveComm={this.RemoveComm}
                    onPropsCommChangeHandler={this.onPropsCommChangeHandler}
                    onWorkTimeAdd={this.onWorkTimeAdd}
                    onWorkTimeDelete={this.onWorkTimeDelete}
                    onWorkTimeChange={this.onWorkTimeChange}
                    AddTravel={this.AddTravel}
                    RemoveTravel={this.RemoveTravel}
                    onPropsTravelChangeHandler={this.onPropsTravelChangeHandler}
                    AddPurch={this.AddPurch}
                    RemovePurch={this.RemovePurch}
                    onPropsPurchChangeHandler={this.onPropsPurchChangeHandler}
                    onWtTaskAdd={() => ''}
                    onWtTaskDelete={() => ''}
                    onWtTaskChangeHandler={() => ''}
                    onPaste={this.onWdPaste}
                    countries={this.props.countries}
                />
            })
        }

        return (
            <Dialog open={this.props.open} className={style.weekCompilationPopUp}
                    onClose={this.props.handleClose} maxWidth={"xl"}
            >
                <DialogTitle>Compilazione settimanale</DialogTitle>
                <Divider/>
                <DialogContent className={style.content}>
                    <div className={style.daySelection}>
                        <DayPicker
                            className={style.Selectable}
                            localeUtils={MomentLocaleUtils}
                            locale={'it'}
                            fixedWeeks
                            selectedDays={this.state.days.map(d => moment(d, 'YYYY-MM-DD').toDate()).toJS()}
                            showWeekNumbers
                            showOutsideDays
                            modifiers={{}}
                            onDayClick={(date, {selected}) => {
                                let days = this.state.days
                                if (selected) {
                                    days = days.delete(moment(date).format('YYYY-MM-DD'))
                                } else {
                                    days = days.add(moment(date).format('YYYY-MM-DD'))
                                }
                                this.setState({days: days})
                            }}
                            modifiersClassNames={{
                                selected: this.props.theme.palette.primary.main,
                                today: this.props.theme.palette.primary.main
                            }}

                        />
                    </div>
                    <div className={style.wtData}>
                        {data}
                    </div>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant={"text"}
                            onClick={this.props.handleClose}
                    >Annulla</Button>
                    <Button variant={"contained"}
                            onClick={() => this.onSendData()
                                // this.props.weekWorkTimeAdd(this.state)
                                //     .then(() => {
                                //             this.setState({
                                //                 week: new Date(),
                                //                 days: List([0, 1, 2, 3, 4]),
                                //                 wdData: List([wd])
                                //             })
                                //             this.props.handleClose()
                                //         })
                            }
                    >Invio</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        listWorkedCommissions: state.userData.list_worked_commissions,
        mngData: state.mng_data.mng_data,
        machines: state.mng_data.machines,
        fdls: state.userData.fdls,
        user: state.auth.user,
        wdCopied: state.data.wdCopied,
        countries: state.meta.countries,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        weekWorkTimeAdd: (...args) => dispatch(weekWorkDataAdd(...args)),
        onWdPaste: (...args) => dispatch(onWdPaste(...args)),
        onWdCopy: (...args) => dispatch(onWdCopy(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(WeekCompilationPopUp))