import React, {useState} from 'react';
import style from './WdExport.module.scss';
import MonthPicker from "../Library/MonthPicker/MonthPicker";
import moment from "moment/moment";
import {Button} from "@mui/material";
import {downloadWdExport} from "../../store/actions/exportData";
import {useDispatch, useSelector} from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ConfirmPopUp from "../Library/ConfirmPopUp/ConfirmPopUp";
import {DataGridPremium} from "@mui/x-data-grid-premium";

const WdExport = () => {

    const dispatch = useDispatch()


    const [date, setDate] = useState(moment().month(moment().month() - 1).startOf('month'))
    const [definitiveExport, setDefinitiveExport] = useState(false)
    const [confirmExport, setConfirmExport] = useState(false)

    return <div className={style.wdExport}>
        <div className={style.filterBar}>

            <MonthPicker
                value={date}
                onChange={(n) => {
                    setDefinitiveExport(false)
                    setDate(n)
                }}
            />
            <FormControlLabel
                control={<Checkbox checked={definitiveExport}/>}
                label="Export definitivo"
                disabled={date.startOf('month') >= moment().startOf('month')}
                onChange={() => setDefinitiveExport(old => !old)}
            />
            {/*<Button*/}
            {/*    onClick={() => dispatch(getFdlDataExport(integration, date.month() + 1, date.year()))}*/}
            {/*>*/}
            {/*    Visualizza dati*/}
            {/*</Button>*/}
            <Button
                onClick={() => {
                    if (definitiveExport)
                        setConfirmExport(true)
                    else
                        dispatch(downloadWdExport(date.month() + 1, date.year(), false))

                }}
            >
                Export
            </Button>
        </div>

        <ConfirmPopUp
            open={confirmExport}
            handleAccept={() => {
                dispatch(downloadWdExport(date.month() + 1, date.year(), true))
                setConfirmExport(false)
            }}
            handleDecline={() => {
                setDefinitiveExport(false)
                setConfirmExport(false)
            }}
            title={`Generazioen export definitivo`}
            description={`Attenzaione generando un export definitivo gli utenti non potranno più modificare le ore inserite (appartenenti al mese selezionato)!!!`}
            ko={'Annulla'}
            ok={'Genera'}
        />
    </div>
}

export default WdExport