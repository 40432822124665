import * as actionTypes from "./action";
import {Map, fromJS} from "immutable";
import * as actionType from "./action";
import apiV2 from "../../apiV2";


const updateListaComm = (comm_list) => {
    return {
        type: actionTypes.UPDATE_USERDATA_COMM,
        comm_list: comm_list
    };
};

const updateListaCli = (cli_list) => {
    return {
        type: actionTypes.UPDATE_USERDATA_CLI,
        cli_list: cli_list
    };
};

const updateStatistics = (statistics) => {
    console.log(statistics)
    return {
        type: actionTypes.UPDATE_USERDATA_STATISTICS,
        statistics: statistics
    };
};

export const UpdateCommList = (res) => {
    return {
        type: actionType.UPDATE_COMM_LIST,
        lista_commesse: res
    };
};

export const UpdateWorkedCommissions = (res) => {
    return {
        type: actionType.UPDATE_WORKED_COMMISSIONS,
        list_worked_commissions: res
    };
};

export const updateUser = (user) => {
    return {
        type: actionType.UPDATE_USER,
        user: user

    }
};


export const closeSnackbar = key => ({
    type: actionType.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = key => ({
    type: actionType.REMOVE_SNACKBAR,
    key,
});

export const get_userDataComm = () => {
    return (dispatch, getState) => {

        apiV2.get('statistics/work_data').then(response => {
            dispatch(updateListaComm(fromJS(response.data.data.data)))
        })
    }
};


export const get_userDataCli = () => {
    return (dispatch, getState) => {

        apiV2.get('statistics/worked_clients').then(response => {
            dispatch(updateListaCli(fromJS(response.data.data.data)))
        })
    }
};


export const get_statistics = () => {
    return (dispatch, getState) => {

        apiV2.get('statistics/user_statistics').then(response => {
            dispatch(updateStatistics(fromJS(response.data.data.data)))
        })
    }
};

export const get_worked_commissions = () => {
    return (dispatch, getState) => {
        apiV2.get('statistics/recent_comm').then(response => {
            dispatch(UpdateWorkedCommissions(fromJS(response.data.data.data)))
        });
    }
}


// export const get_sign = () => {
//     return (dispatch, getState) => {
//             apiV1.get('user/sign').then(response => {
//             console.log(response.data)
//             const newUser = getState().auth.user.updateIn(['userData', 'sign'], data => response.data)
//             console.log(newUser.get('userData'))
//             dispatch(updateUser(newUser))
//         });
//     }
// }


export const update_sign = (sign) => {
    return (dispatch, getState) => {
        const newUser = getState().auth.user.updateIn(['userData', 'sign'], data => sign)
        dispatch(updateUser(newUser))

        // console.log(newUser.get('userData'))
        // let formData = new FormData();
        // formData.append("file", sign);
        // axios.put('user/sign', sign)
        //     .then(response => {
        //         console.log(response.data);
        //         dispatch(updateUser(newUser))
        //     })
        // axios.put('user/sign', formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // })
        //     .then(response => {
        //         console.log(response.data);
        //         dispatch(updateUser(newUser))
        //     })
    }
};


export const update_user = (data) => {
    return (dispatch, getState) => {
        let newUser = data
        newUser = newUser.delete('id')
        console.log(newUser.get('userData'))
        apiV2.put('user', newUser)
            .then(response => {
                console.log(response.data);
                data = getState().auth.user.update('userData', userData => newUser)
                dispatch(updateUser(data))
            })
    }
};

export const updateTheme = (primaryColor, secondaryColor, tertiaryColor) => {
    return (dispatch, getState) => {
        const data = getState().auth.user.updateIn(['userData', 'config', 'theme'], () => Map({primaryColor, secondaryColor, tertiaryColor}))
        dispatch(updateUser(data))
    }
};