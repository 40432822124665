import React from "react";
import './WtTask.scss'
import {TextField} from "@mui/material";
import * as pMap from "../../../../store/stateStr/PurchMap";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {connect} from "react-redux";


const WtTask = (props) => {

    return (
        <div className={'wtTask'}
             id={`wtDivTask${props.task.get('id')}`}
             ref={props.refCmp}
        >
            <div className={'subRow'}>
                <TextField
                    id={"Nome-" + props.task.get(pMap.DbKey)}
                    variant="outlined"
                    className={'textfield'}
                    margin="dense" size="small"
                    disabled={props.lockedData}
                    type="text"
                    label="Nome"
                    defaultValue={props.task.get('task_name')} //className="inputCommessa data"
                    onBlur={(event) => props.onWtTaskChangeHandler(event.target.value, props.idxDay, props.idxWt, props.idxTask, 'task_name', props.task.get('id'))}/>
            </div>
            <div className={'subRow'}>
                <TextField
                    id={"Descrizione-" + props.task.get(pMap.DbKey)}
                    variant="outlined"
                    className={'textFieldNote'}
                    disabled={props.lockedData}
                    margin="dense" size="small"
                    type="text"
                    label="Descrizione"
                    defaultValue={props.task.get('description')} //className="inputCommessa data"
                    onBlur={(event) => props.onWtTaskChangeHandler(event.target.value, props.idxDay, props.idxWt, props.idxTask, 'description', props.task.get('id'))}/>
            </div>
            <div className={'group'}>
                {/*<div className={'subRow'}>*/}
                <TextField
                    id={"duration-" + props.task.get('id')}
                    label="Durata"
                    type="time"
                    color={"secondary"}
                    disabled={props.lockedData}
                    variant="outlined"
                    margin="dense" size="small"
                    defaultValue={props.task.get('duration')}
                    className={'timePicker'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    onBlur={(event) => {
                        props.onWtTaskChangeHandler(event.target.value, props.idxDay, props.idxWt, props.idxTask, 'duration', props.task.get('id'))
                    }}
                />
                {/*</div>*/}

                <div style={{margin: '0 0 0 auto'}}>
                    {/*<div className={'actionBtnRow'}>*/}
                    <Fab color="primary" className={'circButton'}
                         disabled={props.lockedData}
                         onClick={() => props.onWtTaskAdd(props.idxDay, props.idxWt, props.idWt)}>
                        <AddIcon/>
                    </Fab>
                    <Fab color="secondary" className={'circButton'}
                         disabled={props.lockedData}
                         onClick={() => props.onWtTaskDelete(props.idxDay, props.idxWt, props.idxTask, props.task.get('id'))}>
                        <RemoveIcon/>
                    </Fab>
                    {/*</div>*/}
                </div>
                {/*</div>*/}
            </div>
        </div>
    )
}

// const mapStateToProps = state => {
//     return {
//         date: state.day
//     };
// };

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(null, mapDispatchToProps)(WtTask);