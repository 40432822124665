import React, {PureComponent} from "react";
import './UsrComm.css'
import {connect} from "react-redux";
import UsrCommData from "../Library/UsrCommData/UsrCommData";
import UsrCommission from "../Library/UsrCommission/UsrCommission";
import {ButtonBase, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import UsrData from "../Library/UsrData/UsrData";
import {get_statistics, get_userDataComm} from "../../store/actions/userData";


class UsrComm extends PureComponent {

    state = {
        selComm: '25101997'
    }

    handleChangeComm = (commCode) => {
        this.setState({selComm: commCode})
    }


    componentDidMount() {
        this.props.get_userDataComm();
        this.props.get_statistics();
    }

    render() {

        let cnt = null
        if (this.state.selComm === '25101997') {
            cnt = <UsrData
                statistics={this.props.statistics}
            />
        } else if (this.state.selComm !== 0 && this.props.comm_list.size > 0) {
            cnt = <UsrCommData
                comm={this.props.comm_list.get(this.state.selComm)}
            />

        }


        let comm = null
        if (this.props.comm_list.size > 0) {
            comm = this.props.comm_list.sort((a, b) => {
                if (a.get('last_day') < b.get('last_day')) {
                    return 1;
                }
                if (a.get('last_day') === b.get('last_day')) {
                    return 0;
                }
                if (a.get('last_day') >= b.get('last_day')) {
                    return -1;
                }
            }).toList().map((comm, index) => {
                if (comm.get('description')) {
                    return (
                        <UsrCommission
                            key={index}
                            comm={comm}
                            handleChangeComm={this.handleChangeComm}
                            clientDetails={this.props.mng_data.filter(d => d.get('client_code') === comm.get('client'))}
                        />
                    )
                }
            })
        }

        return (
            <div className={'usrComm'}>
                <div className={'listComm'}>
                    <div className={'scrollListComm'}>
                        <ButtonBase className={'usrCommission'}
                                    onClick={() => this.handleChangeComm('25101997')}>
                            <Paper className={'usrCommissionPaper'}>
                                <Typography className={'code'}>Le tue statistiche</Typography>
                                {/*<Typography className={'description'}></Typography>*/}
                                {/*<Typography className={'description'}></Typography>*/}


                            </Paper>
                        </ButtonBase>
                        {comm}
                    </div>
                </div>
                <div className={'infoComm'}>
                    {cnt}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        comm_list: state.userData.comm_list,
        statistics: state.userData.statistics,
        mng_data: state.mng_data.mng_data,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        get_userDataComm: () => dispatch(get_userDataComm()),
        get_statistics: () => dispatch(get_statistics()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsrComm);