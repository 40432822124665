import React from "react";
import './FeaturePopUp.css'
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import axios from "../../../apiV2";
import {fromJS, List} from "immutable";


class FeaturePopUp extends React.Component {


    state = {
        updates: List([]),
        closed: false,
    }

    onClose = () => {
        localStorage.setItem("actVersion", process.env.REACT_APP_VERSION)
        this.setState({closed:true})
    }

    componentDidMount() {
        this.updates = List([])

        if (localStorage.getItem("actVersion") !== null && process.env.REACT_APP_VERSION !== localStorage.getItem("actVersion")) {
            axios.get(`info/version/${process.env.REACT_APP_VERSION}`).then(response => {
                const data = fromJS(response.data.data.notes)
                this.setState({updates:data||List([])})
            })
        } else if (localStorage.getItem("actVersion") === null) {
            localStorage.setItem("actVersion", process.env.REACT_APP_VERSION)
        }
    }

    render() {
        let updates = null
        if (this.state.updates.size > 0) {
            updates = this.state.updates.map((v, k) => {
                const details = v.map(d => {
                    return <Typography variant={"body2"}>  - {d}</Typography>
                })
                return (
                    <div>
                        <Typography variant={"h5"}>{k}:</Typography>
                        {details}
                    </div>
                )
            }).toList()
        }

        return (
            <Dialog open={this.state.updates.size > 0 && !this.state.closed} onClose={this.onClose}>
                <DialogTitle>Nuova versione {process.env.REACT_APP_VERSION}!</DialogTitle>
                <DialogContent>
                    {/*<Typography variant={"h4"}>Lista cam</Typography>*/}
                    {updates}

                </DialogContent>
            </Dialog>
        )
    }
}


export default FeaturePopUp;