import React, {useState} from 'react';
import './StatisticBox.css'
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {Tooltip} from "@mui/material";

const StatisticBox = (props) => {

    const [open, setOpen] = useState(false)

    const Icon = props.icon

    const handleClick = () => {
        if (props.extra) {
            setOpen(!open)
        }
    }

    let extra = null
    if (props.extra) {
        extra = props.extra.map((e, i, a) => {
            return (
                <React.Fragment>
                    <div className={'inlineValue'}>
                        <Typography className={'extraText noselect'}>{e.name + ': '}</Typography>
                        <Typography className={'extraText noselect'}
                                    style={{alignSelf: 'flex-end', textAlign: 'end'}}>{e.value}</Typography>
                    </div>
                    {/*{i + 1 !== a.length && <Divider style={{backgroundColor: '#e7e7e7', margin: 0}} variant={"fullWidth"}/>}*/}
                </React.Fragment>
            )
        })
    }

    return (
        <Paper className={open ? 'statisticsBox statisticsBoxExpanded' : 'statisticsBox statisticsBoxNormal'}
               transparent={true}
               style={{backgroundColor: props.color, height: open ? props.extraHeight : null}} onClick={handleClick}>
            <Typography className={'statistics_box_title noselect'}>{props.title}</Typography>
            <Typography variant={"h2"} className={'statistics_box_value noselect'}>
                {props.value}
            </Typography>
            <Icon className={'statistics_box_icon'}/>
            {props.info && <div className={'info'}><Tooltip title={props.info}><InfoOutlinedIcon/></Tooltip></div>}
            {props.extra && open && <ExpandLessRoundedIcon className={'expandIcon'}/>}
            {props.extra && !open && <ExpandMoreRoundedIcon className={'expandIcon'}/>}
            {props.extra && <div className={'extra'}>
                {/*<FlatTable*/}
                {/*    data={props.extra}*/}
                {/*/>*/}
                {extra}
            </div>}
        </Paper>
    )
}

export default StatisticBox;