import api from "../../apiV2";
import {fromJS} from "immutable";
import * as actionType from "./action";


// region Countries

export const get_countries = () => {
    return (dispatch, getState) => {
        api.get('countries').then(response => {
            dispatch({
                type: actionType.UPDATE_COUNTRIES,
                data: fromJS(response.data.data)
            })
        });
    }
}
// endregion


// region Regions

export const get_regions = () => {
    return (dispatch, getState) => {
        api.get('regions').then(response => {
            dispatch({
                type: actionType.UPDATE_REGIONS,
                data: fromJS(response.data.data)
            })
        });
    }
}
// endregion


// region Regions

export const get_themes = () => {
    return (dispatch, getState) => {
        api.get('themes').then(response => {
            dispatch({
                type: actionType.UPDATE_THEMES,
                data: fromJS(response.data.data)
            })
        });
    }
}
// endregion