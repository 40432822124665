import axios from 'axios';


const apiV2 = axios.create({
    baseURL: process.env.REACT_APP_API_V2
});
// const instance = axios.create({
//     baseURL: 'http://192.168.1.250:4000/API/v1/'
// });
// const instance = axios.create({
//     baseURL: 'http://192.168.1.111:4000/API/v1/'
// });
// const instance = axios.create({
//     baseURL: 'https://rossetto.ns0.it/API/v1/'
// });

apiV2.defaults.headers.common['Content-Type'] = 'application/json';

export default apiV2;