import React, {Component} from 'react'
import TextField from '@mui/material/TextField';
import "./SignUp.scss"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as action from '../../store/actions/index'
import {connect} from "react-redux";
import {withRouter} from "react-router";

class SignUp extends Component {

    state = {
        email: {
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
        },
        password: {
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            valid: true,
        },
        name: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        surname: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        logged: false,
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    handleEmailChange = (event) => {
        this.setState({
                email: {
                    ...this.state.email,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.email.validation),
                }
            }
        )
    };

    handleNameChange = (event) => {
        this.setState({
                name: {
                    ...this.state.name,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.name.validation),
                }
            }
        )
    };

    handleSurnameChange = (event) => {
        this.setState({
                surname: {
                    ...this.state.surname,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.surname.validation),
                }
            }
        )
    };

    handlePasswordChange = (event) => {
        this.setState({
                password: {
                    ...this.state.password,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.password.validation),
                }
            }
        )
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.props.SignUp(this.state.name.value, this.state.surname.value, this.state.email.value, this.state.password.value)
            .then((res) => {
                this.props.history.push('/SignIn')
            })
    };

    onClickLoginHandle = () => {
        window.location.pathname = '/SignIn';
    }

    render() {
        if (!this.state.logged) {
            return (
                <div className="signUp">
                    <div className={'signUpBody'}>
                        <div className={'logo'}>
                            <img src="FogliOre.svg" alt=""/>
                        </div>
                        <form className={"auth_form"} onSubmit={this.handleOnSubmit}>
                            <Typography color="textPrimary">Registrazione</Typography>
                            <TextField
                                id={'regSurname'}
                                className="text_field"
                                label="Cognome"
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handleSurnameChange}
                                error={this.state.surname.value.length > 0 && !this.state.surname.valid}
                                value={this.state.surname.value}

                            />
                            <TextField
                                id={'regName'}
                                className="text_field"
                                label="Nome"
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}

                                onChange={this.handleNameChange}
                                error={this.state.name.value.length > 0 && !this.state.name.valid}
                                value={this.state.name.value}

                            />
                            <TextField
                                id={'regEmail'}
                                className="text_field"
                                label="Email"
                                fullWidth={true}
                                type="email"
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handleEmailChange}
                                error={this.state.email.value.length > 0 && !this.state.email.valid}
                                value={this.state.email.value}

                            />
                            <TextField
                                id={'regPass'}
                                className="text_field"
                                label="Password"
                                type="Password"
                                fullWidth={true}
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handlePasswordChange}
                                error={!this.state.password.valid}
                                value={this.state.password.value}
                            />
                            <div className={'action'}>
                                <Button
                                    className="login_button"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={this.onClickLoginHandle}
                                    style={{marginRight: '10px'}}
                                >
                                    Accedi
                                </Button>
                                <Button
                                    className="login_button"
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    type="submit"
                                >
                                    Registrati
                                </Button>
                            </div>
                            {/*<Link to={{pathname: '/SignIn'}}>*/}
                            {/*    <Typography className="registration_button" color="textPrimary"*/}
                            {/*                variant="caption">Accedi</Typography>*/}
                            {/*</Link>*/}
                        </form>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {this.props.children}
                </div>
            )
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SignUp: (...args) => dispatch(action.SignUp(...args)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(SignUp));