export const DbKey = 'id';
export const JKey = 'j_key';
export const Sync = 'sync';
export const Day = 'day';

export const Fdl = 'fdl';
export const Fs = 'fs';
export const Trs = 'trs';
export const Nc = 'nc';
export const clientNc = 'client_nc';
export const Luogo = 'place';
export const Region = 'region';
export const Interlocutore = 'interlocutor';
export const Note = 'note';
export const Task = 'task';

export const Purch = 'purchases';
export const Travels = 'travels';
export const CommTasks = 'tasks';