export const UPDATE_WEEK = 'UPDATE_WEEK';
export const UPDATE_MACHINES_LIST = 'UPDATE_MACHINES_LIST';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const UPDATE_REGIONS = 'UPDATE_REGIONS';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_FILE_STATE = 'UPDATE_FILE_STATE';
export const UPDATE_THEMES = 'UPDATE_THEMES';


export const UPDATE_USERDATA_COMM = 'UPDATE_USERDATA_COMM';
export const UPDATE_USERDATA_CLI = 'UPDATE_USERDATA_CLI';
export const UPDATE_USERDATA_STATISTICS = 'UPDATE_USERDATA_STATISTICS';
export const UPDATE_FDLS = 'UPDATE_FDLS';

export const UPDATE_COPIED_WD = 'UPDATE_COPIED_WD';

export const UPDATE_COMM_LIST = 'UPDATE_COMM_LIST';
export const UPDATE_MNG_DATA = 'UPDATE_MNG_DATA';
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';
export const UPDATE_CLI_LIST = 'UPDATE_CLI_LIST';
export const UPDATE_EVENT_WORKTIME = 'UPDATE_EVENT_WORKTIME';
export const UPDATE_FDL = 'UPDATE_FDL';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_COMMISSION_CODES = 'UPDATE_COMMISSION_CODES';
export const UPDATE_WORKDATA_AGGREGATION = 'UPDATE_WORKDATA_AGGREGATION';
export const UPDATE_WORKDATA_COMPILATION = 'UPDATE_WORKDATA_COMPILATION';

export const UPDATE_WORKED_COMMISSIONS = 'UPDATE_WORKED_COMMISSIONS';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAIL = 'AUTO_LOGIN_FAIL';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';


export const UPDATE_FDL_EXPORT_DATA = 'UPDATE_FDL_EXPORT_DATA';


// region commonAction
export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    console.log('new notification')

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const newNotification = (message, options) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(enqueueSnackbar({
                message: message,
                options: options,
            }))
            resolve()
        })
    }
}
// {
//                     key: key,
//                     persist: true,
//                     variant: 'error',
// preventDuplicate: true,
//                 }
// endregion
