import React, {useEffect, useState} from "react";
import './WdAgg.scss'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import './Pivot.scss';
import {useSelector} from "react-redux";
import {numberFormat, aggregators} from "react-pivottable/Utilities";
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Button from "@mui/material/Button";
import moment from "moment";
// import moment from "moment";
// import {forIn} from "lodash";

const PlotlyRenderers = createPlotlyRenderers(Plot);

const getStartFilters = () => {
    const year = new Date().getFullYear();
    let years = {}
    for (let i = 2018; i < year; i++) {
        years[`${i}`] = true
    }
    // const week = moment().week();
    // let weeks = {}
    // for (let i = week + 1; i <= 53; i++) {
    //     weeks[`${i}`] = true
    // }
    return {
        Anno: years,
        // Settimana: weeks
    }
}
const getStartFiltersActComm = (data, user) => {
    const lastComm = data.filter(w => w.get('Id utente') === user.get('id') && w.get('Ore di lavoro') !== 0).sortBy(w => moment(w.get('Giorno'), 'YYYY-MM-DD')).last().get('Integrazione')
    let res =  {
        Integrazione: data.filter(w => w.get('Integrazione') !== lastComm).reduce((acc, w) => acc.set(w.get('Integrazione'), true)).toJS(),
    }
    const userFilter = data.filter(w => w.get('Nome utente') !== user.get('surname') + ' ' + user.get('name'))?.reduce((acc, w) => acc.set(w.get('Nome utente'), true))?.toJS()
    if (userFilter){
        res['Nome utente'] = userFilter
    }
    return res

}

const getInitialState = () => {
    let saved = JSON.parse(localStorage.getItem('statistics'))
    if (!saved) {
        saved = {
            rows: ['Nome utente'],
            cols: ['Anno', 'Mese', 'Settimana'],
            vals: ['Ore di lavoro'],
            aggregatorName: 'Sum',
            valueFilter: getStartFilters()
        }
    }
    return saved
}

const WdAgg = () => {

    const user = useSelector(state => state.auth.user.getIn(['userData']))

    const [pivotState, setPivotState] = useState(() => getInitialState())


    let workdataExtract = useSelector((state) => state.mng_data.workdataAggregation)

    useEffect(() => {
        // componentWillUnmount
        return () => {
            localStorage.setItem("statistics", JSON.stringify(pivotState))
        }
    }, [pivotState]);

    const handlePivotChange = (state) => {
        const newState = {
            "style": state["style"],
            "rows": state["rows"],
            "cols": state["cols"],
            "vals": state["vals"],
            "aggregatorName": state["aggregatorName"],
            "valueFilter": state["valueFilter"],
            "sorters": state["sorters"],
            "rowOrder": state["rowOrder"],
            "colOrder": state["colOrder"],
            "derivedAttributes": state["derivedAttributes"],
            "tableOptions": state["tableOptions"],
            "hiddenAttributes": state["hiddenAttributes"],
            "hiddenFromAggregators": state["hiddenFromAggregators"],
            "hiddenFromDragDrop": state["hiddenFromDragDrop"],
            "unusedOrientationCutoff": state["unusedOrientationCutoff"],
            "rendererName": state['rendererName']
        }
        setPivotState(newState)
    }

    const handleFavoritesClick = (id) => {
        let newState = {}
        switch (id) {
            case 0:
                newState = {
                    "rows": [],
                    "cols": [],
                    "vals": [],
                    "aggregatorName": 'Sum',
                    "valueFilter": {},
                    "sorters": {},
                    "rowOrder": 'key_a_to_z',
                    "colOrder": 'key_a_to_z',
                    "derivedAttributes": {},
                    "tableOptions": {},
                    "hiddenAttributes": [],
                    "hiddenFromAggregators": [],
                    "hiddenFromDragDrop": [],
                }
                break;
            case 1:
                newState = {
                    rows: ['Nome utente', 'Integrazione'],
                    cols: ['Anno', 'Mese', 'Settimana'],
                    vals: ['Ore di lavoro'],
                    aggregatorName: 'Sum',
                    valueFilter: getStartFilters()
                }
                break;
            case 2:
                newState = {
                    rows: ['Nome utente'],
                    cols: ['Anno', 'Mese', 'Settimana'],
                    vals: ['Ore di lavoro'],
                    aggregatorName: 'Sum',
                    valueFilter: getStartFilters()
                }
                break;
            case 3:
                newState = {
                    rows: ['Nome utente', 'Integrazione'],
                    cols: ['Anno', 'Mese', 'Settimana'],
                    vals: ['Ore di lavoro'],
                    aggregatorName: 'Sum',
                    valueFilter: getStartFiltersActComm(workdataExtract, user)
                }
                break;
        }
        setPivotState(newState)
    }

    const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
    var averangeOverSum = ([attr, attr1]) => {
        return (data, rowKey, colKey) => {
            return {
                sum: {},
                push(record) {
                    if (!isNaN(parseFloat(record[attr1]))) {
                        // console.debug(parseFloat(record[attr1]))
                        // console.debug(data, rowKey, colKey)
                        if (parseFloat(record[attr1]) <= 0) return
                        if (!this.sum[record[attr]]) this.sum[record[attr]] = 0
                        this.sum[record[attr]] += parseFloat(record[attr1]);
                    }
                },
                value() {
                    if (Object.values(this.sum).length === 0) return 0
                    return average(Object.values(this.sum));
                },
                format: numberFormat(),
                numInputs: 2,
            };
        };
    };

    // console.debug(workdataExtract.filter(w => w.get('userId') === ussrId).sortBy(w => moment(w.get('Giorno'), 'YYYY-MM-DD')).last())
    // const lastComm = workdataExtract.filter(w => w.get('userId') === ussrId).sortBy(w => moment(w.get('Giorno'), 'YYYY-MM-DD')).last().get('Integrazione')
    // console.debug(workdataExtract.filter(w => w.get('userId') === ussrId && w.get('nc') !== lastComm).reduce((acc, w) => acc.set(w.get('Integrazione'), true)))
    return (
        <div className={'wdAgg'}>
            <Button variant={"outlined"} color={"secondary"} className={'favoriteButtons'}
                    onClick={() => handleFavoritesClick(0)}>Reset</Button>
            <Button variant={"outlined"} color={"secondary"} className={'favoriteButtons'}
                    onClick={() => handleFavoritesClick(1)}>Ore di lavoro settimanali su commessa</Button>
            <Button variant={"outlined"} color={"secondary"} className={'favoriteButtons'}
                    onClick={() => handleFavoritesClick(2)}>Ore di lavoro settimanali</Button>
            <Button variant={"outlined"} color={"secondary"} className={'favoriteButtons'}
                    onClick={() => handleFavoritesClick(3)}>Ore di lavoro settimanali ultima commessa lavorata</Button>
            <PivotTableUI
                style={{width: window.innerWidth}}
                data={workdataExtract.toJS()}
                onChange={handlePivotChange}
                aggregators={{
                    ...aggregators,
                    averangeOverSum,
                }}
                menuLimit={1000}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...pivotState}
            />
        </div>
    );
}

export default WdAgg;