import React, {useEffect, useState} from "react";
import './YearPicker.scss'
import {Paper} from "@mui/material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import moment from "moment";

const YearPicker = ({value, onYearChange, onMonthChange}) => {
    const [selectedYear, setSelectedYear] = useState()
    const [actYear, setActYear] = useState(value?.year() || new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState()
    const theme = useTheme();

    useEffect(()=>{
        if (value){
            setSelectedMonth(value?.month())
            setSelectedYear(value?.year())
        } else {
            setSelectedMonth(new Date().getMonth())
            setSelectedYear(new Date().getFullYear())
        }
    },[])

    let listYear = Array.from({length: 4}, (_, i) => actYear - 4 + i)
    listYear = listYear.concat([actYear])
    listYear = listYear.concat([...Array.from({length: 4}, (_, i) => actYear + i + 1)])

    const monthList = [...Array(12).keys()]
    moment.locale('it');
    const monthNames = moment.months()

    return (
        <Paper className={'yearPicker'} variant={"outlined"}>
            <div className={'year'}>

            <div className={'header'}>
                <IconButton
                    onClick={() => setActYear(actYear - 9)}
                >
                    <ArrowBackIosRoundedIcon/>
                </IconButton>
                <Typography>{listYear[0] + ' - ' + listYear[listYear.length - 1]}</Typography>
                <IconButton
                    onClick={() => setActYear(actYear + 9)}
                >
                    <ArrowForwardIosRoundedIcon/>
                </IconButton>
            </div>
            <Divider variant={"fullWidth"}/>
            <div className={'yearList'}>
                {
                    listYear.map(y => {
                        return (
                            <Paper
                                key={y}
                                className={'yearCard'}
                                style={{backgroundColor: y === selectedYear ? theme.palette.secondary.main : ''}}
                                onClick={() => {
                                    setSelectedYear(y)
                                    onYearChange(y)
                                }}
                            >
                                {y}
                            </Paper>
                        )
                    })
                }
            </div>
            </div>
            <Divider orientation={"vertical"} flexItem/>
            <div className={'monthList'}>
                {
                    monthList.map(m => {
                        return (
                            <Paper
                                key={m}
                                className={'monthCard'}
                                style={{backgroundColor: m === selectedMonth ? theme.palette.secondary.main : ''}}
                                onClick={() => {
                                    onMonthChange(m);
                                    selectedMonth !== m? setSelectedMonth(m): setSelectedMonth(null);
                                }}
                            >
                                {monthNames[m]}
                            </Paper>
                        )
                    })
                }
            </div>
        </Paper>
    )
}

export default YearPicker;