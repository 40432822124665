import React from 'react';
import SvgIcon from "@mui/material/SvgIcon";


let PartiallySent = (props) => (
    <SvgIcon {...props}>
        <path
            d="m 19,13 c -2.17,0 -4.07,1.16 -5.12,2.89 C 13.28,15.96 12.65,16 12,16 7.58,16 4,14.21 4,12 V 9 c 0,2.21 3.58,4 8,4 4.42,0 8,-1.79 8,-4 v 3 c 0,0.36 -0.1,0.71 -0.28,1.05 C 19.5,13 19.24,13 19,13 m -5.9,4.96 C 12.74,18 12.37,18 12,18 7.58,18 4,16.21 4,14 v 3 c 0,2.21 3.58,4 8,4 0.46,0 0.9,0 1.33,-0.06 C 13.12,20.33 13,19.68 13,19 c 0,-0.36 0.04,-0.7 0.1,-1.04 M 12.007628,3.0030685 c -4.4199813,0 -7.9999813,1.7900001 -7.9999813,4.0000001 v 0.01409 c 0,2.21 3.59,4.0000134 7.9999813,4.0000134 4.409999,0 7.999999,-1.7900134 7.999999,-4.0000134 v -0.01409 c 0,-2.21 -3.58,-4.0000001 -7.999999,-4.0000001 M 6.0076467,9.6430686 c 1.47,0.8300034 3.949263,1.3600034 5.9999813,1.3600034 2.050732,0 4.529999,-0.53 5.999999,-1.3600034 m -5.999999,-0.64 c -3.8699813,0 -5.9999813,-1.5 -5.9999813,-2 0,-0.5 2.13,-2 5.9999813,-2 3.869999,0 5.999999,1.5 5.999999,2 0,0.5 -2.13,2 -5.999999,2 z M 18,21.08 l -2.75,-3 1.16,-1.16 1.59,1.58 3.59,-3.58 1.16,1.41 -4.75,4.75"/>
    </SvgIcon>
);

export default PartiallySent;