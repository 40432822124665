import React from "react";
import './ConfirmPopUp.css'
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";


const ConfirmPopUp = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleDecline}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.cancel &&
                    <Button onClick={() => props.handleCancel()} color={'secondary'} variant={"text"} autoFocus style={{margin:'auto auto auto 0'}} >
                        {props.cancel}
                    </Button>
                }
                <Button onClick={() => props.handleDecline()} color={'secondary'} variant={"outlined"}>
                    {props.ko}
                </Button>
                <Button onClick={() => props.handleAccept()} color={'secondary'} variant={"contained"} autoFocus>
                    {props.ok}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmPopUp;
