import {enqueueSnackbar} from "./actions/action";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
};

export const parseTime = (s) => {
    // console.log(s);
    s = new Date(s);
    return s.getMinutes() + s.getHours() * 60;
};

export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const errorNotification = (err, errorCode,  msgList = {}, defaultMessage = 'Errore interno!') => {
    let message = defaultMessage
    if (err.response) {
        Object.keys(msgList).forEach(k => {
            if (err.response.status === k) message = msgList[k]
        })
    } else {
        message = err.message
    }
    return enqueueSnackbar({
        message: `${errorCode} - ${message}`,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
        },
    })
}
