import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import './Integration.scss'
import TextField from "@mui/material/TextField";
import CommStat from "../../../Library/CommStat/CommStat";
import {Map} from 'immutable'
import DateFnsUtils from '@date-io/date-fns';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {InputAdornment} from "@mui/material";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

const Integration = (props) => {
    const [integrationCode, setIntegrationCode] = useState('')


    useEffect(()=>{
        setIntegrationCode(props.integration.get('integration_code'))
    }, [props.integration.get('integration_code')])
    // let user_list = null
    // if (props.integration.getIn(['statistics', 'technicians'])) {
    //     user_list = props.integration.getIn(['statistics', 'technicians']).map(tech => {
    //         return (
    //             <AvatarTechnicians
    //                 key={'avatarTech' + tech.get('name')}
    //                 name={tech.get('name')}
    //                 surname={tech.get('surname')}
    //                 totHours={Math.round(tech.get('load'))}
    //             />
    //         )
    //     })
    // }


    return (
        <div className={'integration'}>
            <Paper variant={'outlined'} className={'integration_general'} transparent>
                <Typography className={'title'}>Generali</Typography>
                <Divider/>
                <div className={'columns'}>
                    <div className={'column1'}>
                        <TextField
                            label={'Progressivo'}
                            variant={"outlined"}
                            value={props.integration.get('client_code') + props.integration.get('commission_code') + '_' + integrationCode}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={(event) => {
                                    const _value = event.target.value.slice(9,11) || ''

                                    setIntegrationCode(_value)
                                }}
                            onBlur={
                                (event) => {
                                    const _value = event.target.value.slice(9,11) || props.integration.get('integration_code')

                                    props.onValueChange(props.index, 'integration_code', _value)
                                }
                            }

                            // InputProps={{
                            //     startAdornment: <InputAdornment
                            //         position="start">{props.integration.get('client_code') + props.integration.get('commission_code') + '_'}</InputAdornment>,
                            // }}
                        />
                        <TextField
                            label={'Commessa cliente'}
                            variant={"outlined"}
                            value={props.integration.get('client_comm')}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'client_comm', event.target.value)
                            }
                        />
                        <TextField
                            label={'Descrizione'}
                            variant={"outlined"}
                            value={props.integration.get('description')}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            multiline
                            rows={4}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'description', event.target.value)
                            }
                        />
                        <TextField
                            label={'Note'}
                            variant={"outlined"}
                            value={props.integration.get('notes', '')}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            multiline
                            rows={4}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'notes', event.target.value)
                            }
                        />
                        <ToggleButtonGroup
                            value={props.integration.get('contract_type', 0)}
                            exclusive
                            onChange={(event, newValue) => props.onValueChange(props.index, 'contract_type', newValue)}
                            className={'contractType'}
                            fullWidth
                            color={"secondary"}
                            disabled={!props.edit}
                        >
                            <ToggleButton value={1}>
                                Corpo
                            </ToggleButton>
                            <ToggleButton value={2}>
                                Consuntivo
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </div>
                    <div className={'column2'}>
                        <TextField
                            label={'Contratto'}
                            variant={"outlined"}
                            value={props.integration.get('contract')}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'contract', event.target.value)
                            }
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                renderInput={(props) => <TextField color={'secondary'} size={'small'}
                                                                   margin={'dense'} {...props} />}
                                disableToolbar
                                className={'textfield'}
                                label={'Data contratto'}
                                // inputFormat="dd/MM/yyyy"
                                id="date-picker-inline"
                                value={props.integration.get('contract_date') ? moment(props.integration.get('contract_date'), 'YYYY-MM-DD') : null}
                                disabled={!props.edit}
                                onChange={
                                    (event) => {
                                        if (isValidDate(event)) {
                                            console.debug('valid')
                                            props.onValueChange(props.index, 'contract_date', moment(event).format('YYYY-MM-DD'))
                                        } else if (event === null) {
                                            console.debug('event null')
                                            props.onValueChange(props.index, 'contract_date', null)
                                        }
                                    }}
                            />
                        </LocalizationProvider>
                        <TextField
                            label={'Offerta'}
                            variant={"outlined"}
                            value={props.integration.get('offer')}
                            size={'small'}
                            margin={'dense'}
                            className={'textfield'}
                            color={'secondary'}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'offer', event.target.value)
                            }

                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
                            <DatePicker
                                renderInput={(props) => <TextField color={'secondary'} size={'small'}
                                                                   margin={'dense'} {...props} />}
                                disableToolbar
                                className={'textfield'}
                                label={'Data offerta'}
                                inputFormat="dd/MM/yyyy"
                                id="date-picker-inline"
                                value={props.integration.get('offer_date') ? moment(props.integration.get('offer_date'), 'YYYY-MM-DD') : null}
                                disabled={!props.edit}
                                onChange={
                                    (event) => {
                                        if (isValidDate(event)) {
                                            props.onValueChange(props.index, 'offer_date', moment(event).format('YYYY-MM-DD'))
                                        } else if (event === null) {
                                            props.onValueChange(props.index, 'offer_date', null)
                                        }
                                    }
                                }
                            />
                        </LocalizationProvider>
                        <FormControlLabel
                            control={<Checkbox color="secondary"/>}
                            label="Chiusa"
                            labelPlacement="end"
                            className={'checkbox'}
                            checked={props.integration.get('closed')}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'closed', event.target.checked)
                            }
                        />
                        <Divider/>
                        <FormControlLabel
                            control={<Checkbox color="secondary"/>}
                            label="Commessa di rendicontazione"
                            labelPlacement="end"
                            className={'checkbox'}
                            checked={props.integration.get('reporting')}
                            disabled={!props.edit}
                            onChange={
                                (event) =>
                                    props.onValueChange(props.index, 'reporting', event.target.checked)
                            }
                        />
                    </div>
                </div>
            </Paper>
            {/*<Paper variant={'outlined'} className={'integration_contacts'}>*/}
            {/*    <Typography>Contatti</Typography>*/}
            {/*    <Divider style={{margin: '0 0 10px 0'}}/>*/}


            {/*</Paper>*/}

            <CommStat
                statistics={props.integration.getIn(['statistics', 'stat']) ? props.integration.getIn(['statistics', 'stat'], Map({})) : Map({})}/>

        </div>

    )
}

export default Integration;