import React, {useEffect, useState} from "react";
import {Dialog, InputAdornment} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import './PopUpNewComm.css'
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {checkValidity} from "../Utils/Utils";

const PopUpNewComm = (props) => {

    const [commission, setCommission] = useState((new Date().getYear() -100).toString())
    const [client, setClient] = useState('')
    const [description, setDescription] = useState('')


    useEffect(() => {
        if (props.open) {
            setClient(props.act_client)
        } else {
            setClient('')
            setCommission((new Date().getYear() -100).toString())
        }
    }, [props.open]);


    return (
        <Dialog open={props.open} onClose={props.onClose} className={'popUpNewComm'}>
            <DialogTitle className={'header'}>
                Nuova commessa
            </DialogTitle>
            <Divider/>
            <DialogContent className={'content'}>
                <TextField
                    label={'Codice cliente'}
                    required
                    type="number"
                    helperText={'es. 9000'}
                    variant={"outlined"}
                    value={client}
                    margin={"dense"}
                    size={"medium"}
                    color={'secondary'}
                    onChange={(event) => {
                        if (event.target.value.length <= 4) {
                            setClient(event.target.value.toString())
                        }
                    }}
                    error={!checkValidity(client, {
                        required: true,
                        minLength: 4,
                        maxLength: 4,
                    })}
                />
                <TextField
                    label={'Codice commessa'}
                    required
                    type="number"
                    variant={"outlined"}
                    value={commission}
                    margin={"dense"}
                    size={"medium"}
                    color={'secondary'}
                    helperText={'es. 2101'}
                    InputProps={{
                        startAdornment: <InputAdornment
                            position="start">{client}</InputAdornment>,
                    }}
                    onChange={(event) => {
                        if (event.target.value.length <= 4) {
                            setCommission(event.target.value.toString())
                        }
                    }}
                    error={!checkValidity(client, {
                        required: true,
                        minLength: 4,
                        maxLength: 4,
                    })}
                />
                <TextField
                    variant={"outlined"}
                    id="description"
                    label="Descrizione"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(event) => setDescription(event.target.value.toString())}
                    color={"secondary"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color={"secondary"}>Annulla</Button>
                <Button variant={"outlined"} style={{width: '100px'}} color={"secondary"}
                        onClick={() => props.onNewComm(client, commission, description)}
                        disabled={client.length !== 4 || commission.length !== 4}
                >Crea</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PopUpNewComm;