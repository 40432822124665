import React, {useEffect, useMemo, useState} from "react";
import './WorkDataCompilation.scss';
import {Map} from 'immutable'
import {useDispatch, useSelector} from "react-redux";
import TableCell from "./TableCell/TableCell";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {getAllUsers, getWorkdataCompilation} from "../../store/actions/MngData";
import MonthPicker from "../Library/MonthPicker/MonthPicker";
import moment from "moment";
import CleanData from "../Library/Icons/CleanData";
import UnsentData from "../Library/Icons/UnsentData";
import PartiallySent from "../Library/Icons/PartiallySent";
import Sent from "../Library/Icons/Sent";
import NoData from "../Library/Icons/NoData";
import {Tooltip} from "@mui/material";

const getAllDaysInMonth = (month, year) =>
    Array.from(
        {length: new Date(year, month, 0).getDate()},
        (_, i) => new Date(year, month - 1, i + 1).getDate()
    );


const WorkDataCompilation = ({}) => {
    const dispatch = useDispatch()

    const users = useSelector(state => state.mng_data.users)
    const workdataCompilation = useSelector(state => state.mng_data.workdataCompilation)

    const [date, setDate] = useState(()=>{
        let _t = moment()
        if (workdataCompilation.get('year')){
            _t = _t.year(workdataCompilation.get('year'))
            _t = _t.month(workdataCompilation.get('month') - 1)
        }
        return _t.startOf('month')
    })
    const month = date.month() + 1
    const year = date.year()

    useEffect(() => {
        dispatch(getWorkdataCompilation(year, month))
    }, [date])

    useEffect(() => {
        dispatch(getAllUsers())
    }, [])

    const columns = useMemo(() => {
            return [{
                field: `userId`,
                headerName: 'Tecnico',
                minWidth: 200,
            }].concat(getAllDaysInMonth(month, year).map(d => {
                return {
                    field: d.toString(),
                    headerName: d.toString(),
                    width: 70,
                    date: new Date(year, month - 1, d),
                    weekend: !(new Date(year, month - 1, d).getDay() % 6),
                    renderCell: (params) => {
                        return <TableCell key={`${params.id} ${year} ${month}`} cell={params}/>
                    },
                    sortable: false
                }
            }))
        }, [workdataCompilation]
    )


    const data = useMemo(() => {
        if (users.size > 0) {
            return Object.values(workdataCompilation.get('data', Map([])).map((v, k) => {
                const user = users?.find(u => u.get('id') === parseInt(k))
                return {
                    userId: `${user?.get('surname')} ${user?.get('name')}`,
                    ...v.toJS()
                }
            }).toList().toJS())
        } else return Map({})
    }, [users, workdataCompilation])


    return (
        <div style={{height: window.innerHeight - 140}} className={'workDataCompilation'}>
            <div style={{display: 'flex'}} className={'toolbar'}>

                <MonthPicker
                    value={date}
                    onChange={(n) => {
                        setDate(n)
                    }}
                />
                <div className={'legend'}>

                    <Tooltip title={'Non compilato'}>
                        <div className={'iconContainer'}>
                            <CleanData className={'icon'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={'Compilato - Non inviato'}>
                        <div className={'iconContainer'}>
                            <UnsentData className={'icon'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={'Inviato in parte'}>
                        <div className={'iconContainer'}>
                            <PartiallySent className={'icon'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={'Inviato'}>
                        <div className={'iconContainer'}>
                            <Sent className={'icon'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={'Nessun dato'}>
                        <div className={'iconContainer'}>
                            <NoData className={'icon'}/>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <DataGridPremium
                disableColumnMenu
                disableColumnReorder
                disableColumnSelector
                disableColumnResize
                hideFooter
                rows={data}
                columns={columns}
                getRowId={(row) => row.userId}
                initialState={{
                    pinnedColumns: {left: ['userId']},
                    sorting: {sortModel: [{field: 'userId', sort: 'asc'}]},
                }}
                disableVirtualization
                showCellRightBorder
                showColumnRightBorder
            />
        </div>
    )

}

export default WorkDataCompilation;