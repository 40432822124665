import React, {useState} from "react"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import "./Purchase.scss"
import * as pMap from '../../../../store/stateStr/PurchMap'
import {connect} from "react-redux";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {TextField, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import {NumberFormatCustom} from "../../../Library/Utils/Utils";

// function NumberFormatCustom(props) {
//     const {inputRef, onChange, ...other} = props;
//     return (
//         <NumberFormat
//             {...other}
//             getInputRef={inputRef}
//             onValueChange={values => {
//                 onChange({
//                     target: {
//                         value: values.value,
//                     },
//                 });
//             }}
//             // thousandSeparator
//             fixedDecimalScale={true}
//             isNumericString
//             prefix="€ "
//         />
//     );
// }

const Purchase = (props) => {
    const [fieldChanged, setFieldChanged] = useState(false)
    return (
        <div className="purchase_v2">
            <TextField
                id={"Descrizione-" + props.purch.get(pMap.DbKey)}
                variant="outlined"
                className={'textFieldNote'}
                disabled={props.lockedData}
                margin="dense" size="small"
                type="text"
                label="Descrizione"
                color="secondary"
                defaultValue={props.purch.get(pMap.Descrizione)} //className="inputCommessa data"
                onChange={() => {
                    setFieldChanged(true)
                }}
                onBlur={(event) => {
                    if (!fieldChanged) return
                    setFieldChanged(false)
                    props.onPropsPurchChangeHandler(event.target.value, props.idx_day, props.idx_comm, props.idx_purch, pMap.Descrizione, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))
                }}
            />
            <div className={'subRow'} style={{flex: 'unset'}}>
                <TextField
                    id={"totale-" + props.purch.get(pMap.DbKey)}
                    variant="outlined"
                    className={'textfield'}
                    disabled={props.lockedData}
                    margin="dense" size="small"
                    label="Totale"
                    color="secondary"
                    defaultValue={props.purch.get(pMap.Totale)}
                    onChange={() => {
                        setFieldChanged(true)
                    }}
                    onBlur={(event) => {
                        if (!fieldChanged) return
                        setFieldChanged(false)
                        if (event.target.value === '') {
                            props.onPropsPurchChangeHandler(0, props.idx_day, props.idx_comm, props.idx_purch, pMap.Totale, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))
                        } else {
                            props.onPropsPurchChangeHandler(parseFloat(event.target.value.substring(2)), props.idx_day, props.idx_comm, props.idx_purch, pMap.Totale, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))
                        }
                    }}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
                <ToggleButtonGroup
                    className={'money_type'}
                    value={props.purch.get(pMap.Metodo)}
                    disabled={props.lockedData}
                    exclusive
                    onChange={(event, newValue) => {
                        if (newValue) {
                            props.onPropsPurchChangeHandler(newValue, props.idx_day, props.idx_comm, props.idx_purch, pMap.Metodo, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))
                        }
                    }}
                    color={"secondary"}
                >
                    <ToggleButton value={1} aria-label="left aligned">
                        <Tooltip title={'Pagamento in euro con carta'}>
                            <CreditCardIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="centered">
                        <Tooltip title={'Pagamento in euro in contanti'}>
                            <EuroIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="centered">
                        <Tooltip title={'Pagamento in moneta estera con carta'}>
                            <LocalAtmRoundedIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="centered">
                        <Tooltip title={'Pagamento in moneta estera in contanti'}>
                            <AttachMoneyIcon/>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {/*<TextField*/}
            {/*    id={"cambio-" + props.purch.get(pMap.DbKey)}*/}
            {/*    variant="outlined"*/}
            {/*    className={'textfield'}*/}
            {/*    margin="dense" size="small"*/}
            {/*    label="Totale"*/}
            {/*    defaultValue={props.purch.get(pMap.Totale)}*/}
            {/*    onBlur={(event) => {*/}
            {/*        if (event.target.value === '') {*/}
            {/*            props.onPropsPurchChangeHandler(0, props.idx_day, props.idx_comm, props.idx_purch, pMap.Totale, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))*/}
            {/*        } else {*/}
            {/*            props.onPropsPurchChangeHandler(event.target.value.substring(2), props.idx_day, props.idx_comm, props.idx_purch, pMap.Totale, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}
            <TextField
                id={"Note-" + props.purch.get(pMap.DbKey)}
                variant="outlined"
                className={'textFieldNote'}
                style={{marginRight: '10px'}}
                disabled={props.lockedData}
                margin="dense" size="small"
                type="text"
                label="Note"
                color="secondary"
                defaultValue={props.purch.get(pMap.Note)} //className="inputCommessa data"
                onChange={() => {
                    setFieldChanged(true)
                }}
                onBlur={(event) => {
                    if (!fieldChanged) return
                    setFieldChanged(false)
                    props.onPropsPurchChangeHandler(event.target.value, props.idx_day, props.idx_comm, props.idx_purch, pMap.Note, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))
                }}/>
            <div style={{marginRight: '0', marginLeft: 'auto'}}>
                {/*<div className={'actionBtn'}>*/}
                <Fab color="secondary" className={'circButton'}
                     disabled={props.lockedData}
                     onClick={() => props.AddPurch(props.idx_day, props.idx_comm, props.purch.get(pMap.Day), props.id_comm)}>
                    <AddIcon/>
                </Fab>
                <Fab color="secondary" className={'circButton'}
                     disabled={props.lockedData}
                     onClick={() => props.RemovePurch(props.idx_day, props.idx_comm, props.idx_purch, props.purch.get(pMap.Day), props.id_comm, props.purch.get(pMap.DbKey))}>
                    <RemoveIcon/>
                </Fab>
                {/*</div>*/}
            </div>
        </div>
    )
};


// const mapStateToProps = state => {
//     return {
//         date: state.day
//     };
// };

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(null, mapDispatchToProps)(Purchase)

