import React, {Component} from "react";
import Paper from "@mui/material/Paper";
import DragAndDropV2 from "../Library/DragAndDropV2/DragAndDropV2";
import './Settings.scss'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {update_sign, update_user, updateTheme} from "../../store/actions/userData";
import {toBase64} from "../Library/Utils/Utils";
import TextField from "@mui/material/TextField";
import {Map} from 'immutable'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {changePassword} from "../../store/actions/auth";
import ThemeButton from "../Library/ThemeButton/ThemeButton";


class Settings extends Component {

    state = {
        user: Map({}),
        dialog_password: false,

        oldPassword: '',
        newPassword1: '',
        newPassword2: '',

        customPrimaryColor: '#FFFFFF',
        customSecondaryColor: '#FFFFFF',
        customTertiaryColor: '#FFFFFF',

    }

    componentDidMount() {
        this.setState({user: this.props.user})
    }


    render() {

        const handleDrop = (files) => {
            if (files[0].type === 'image/png') {
                toBase64(files[0]).then((data) => {
                        this.setState(({user}) => (
                            {
                                user: user.update('sign', sign => data)
                            }
                        ))
                    }
                )
            }
            //  'data:image/png;base64,' +
            // this.props.update_sign(toBase64(files[0]))
        };

        const onChangeUserProps = (key, value) => {
            this.setState(({user}) => ({user: user.update(key, val => value)}))
        }

        const onCalcel = () => {
            this.setState({user: this.props.user})

        }

        const opneDialog = () => {
            this.setState({dialog_password: true})

        }

        const closeDialog = () => {
            this.setState({dialog_password: false})
            this.setState({
                passwords: Map({
                    oldPassword: '',
                    newPassword1: '',
                    newPassword2: '',
                })
            })

        }

        const onChangePassword = (key, value) => {
            this.setState(({passwords}) => ({passwords: passwords.update(key, val => value)}))
        }

        const onThemeChange = (primaryColor, secondaryColor, tertiaryColor) => {
            this.props.updateTheme(primaryColor, secondaryColor, tertiaryColor)
            this.setState((state) => {
                return {
                    user: state.user.updateIn(['config', 'theme'], () => Map({
                        primaryColor,
                        secondaryColor,
                        tertiaryColor
                    }))
                }
            })
        }

        return (
            <React.Fragment>
                <div className={'settings'}>
                    <Paper className={'category user'}>
                        <div className={'title'}>
                            <Typography variant={'h5'} style={{margin: '0 0 0 0'}}>
                                Impostazioni utente
                            </Typography>
                        </div>
                        <div className={'body'}>
                            <TextField
                                variant={"outlined"}
                                color={'secondary'}
                                margin={"dense"}
                                label={'Nome'}
                                value={this.state.user.get('name')}
                                onChange={(event) => onChangeUserProps('name', event.target.value)}
                            />
                            <TextField
                                variant={"outlined"}
                                color={'secondary'}
                                margin={"dense"}
                                label={'Cognome'}
                                value={this.state.user.get('surname')}
                                onChange={(event) => onChangeUserProps('surname', event.target.value)}
                            />

                            <Button
                                variant={"outlined"}
                                className={'password'}
                                onClick={opneDialog}
                            >
                                Cambia password
                            </Button>

                        </div>
                        {/*<Divider*/}
                        {/*    variant={"fullWidth"}*/}
                        {/*/>*/}
                        <div className={'title'}>
                            <Typography variant={'h5'} style={{margin: '0 0 0 0'}}>
                                Documenti
                            </Typography>
                        </div>
                        <div className={'body'}>
                            <Paper className={'signConteiner'} elevation={6} variant={"outlined"}>
                                <Typography>Firma</Typography>
                                <Typography variant={'body2'} style={{color: '#9e9e9e'}}>(Dimensioni:600px x
                                    200px)</Typography>
                                <Paper className={'sign'} variant={"outlined"}>
                                    <DragAndDropV2 handleDrop={handleDrop} dragCounter={0}>
                                        <img className='signImg'
                                            // src={this.props.sign ? URL.createObjectURL(this.props.sign) : null}
                                             src={this.state.user.get('sign') ? this.state.user.get('sign') : null}
                                             alt="Trascina qua la tua firma"/>
                                    </DragAndDropV2>
                                </Paper>
                            </Paper>
                        </div>
                        {this.props.user.getIn(['config', 'alpha']) &&
                            <>
                                <div className={'title'}>
                                    <Typography variant={'h5'} style={{margin: '0 0 0 0'}}>
                                        Tema
                                    </Typography>
                                </div>
                                <div className={'body'}>
                                    <div className={'themeSelector'}>
                                        {this.props.themes.map(t => {
                                            if (t.get('visible')) {
                                                return <ThemeButton
                                                    name={t.get('name')}
                                                    primaryColor={t.get('color1')}
                                                    secondaryColor={t.get('color2')}
                                                    tertiaryColor={t.get('color3')}
                                                    onClick={onThemeChange}
                                                />
                                            }
                                        })}

                                        <ThemeButton
                                            primaryColor={'#fafafa'}
                                            secondaryColor={'#212121'}
                                            tertiaryColor={'#212121'}
                                            onClick={() => window.alert('Il tema Juventino è da sfigato!!')}
                                        />
                                        {/*<div className={'themButton'} onClick={()=>this.props.updateTheme('#039be5', '#689f38')}>*/}
                                        {/*    <div className={'primaryColorTheme'}/>*/}
                                        {/*    <div className={'secondaryColorTheme'}/>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}

                                        {/*    <TextField*/}
                                        {/*        label={'Primary color'}*/}
                                        {/*        defaultValue={'#'}*/}
                                        {/*        value={this.state.customPrimaryColor}*/}
                                        {/*        onChange={(e) => this.setState({customPrimaryColor: e.target.value})}*/}
                                        {/*    />*/}
                                        {/*    <TextField*/}
                                        {/*        label={'Secondary color'}*/}
                                        {/*        defaultValue={'#'}*/}
                                        {/*        value={this.state.customSecondaryColor}*/}
                                        {/*        onChange={(e) => this.setState({customSecondaryColor: e.target.value})}*/}
                                        {/*    />*/}
                                        {/*    <TextField*/}
                                        {/*        label={'Tertiary color'}*/}
                                        {/*        defaultValue={'#'}*/}
                                        {/*        value={this.state.customTertiaryColor}*/}
                                        {/*        onChange={(e) => this.setState({customTertiaryColor: e.target.value})}*/}
                                        {/*    />*/}
                                        {/*    <Button onClick={() => {*/}
                                        {/*        const primaryColor = this.state.customPrimaryColor*/}
                                        {/*        const secondaryColor = this.state.customSecondaryColor*/}
                                        {/*        const tertiaryColor = this.state.customTertiaryColor*/}
                                        {/*        this.props.updateTheme(primaryColor, secondaryColor, tertiaryColor)*/}
                                        {/*        this.setState((state) => {*/}
                                        {/*            return {*/}
                                        {/*                user: state.user.updateIn(['config', 'theme'], () => Map({*/}
                                        {/*                    primaryColor,*/}
                                        {/*                    secondaryColor,*/}
                                        {/*                    tertiaryColor*/}
                                        {/*                }))*/}
                                        {/*            }*/}
                                        {/*        })*/}
                                        {/*    }}*/}
                                        {/*    >Applica</Button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </>
                        }
                    </Paper>
                    <div className={'action'}>
                        <Button className={'bottom'}
                                variant={"text"}
                                onClick={() => onCalcel()}
                        >
                            Annulla
                        </Button>
                        <Button className={'bottom'}
                                variant={"contained"}
                                onClick={() => this.props.update_user(this.state.user)}
                        >
                            Salva
                        </Button>
                    </div>
                </div>
                <Dialog open={this.state.dialog_password} onClose={closeDialog} className={'new_password'}>
                    <DialogTitle id="form-dialog-title">Nuova password</DialogTitle>
                    <DialogContent className={'content'}>
                        <DialogContentText>
                            La password deve essere lunga almeno 8 caratteri!
                        </DialogContentText>
                        <TextField
                            variant={"outlined"}
                            color={'secondary'}
                            margin={"dense"}
                            label={'Vecchia password'}
                            type={'password'}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            value={this.state.oldPassword}
                            onChange={(event) => this.setState({oldPassword: event.target.value})}
                        />
                        <TextField
                            variant={"outlined"}
                            color={'secondary'}
                            margin={"dense"}
                            label={'Nuova password'}
                            type={'password'}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            value={this.state.newPassword1}
                            onChange={(event) => this.setState({newPassword1: event.target.value})}
                        />
                        <TextField
                            variant={"outlined"}
                            color={'secondary'}
                            margin={"dense"}
                            label={'Ripeti nuova password'}
                            type={'password'}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            value={this.state.newPassword2}
                            onChange={(event) => this.setState({newPassword2: event.target.value})}
                            error={this.state.newPassword1 !== this.state.newPassword2}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="inherit">
                            Annulla
                        </Button>
                        <Button
                            onClick={() => this.props.changePassword(this.state.oldPassword, this.state.newPassword1, this.state.newPassword2).then(() => closeDialog())}
                            color="inherit" variant={"outlined"}
                            disabled={
                                this.state.newPassword1 !== this.state.newPassword2 ||
                                this.state.newPassword1.length < 8
                            }
                        >
                            Conferma
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('userData'),
        themes: state.meta.themes
    };
};

const mapDispatchToProps = dispatch => {
    return {

        update_sign: (sign) => dispatch(update_sign(sign)),
        // get_sign: () => dispatch(get_sign()),
        update_user: (data) => dispatch(update_user(data)),
        changePassword: (...args) => dispatch(changePassword(...args)),
        updateTheme: (...args) => dispatch(updateTheme(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);