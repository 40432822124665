import React, {useEffect, useMemo, useState} from 'react';
import "./Mng.scss"
import {useDispatch, useSelector} from "react-redux";
import {add_client, add_comm, get_mng_data} from "../../store/actions/MngData";
import {ButtonBase, Paper, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography} from "@mui/material";
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import PopUpNewComm from "../Library/PopUpNewComm/PopUpNewComm";
import PopUpNewClient from "../Library/PopUpNewClient/PopUpNewClient";
import {Map} from "immutable";
import Commission from "./Commission/Commission";
import CommissionDialog from "./CommissionDialog/CommissionDialog";
import Client from "./Client/Client";
import ClientDialog from "./ClientDialog/ClientDialog";

Date.prototype.addDays = function (days) {
    var date = new Date(valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

const Mng = () => {
    const dispatch = useDispatch()

    const mng_data = useSelector(state => state.mng_data.mng_data)
    const clients = useSelector(state => state.mng_data.clients)
    const defaultCli = useSelector(state => state.auth.user.getIn(["userData", "config", "mng", "default_cli"], '9000'))
    const mngEdit = useSelector(state => state.auth.user.getIn(['role', 'edit_mng_comm']))
    const viewMng = useSelector(state => state.auth.user.getIn(['role', 'view_mng_comm']))

    const [dialogNewComm, setDialogNewComm] = useState(false)
    const [dialogNewCli, setDialogNewCli] = useState(false)
    const [dialogEditCommission, setDialogEditCommission] = useState({client_index: '', commission_index: ''})
    const [dialogClient, setDialogClient] = useState('')

    const [filter, setFilter] = useState(Map({client_code: '9000', client_comm: '', contract: ''}))


    useEffect(() => {
        // dispatch(get_mng_data())
    }, [])

    const onAddNewClient = (...args) => {
        dispatch(add_client(...args)).then((res) => setDialogNewCli(false))
    }

    const onAddNewCommission = (...args) => {
        dispatch(add_comm(...args)).then((res) => setDialogNewComm(false))
    }

    const updateFiler = (key, value) => {
        if ((key === 'client_code' || key === 'comm_code') && value.length > 8 && /^\d+$/.test(value)) return
        //
        setFilter(oldState => oldState.update(key, v => value))
    }


    const actions = [
        {icon: <FolderRoundedIcon/>, name: 'Nuova commessa', event: () => setDialogNewComm(true)},
        {icon: <AssignmentIndRoundedIcon/>, name: 'Nuovo cliente', event: () => setDialogNewCli(true)},
    ];


    let comm = null
    comm = useMemo(() => {
        if (mng_data.size !== 0) {
            return mng_data.map((cli) => {
                if (cli.get('commissions')) {
                    return cli.get('commissions')
                        .filter(c => {
                            if (filter.get('client_comm')) {
                                return c.get('integrations').some(i => i.get('client_comm')?.toString().toLowerCase().includes(filter.get('client_comm').toLowerCase()))
                            } else if (filter.get('contract')) {
                                return c.get('integrations').some(i => i.get('contract')?.toString().toLowerCase().includes(filter.get('contract').toLowerCase()))
                            } else if (filter.get('client_code').length <= 3) {
                                // return c.get('client_code').includes(filter.get('client_code').slice(0, 4))
                            } else {
                                return `${c.get('client_code')}${c.get('commission_code')}`.startsWith(filter.get('client_code'))
                            }
                        })
                        .sortBy(item => -item.get('commission_code'))
                        // .take(10)
                        .map((c) => {
                            const client_index = mng_data.findIndex(client => client.get('client_code') === c.get('client_code'))
                            const commission_index = mng_data.getIn([client_index, 'commissions']).findIndex(commission => commission.get('commission_code') === c.get('commission_code'))
                            return (
                                <Commission
                                    key={`c_${c.get('client_code')}${c.get('commission_code')}`}
                                    commission={c}
                                    onCommissionSelected={() => setDialogEditCommission({
                                        client_index: client_index,
                                        commission_index: commission_index
                                    })}
                                />
                            )
                        })
                }
            })

        }
    }, [mng_data, filter])

    let filteredClient = null
    if (filter.get('client_code').length >= 4 && /^[0-9]*$/.test(filter.get('client_code')) && mng_data.size !== 0) {
        filteredClient = mng_data.find(c => filter.get('client_code').slice(0, 4) === c.get('client_code'))
    }

    let client
    client = useMemo(() => {
        return mng_data
            .filter(c => {
                    if (filter.get('client_code').length < 4 || !/^[0-9]*$/.test(filter.get('client_code'))) {
                        return filter.get('client_code') ? (c.get('client_code') || 0).toString().includes(filter.get('client_code')?.slice(0, 4)) || c.get('company_name').toLowerCase().includes(filter.get('client_code').toLowerCase()) : true
                    } else return true
                }
            )
            .sortBy(item => item.get('client_code'))
            .map(c => {
                return (
                    <ButtonBase className={'client_button'}
                                onClick={() => updateFiler('client_code', c.get('client_code').toString())}
                                key={c.get('client_code').toString()}
                    >
                        <div variant={"elevation"} style={{width: '100%'}}>
                            <Typography className={'client_code'}>
                                {('0000' + c.get('client_code')).slice(-4) + ' - ' + c.get("company_name")}
                            </Typography>
                        </div>
                    </ButtonBase>
                )
            })
    }, [mng_data, filter.get('client_code').slice(0, 4)])

    let dialogClientIndex = null
    if (dialogClient !== '') {
        dialogClientIndex = mng_data.findIndex(c => c.get('client_code') === dialogClient)
    }
    return (
        <div className={'mng_comm'}>
            <div className={'filter'}>
                <Paper className={'advanced_filter'}>
                    <TextField
                        label={'Cliente/Commessa'}
                        value={filter.get('client_code') || ''}
                        onChange={(event) => updateFiler('client_code', event.target.value)}
                        variant={"outlined"}
                        // helperText={'Minimo 3 caratteri'}
                        size="small"
                        margin={"dense"}
                        type="search"
                        color={"secondary"}

                    />
                    <TextField
                        label={'Commessa cliente'}
                        value={filter.get('client_comm') || ''}
                        onChange={(event) => updateFiler('client_comm', event.target.value)}
                        variant={"outlined"}
                        size="small"
                        margin={"dense"}
                        type="search"
                        color={"secondary"}
                        // helperText={' '}
                    />
                    <TextField
                        label={'Contratto'}
                        value={filter.get('contract') || ''}
                        onChange={(event) => updateFiler('contract', event.target.value)}
                        variant={"outlined"}
                        size="small"
                        margin={"dense"}
                        type="search"
                        color={"secondary"}
                        // helperText={' '}
                    />
                </Paper>
                <div className={'container_list_clients'}>
                    {client}
                </div>
            </div>
            <div className={'commission'}>
                {filteredClient !== null && filteredClient !== undefined && <Client
                    id={filteredClient.get('client_code')}
                    client={filteredClient}
                    onClientSelection={(clientCode) => {
                        if (viewMng) {
                            setDialogClient(clientCode)
                        }
                    }}
                />}
                {comm}
            </div>
            {mngEdit &&
                <div className={'speedDial'}>
                    <SpeedDial
                        ariaLabel=" SpeedDial example"
                        className={''}
                        hidden={false}
                        icon={<SpeedDialIcon/>}
                        // onClose={onSpeedDialClose}
                        // onOpen={onSpeedDialOpen}
                        // open={speeddial_state}
                        direction={'left'}
                        // onClick={onSpeedDialClick}
                        // onBlur={onSpeedDialClose}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipPlacement={"top"}
                                onClick={() => action.event()}
                                title={action.name}/>
                        ))}
                    </SpeedDial>
                </div>
            }
            <PopUpNewComm
                open={dialogNewComm}
                onClose={() => setDialogNewComm(false)}
                act_client={filter.get('client_code') !== '' ? filter.get('client_code') : defaultCli}
                onNewComm={(...args) => onAddNewCommission(...args)}
            />
            <PopUpNewClient
                open={dialogNewCli}
                onClose={() => setDialogNewCli(false)}
                onNewCli={(...args) => onAddNewClient(...args)}
            />
            <CommissionDialog
                commission={mng_data.getIn([dialogEditCommission.client_index, 'commissions', dialogEditCommission.commission_index], Map({}))}
                edit={mngEdit}
                onClosePopUp={() => setDialogEditCommission({client_index: '', commission_index: ''})}
            />
            {viewMng && mng_data.size !== 0 &&
                <ClientDialog
                    client={mng_data.get(dialogClientIndex, Map({}))}
                    onClose={() => setDialogClient('')}
                />
            }
        </div>
    )
}

export default Mng;