import React from 'react';
import SvgIcon from "@mui/material/SvgIcon";

let LessTimeIcon = (props) => (
    <SvgIcon {...props}>
        <path
            d="M10.75,8C10.34,8,10,8.34,10,8.75v4.69c0,0.35,0.18,0.67,0.47,0.85l3.64,2.24c0.33,0.2,0.76,0.11,0.97-0.21 c0.23-0.34,0.12-0.8-0.23-1.01L11.5,13.3V8.75C11.5,8.34,11.16,8,10.75,8z"/>
        <path
            d="M17.92,12c0.05,0.33,0.08,0.66,0.08,1c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-3.9,3.1-7,7-7c0.7,0,1.37,0.1,2,0.29V4.23 C12.36,4.08,11.69,4,11,4c-5,0-9,4-9,9s4,9,9,9s9-4,9-9c0-0.34-0.02-0.67-0.06-1H17.92z"/>
        <path
            d="m 22,6.1734706 c -6,0 -1.449867,0 -6,0 -0.55,0 -1,0.45 -1,1 0,0.55 0.45,1 1,1 6,0 0,0 6,0 0.55,0 1,-0.45 1,-1 0,-0.55 -0.45,-1 -1,-1 z"/>
    </SvgIcon>
);

export default LessTimeIcon;