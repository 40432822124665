import React, {Component} from 'react'
import TextField from '@mui/material/TextField';
import "./Login.scss"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as action from '../../store/actions/index'
import {connect} from "react-redux";
import {checkValidity} from "../Library/Utils/Utils";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

class Login extends Component {

    state = {
        email: {
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
        },
        password: {
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            valid: false,
        },
        showPassword: false,
        logged: false,
    };

    // componentDidMount() {
    //     this.props.onAuth('luca.rossetto@eurekasystem.it', 'Luca_9719');
    // }


    handleEmailChange = (event) => {
        this.setState({
                email: {
                    ...this.state.email,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, this.state.email.validation),
                }
            }
        )
    };

    handlePasswordChange = (event) => {
        this.setState({
                password: {
                    ...this.state.password,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, this.state.password.validation),
                }
            }
        )
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.email.value, this.state.password.value);
    }

    onClickRegistrationHandle = () => {
        window.location.pathname = '/SignUp';
    }


    handleClickShowPassword = () => {
        this.setState((old) => ({showPassword: !old.showPassword}))
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render() {
        return (
            <div className="login">
                <div className={'loginBody'}>
                    <div className={'logo'}>
                        <img src="FogliOre.svg" alt=""/>
                    </div>
                    <form className={"auth_form"} onSubmit={this.handleOnSubmit}>
                        <Typography color="textPrimary" variant={"h4"}>Login</Typography>
                        <TextField id={'logEmail'} className="text_field" label="Email" type="email"
                                   fullWidth={true}
                                   variant="outlined"
                                   margin={"normal"}
                                   onChange={this.handleEmailChange}
                                   error={this.state.email.value.length > 0 && !this.state.email.valid}
                                   value={this.state.email.value}

                        />
                        <TextField
                            id={'logPass'}
                            className="text_field"
                            label="Password"
                            variant="outlined"
                            onChange={this.handlePasswordChange}
                            error={this.state.password.value.length > 0 && !this.state.password.valid}
                            value={this.state.password.value}
                            type={this.state.showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <div className={'action'}>
                            <Button
                                className="login_button"
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                onClick={this.onClickRegistrationHandle}
                                style={{marginRight: '10px'}}
                            >
                                Registrati
                            </Button>
                            <Button
                                className="login_button"
                                variant="outlined"
                                color="secondary"
                                type="submit"
                                fullWidth={true}
                            >
                                Accedi
                            </Button>
                        </div>
                        {/*<Link to={{pathname: '/SignUp'}}>*/}
                        {/*    <Typography className="registration_button" color="textPrimary"*/}
                        {/*                variant="body2">Registrati</Typography>*/}
                        {/*</Link>*/}
                    </form>
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        userLogged: state.auth.userLogged,
        error: state.auth.error,
        loading: state.auth.loading
    };
};

const mapDispatchToProp = dispatch => {
    return {
        onAuth: (email, password) => dispatch(action.auth(email, password))

    }
}

export default connect(mapStateToProps, mapDispatchToProp)(Login);