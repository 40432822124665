import React, {useEffect, useState} from 'react'
import {DataGridPro, GridRowModes, GridToolbarContainer} from "@mui/x-data-grid-pro";
import {fromJS, List, Map} from "immutable";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

function EditToolbar(props) {
    const {setThemes} = props;

    const handleClick = () => {
        const id = 1000;
        setThemes((oldRows) => oldRows.push(Map({id, name: 'new', color1: '#FFF', color2: '#FFF', color3: '#FFF'})));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

const ThemeManagement = () => {
    const dispatch = useDispatch()

    const reduxThemes = useSelector(state => state.meta.themes)

    const [themes, setThemes] = useState(List([]))

    useEffect(() => {
        setThemes(reduxThemes)
    }, [reduxThemes])


    return <div style={{wisth: '100%'}}>
        <DataGridPro
            experimentalFeatures={{newEditingApi: true}}
            style={{height: '800px'}}
            disableColumnMenu
            disableColumnReorder
            disableColumnSelector
            disableColumnResize
            hideFooter
            rows={themes.toJS()}
            columns={[{
                field: `name`,
                headerName: 'Nome',
                minWidth: 200,
                editable: true
            }, {
                field: `color1`,
                headerName: 'Colore 1',
                minWidth: 200,
                editable: true
            }, {
                field: `color2`,
                headerName: 'Colore 2',
                minWidth: 200,
                editable: true
            }, {
                field: `color3`,
                headerName: 'Colore 3',
                minWidth: 200,
                editable: true
            }, {
                field: `visible`,
                headerName: 'Visibile',
                minWidth: 200,
                editable: true,
                type: "boolean"
            }]}
            getRowId={(row) => row.id}
            disableVirtualization
            showCellRightBorder
            showColumnRightBorder
            components={{
                Toolbar: EditToolbar,
            }}
            componentsProps={{
                toolbar: {setThemes},
            }}
            processRowUpdate={(newRow) => {
                setThemes(old => old.updateIn([old.findIndex(t => t.get('id') === newRow.id)], () => fromJS(newRow)))
            }}
        />
    </div>
}

export default ThemeManagement