import React, {useState} from "react";
import {Card} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";
import './Client.css'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";


const Client = ({client, onClientSelection}) => {

    return (
        <React.Fragment>
            <Card className={'clientCard'} variant={"elevation"} elevation={9}>
                <CardActionArea className={'actionArea'} onClick={() => onClientSelection(client.get('client_code'))}>
                    <div style={{height: '100%'}}>
                        <CardHeader
                            className={'header'}
                            title={client.get('company_name')}
                        />
                        <Divider variant={'middle'}/>
                        <CardContent className={'content'}>
                            <Paper variant={"outlined"} className={'paper'} transparent>
                                <Typography align={"left"}>Codice
                                    Cliente: {client.get('client_code')}</Typography>
                                {/*<Divider variant={"fullWidth"}/>*/}
                                {/*<Typography align={"left"}>.</Typography>*/}
                            </Paper>
                        </CardContent>
                    </div>
                </CardActionArea>
            </Card>

        </React.Fragment>
    )
}

export default Client;