import React from "react";
import ReactExport from 'react-data-export';
import {List} from "immutable";
import Button from "@mui/material/Button";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FdlExport = ({columns, data, users, year, month}) => {
    // const expColumns = columns.filter(c => c.Header !== undefined).map(c => {
    //
    //     return {title: c.Header}
    // })
    const expColumns = [
        {title: 'Codice'},
        {title: 'Tecnico', width: {wch: 22}},
        {title: 'Data di emissione'},
        {title: 'Commessa'},
        {title: 'Validato'},
        {title: 'Data validazione', width: {wch: 15},height: {wch: 32}},
        {title: 'NF', },
        {title: 'Data NF', width: {wch: 15}},
        {title: 'NU'},
        {title: 'Data NU', width: {wch: 15}},
        {title: 'Descrizione', width: {wch: 60}}
    ]

    const expData = data.sortBy(d => d.get('code')).reverse().map(d => {
        const user = users.find(u => u.get('id') === d.get('technician'))
        let userName = d.get('technician')
        if (user) {
            userName =  `${user.get('surname')} ${user.get('name')}`
        }
        const validated_date = moment(d.get('validated_date')).isValid()?moment(d.get('validated_date')).format('DD-MM-YYYY'):''
        const not_signed_date = moment(d.get('not_signed_date')).isValid()?moment(d.get('not_signed_date')).format('DD-MM-YYYY'):''
        const not_used_date = moment(d.get('not_used_date')).isValid()?moment(d.get('not_used_date')).format('DD-MM-YYYY'):''
        return List([
            {value: d.get('code'), style:{font:{sz:9, bold:true}}},
            {value: userName, style:{font:{sz:9}}},
            {value: d.get('emission'), style:{font:{sz:9}}},
            {value: `${d.get('client_code')}${d.get('commission_code')}_${d.get('integration_code')}`, style:{font:{sz:9}}},
            {value: d.get('validated') ? 'X' : '', style:{alignment: {horizontal:'center'}, font:{sz:9}}},
            {value: validated_date, style:{font:{sz:9}}},
            {value: d.get('not_signed') ? 'X' : '', style:{alignment: {horizontal:'center'}, font:{sz:9}}},
            {value: not_signed_date, style:{font:{sz:9}}},
            {value: d.get('not_used') ? 'X' : '', style:{alignment: {horizontal:'center'}, font:{sz:9}}},
            {value: not_used_date, style:{font:{sz:9}}},
            {value: d.get('description'), style:{font:{sz:9}}},
        ])
    })
    const multiDataSet = [
        {
            columns: expColumns,
            data: expData.sortBy(d => d.get('emission')).toJS()
        }
    ]

    return (
        <ExcelFile filename={`FDL export ${year}${month? `-${month}`:''}`} element={<Button variant={"outlined"} fullWidth color={"secondary"}>Export data</Button>}>
            <ExcelSheet dataSet={multiDataSet} name="Fdl"/>
        </ExcelFile>
    )
}


export default FdlExport;