import * as actionType from '../actions/action'
import {List, Map} from 'immutable';
import {updateObject} from "../utility";


const initialState = {
    notifications: [],
    cli_list: List([]),
    comm_list: List([]),
    statistics: Map({}),
    lista_clienti: List([]),
    list_worked_commissions: List([]),
    lista_commesse: List([]),
    fdls: List([])


};


const userData = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };

        case actionType.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case actionType.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        case actionType.UPDATE_USERDATA_COMM:
            return updateObject(state, {
                comm_list: action.comm_list
            });
        case actionType.UPDATE_USERDATA_CLI:
            return updateObject(state, {
                cli_list: action.cli_list
            });
        case actionType.UPDATE_USERDATA_STATISTICS:
            return updateObject(state, {
                statistics: action.statistics
            });
        case actionType.UPDATE_COMM_LIST:
            return {
                ...state,
                lista_commesse: action.lista_commesse
            };
        case actionType.UPDATE_CLI_LIST:
            return {
                ...state,
                lista_clienti: action.lista_clienti
            };
        case actionType.UPDATE_WORKED_COMMISSIONS:
            return {
                ...state,
                list_worked_commissions: action.list_worked_commissions
            };
        case actionType.UPDATE_FDLS:
            return {
                ...state,
                fdls: action.data
            };
        default:
            return state

    }
};

export default userData;