import * as actionType from '../actions/action'
import {List, Map} from 'immutable';
import {updateObject} from "../utility";


const initialState = {
    day: List([]),
    file: List([]),
    eventWorktime: List([]),
    wdCopied: Map({})
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_WEEK:
            return {
                ...state,
                day: action.day
            };
        case actionType.UPDATE_MACHINES_LIST:
            return {
                ...state,
                macchine: action.macchine
            };
        case actionType.UPDATE_STATE:
            console.log(action.day);
            return {
                day: action.day
            };
        case actionType.UPDATE_FILE_STATE:
            console.log(action.data)
            return {
                ...state,
                file: action.data
            };
        case actionType.UPDATE_EVENT_WORKTIME:
            return updateObject(state, {eventWorktime: action.data});

        case actionType.UPDATE_COPIED_WD:
            return updateObject(state, {wdCopied: action.data});


        default:
            return state

    }
};

export default reducer;