import * as moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";

export function toUTCDatetime(date) {
    if (date !== null) {
        if (date.isValid()) {
            const now_utc = moment.utc(date.format('YYYY-MM-DD HH:mm:ss')).toDate().toUTCString()
            return now_utc
        } else {
            console.log(date)
        }
    }
}

export function checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.haveSomething) {
        if (value.length === 0) return true
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNc) {
        const pattern = /^[0-9]{8}_[0-9]{2}$/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isPureNc) {
        const pattern = /^[0-9]{4}$/;
        isValid = pattern.test(value) && isValid
    }

    // if (rules.isFdl) {
    //     const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    //     isValid = pattern.test(value) && isValid
    // }
    if (rules.isFdl) {
        const pattern = /[0-9]{6}-[0-9]{2}$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const get24Datetime = (time) => {
    let tempTime = new Date("1970-01-01 " + time)
    const h = tempTime.getHours()
    const m = tempTime.getMinutes()

    if (h === 0 && m === 0) {
        return new Date("1970-01-02 00:00:00")
    }
    return tempTime
}

export const totCommHour = (s1, e1) => {
    let firstTimeslot
    if (s1 === null || e1 === null) {
        firstTimeslot = 0
    } else if (new Date('1970-01-01 ' + s1).getTime() !== new Date('1970-01-01 00:00:00').getTime()) {
        firstTimeslot = get24Datetime(e1) - new Date('1970-01-01 ' + s1)
    } else if (new Date('1970-01-01 ' + e1).getTime() !== new Date('1970-01-01 00:00:00').getTime()) {
        firstTimeslot = new Date('1970-01-01 ' + e1) - new Date('1970-01-01 ' + s1)
    } else {
        firstTimeslot = 0
    }

    return firstTimeslot / 3600000
}

export const getDates = (startDate, endDate, interval = 1000 * 60 * 60 * 24) => {
    const duration = endDate - startDate;
    const steps = duration / interval;
    return Array.from({length: steps + 1}, (v, i) => moment(startDate.valueOf() + (interval * i)).format('YYYY-MM-DD'));
}

export const NumberFormatCustom = React.forwardRef((props, ref) => {
    const {inputRef, onChange, ...other} = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator
            fixedDecimalScale={true}
            isNumericString
            prefix="€ "
        />
    )
})

export const PercentFormatCustom = React.forwardRef((props, ref) => {
    const {inputRef, onChange, ...other} = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator
            fixedDecimalScale={true}
            isNumericString
            prefix="% "
        />
    )
})

export const MoneyKmTextFieldFormat = React.forwardRef((props, ref) => {
    const {inputRef, onChange, ...other} = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator
            fixedDecimalScale={true}
            isNumericString
            prefix="€/Km "
        />
    )
})

export const hourPerDayTextFieldFormat = React.forwardRef((props, ref) => {
    const {inputRef, onChange, ...other} = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator
            fixedDecimalScale={true}
            isNumericString
            prefix="h/d "
        />
    )
})