import React from "react";
import './ThemeButton.scss';
import {Tooltip} from "@mui/material";

const ThemeButton = ({name, primaryColor, secondaryColor, tertiaryColor, onClick}) => {
    return (
        <Tooltip title={name}>
            <div className={'themButton'} onClick={() => onClick(primaryColor, secondaryColor, tertiaryColor)}>
                <div className={'primaryColorTheme'} style={{backgroundColor: primaryColor}}/>
                <div className={'secondaryColorTheme'} style={{backgroundColor: secondaryColor}}/>
                <div className={'tertiaryColorTheme'} style={{backgroundColor: tertiaryColor}}/>
            </div>
        </Tooltip>
    )
}

export default ThemeButton;