import React, {useMemo} from "react";
import './TableCell.scss'
import CleanData from "../../Library/Icons/CleanData";
import UnsentData from "../../Library/Icons/UnsentData";
import PartiallySent from "../../Library/Icons/PartiallySent";
import Sent from "../../Library/Icons/Sent";
import NoData from "../../Library/Icons/NoData";
import {Tooltip} from "@mui/material";

const percentColors = [
    {pct: 0.0, color: {r: 0xf4, g: 0x43, b: 0x36}},
    {pct: 0.5, color: {r: 0xff, g: 0x98, b: 0x0}},
    {pct: 1.0, color: {r: 0x4c, g: 0xaf, b: 0x50}}];

const getColorForPercentage = (pct) => {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
};

const TableCell = ({cell: {colDef, field, id, value}}) => {
    // We need to keep and update the state of the cell normally
    // const [actValue, setActValue] = React.useState(value)

    // If the initialValue is changed external, sync it up with our state
    // React.useEffect(() => {
    //     setActValue(value)
    // }, [value])

    return useMemo(() => {
        let icon
        let tooltip
        let color
        if ((!colDef['weekend'] && (!value?.total_sent || !value?.compiled)) || (value?.compiled && !value?.total_sent)) {
            color = '#f44336'
        } else if (value?.compiled && value?.total_sent){
            color= 'rgb(76, 175, 80)'
        }
        if (value && !value['compiled']) {
            icon = <CleanData className={'icon'} style={{fill: color}}/>
            // icon = <div/>
            tooltip = 'Non compilato'
        } else if (value && value['compiled'] && !value['partial_sent']) {
            icon = <UnsentData className={'icon'} style={{fill: color}}/>
            tooltip = 'Compilato - Non inviato'
        } else if (value && value['compiled'] && value['partial_sent'] && !value['total_sent']) {
            icon = <PartiallySent className={'icon'} style={{fill: color}}/>
            tooltip = 'Inviato in parte'
        } else if (value && value['compiled'] && value['total_sent']) {
            icon = <Sent className={'icon'} style={{fill: color}}/>
            tooltip = 'Inviato'
        } else {
            icon = <NoData className={'icon'} style={{fill: color}}/>
            // icon = <div/>
            tooltip = 'Nessun dato'
        }

        return (
            <Tooltip
                title={<span><p>{tooltip}</p><p>Ore segnate: {value?.tot_hours}</p></span>}
                placement={"bottom"}
                disableInteractive
            >
                <div className={`tableCell ${colDef['weekend'] && 'weekendCell'}`}>
                    {icon}
                    {/*${Math.min(value['tot_hours']/8*100, 100) }%*/}
                    <div
                        className={'progressBar'}
                        style={{
                            width: `${Math.min(value?.tot_hours / 8 * 100, 100)}%`,
                            backgroundColor: getColorForPercentage(Math.min(value && value['tot_hours'] / 8 * 100, 100)/100)
                        }}
                    />
                </div>
            </Tooltip>
        )
    }, [])

}

export default TableCell