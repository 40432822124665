import React, {createRef} from "react";
import './UsrCommData.css'
import {ResponsivePie} from "@nivo/pie";
import {ResponsiveSunburst} from "@nivo/sunburst";
import {NivoTheme} from "../../Temi/NivoTheme";
import StatisticBox from "../StatisticBox/StatisticBox";
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import TimelineIcon from "@mui/icons-material/Timeline";
import PublicIcon from "@mui/icons-material/Public";
import TimelapseIcon from "@mui/icons-material/Timelapse";

class UsrCommData extends React.Component {

    el = document.querySelector('.usrCommData');

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return true
    // }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const c = document.querySelector('.usrCommData');

        c.classList.remove('fade-in');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const c = document.querySelector('.usrCommData');

        c.classList.add('fade-in');
    }

    componentDidMount() {
        const c = document.querySelector('.usrCommData');
        c.classList.add('fade-in');
    }

    render() {

        const mainRef = createRef()


        let dataGeneral = null
        let dataUff = null
        let dataFs = null
        let dataTrs = null
        let data = null
        let graph_data = null
        let graph_data_1 = null
        let colors = null


        const getColor = ResponsivePie => {
            return colors[ResponsivePie.id]
        }
        const getColor_1 = ResponsivePie => {
            return colors[ResponsivePie.id]
        }


        let oreTotExtra = this.props.comm.getIn(['hour', 'total', 'extra']) + this.props.comm.getIn(['hour', 'total', 'weekend'])

        data = [
            {name: 'Totale ore', value: this.props.comm.getIn(['hour', 'total', 'total'])},
            {
                name: 'Ore straordinarie',
                value: oreTotExtra
            },
        ]

        dataGeneral = [
            {
                name: 'Ordinarie',
                value: (Math.round(this.props.comm.getIn(['hour', 'total', 'ordinary']) * 10 / 8) / 10).toString() + ' g'
            },
            {name: 'Str. totali', value: (Math.round(oreTotExtra * 10) / 10).toString() + ' h'},
            {
                name: 'Str. settimanali',
                value: (Math.round(this.props.comm.getIn(['hour', 'total', 'extra']) * 10) / 10).toString() + ' h'
            },
            {
                name: 'Str. week end',
                value: (Math.round(this.props.comm.getIn(['hour', 'total', 'weekend']) * 10) / 10).toString() + ' h'
            },
            {
                name: 'Viaggio',
                value: (Math.round(this.props.comm.getIn(['hour', 'travel', 'total']) * 10) / 10).toString() + ' h'
            },
        ]

        dataUff = [
            {
                name: 'totale',
                value: (Math.round(this.props.comm.getIn(['hour', 'office', 'total']) * 10) / 10).toString() + ' h'
            },
            {
                name: 'Ordinarie',
                value: (Math.round(this.props.comm.getIn(['hour', 'office', 'ordinary']) * 10 / 8) / 10).toString() + ' g'
            },
            {
                name: 'Straordinarie',
                value: (Math.round((this.props.comm.getIn(['hour', 'office', 'extra']) + this.props.comm.getIn(['hour', 'office', 'weekend'])) * 10) / 10).toString() + ' h'
            }
        ]

        dataFs = [
            {
                name: 'totale',
                value: (Math.round(this.props.comm.getIn(['hour', 'fs', 'total']) * 10) / 10).toString() + ' h'
            },
            {
                name: 'Ordinarie',
                value: (Math.round(this.props.comm.getIn(['hour', 'fs', 'ordinary']) * 10 / 8) / 10).toString() + ' g'
            },
            {
                name: 'Straordinarie',
                value: (Math.round((this.props.comm.getIn(['hour', 'fs', 'extra']) + this.props.comm.getIn(['hour', 'fs', 'weekend'])) * 10) / 10).toString() + ' h'
            }
        ]

        dataTrs = [
            {
                name: 'totale',
                value: (Math.round(this.props.comm.getIn(['hour', 'trs', 'total']) * 10) / 10).toString() + ' h'
            },
            {
                name: 'Ordinarie',
                value: (Math.round(this.props.comm.getIn(['hour', 'trs', 'ordinary']) * 10 / 8) / 10).toString() + ' g'
            },
            {
                name: 'Straordinarie',
                value: (Math.round((this.props.comm.getIn(['hour', 'trs', 'extra']) + this.props.comm.getIn(['hour', 'trs', 'weekend'])) * 10) / 10).toString() + ' h'
            }
        ]


        graph_data = [
            {
                "id": "Ore ufficio",
                "label": "Ore ufficio",
                "value": this.props.comm.getIn(["hour", "office", 'total']),
                "color": '#1976D2'

            },
            {
                "id": "Ore fuori sede",
                "label": "Ore fuori sede",
                "value": this.props.comm.getIn(["hour", "fs", 'total']),
                "color": '#0288D1'
            },
            {
                "id": "Ore trasferta",
                "label": "Ore trasferta",
                "value": this.props.comm.getIn(["hour", "trs", 'total']),
                "color": '#0097A7'
            },
            {
                "id": "Ore viaggio",
                "label": "Ore viaggio",
                "value": this.props.comm.getIn(["hour", "travel", 'total']),
                "color": '#00796B'

            },
        ]

        graph_data_1 = {
            "name": "Ore di lavoro",
            "color": "#ffffff",
            "children": [
                {
                    "name": "Ore ufficio",
                    "color": "#00796B",
                    "children": [
                        {
                            "name": "Ore settimanali",
                            "color": "#119a00",
                            "value": this.props.comm.getIn(["hour", "office", 'ordinary']) * 1000
                        },
                        {
                            "name": "Ore straordinarie settimanali",
                            "color": "#ffff1a",
                            "value": this.props.comm.getIn(["hour", "office", 'extra']) * 1000
                        },
                        {
                            "name": "Ore straordinarie WeekEnd",
                            "color": "#9a4500",
                            "value": this.props.comm.getIn(["hour", "office", 'weekend']) * 1000
                        },
                    ]
                },
                {
                    "name": "Ore fuori sede",
                    "color": "#0097A7",
                    "children": [
                        {
                            "name": "Ore settimanali",
                            "color": "#119a00",
                            "value": this.props.comm.getIn(["hour", "fs", 'ordinary']) * 1000
                        },
                        {
                            "name": "Ore straordinarie settimanali",
                            "color": "#ffff1a",
                            "value": this.props.comm.getIn(["hour", "fs", 'extra']) * 1000
                        },
                        {
                            "name": "Ore straordinarie WeekEnd",
                            "color": "#9a4500",
                            "value": this.props.comm.getIn(["hour", "fs", 'weekend']) * 1000
                        },
                    ]
                },
                {
                    "name": "Ore trasferta",
                    "color": "#0288D1",
                    "children": [
                        {
                            "name": "Ore settimanali",
                            "color": "#119a00",
                            "value": this.props.comm.getIn(["hour", "trs", 'ordinary']) * 1000
                        },
                        {
                            "name": "Ore straordinarie settimanali",
                            "color": "#ffff1a",
                            "value": this.props.comm.getIn(["hour", "trs", 'extra']) * 1000
                        },
                        {
                            "name": "Ore straordinarie WeekEnd",
                            "color": "#9a4500",
                            "value": this.props.comm.getIn(["hour", "trs", 'weekend']) * 1000
                        },
                    ]
                }
            ]
        }

        colors = {
            'Ore viaggio': graph_data[3].color,
            'Ore trasferta': graph_data[2].color,
            'Ore fuori sede': graph_data[1].color,
            'Ore ufficio': graph_data[0].color
        }

        return (
            <div className={'usrCommData'} key={this.props.comm.get('nc')}>
                <div className={'rawData'}>
                    <StatisticBox
                        key={this.props.comm.get('nc') + '1'}
                        color={'#d32f2f'}
                        title={'Ore totali'}
                        icon={TimelineIcon}
                        value={(Math.round(this.props.comm.getIn(['hour', 'total', 'total']) * 10) / 10).toString() + ' h'}
                        extra={dataGeneral}
                        extraHeight={'260px'}
                    />
                    <StatisticBox
                        key={this.props.comm.get('nc') + '2'}
                        color={'#8e24aa'}
                        title={'Ore totali ufficio'}
                        icon={AddToPhotosRoundedIcon}
                        value={(Math.round(this.props.comm.getIn(['hour', 'office', 'total']) * 10) / 10).toString() + ' h'}
                        extra={dataUff}
                        extraHeight={'200px'}
                    />
                    <StatisticBox
                        key={this.props.comm.get('nc') + '3'}
                        color={'#ef6c00'}
                        title={'Ore totali fuori sede'}
                        icon={PublicIcon}
                        value={(Math.round(this.props.comm.getIn(['hour', 'fs', 'total']) * 10) / 10).toString() + ' h'}
                        extra={dataFs}
                        extraHeight={'200px'}
                    />
                    <StatisticBox
                        key={this.props.comm.get('nc') + '4'}
                        color={'#00796b'}
                        title={'Ore totali trasferta'}
                        icon={TimelapseIcon}
                        value={(Math.round(this.props.comm.getIn(['hour', 'trs', 'total']) * 10) / 10).toString() + ' h'}
                        extra={dataTrs}
                        extraHeight={'200px'}
                    />
                </div>
                <div className="graph">
                    <div className="graphTypeHour">
                        <ResponsivePie
                            theme={NivoTheme}
                            data={graph_data}
                            margin={{top: 80, right: 120, bottom: 80, left: 120}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={getColor}
                            borderWidth={1}
                            borderColor={{from: 'color', modifiers: [['darker', 0]]}}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#ffffff"
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{from: 'color'}}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor="#ffffff"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#FFF'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div className="graphDetailHour">
                        <ResponsiveSunburst
                            theme={NivoTheme}
                            data={graph_data_1}
                            margin={{top: 80, right: 120, bottom: 80, left: 120}}
                            identity="name"
                            value="value"
                            colors={getColor}
                            // childColor={'inherit'}
                            animate={true}
                            motionStiffness={115}
                            motionDamping={24}
                            isInteractive={true}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={5}
                            // colors={{scheme: 'nivo'}}
                            borderWidth={1}
                            borderColor="#282C34"

                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default UsrCommData;