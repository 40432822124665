import * as actionType from './action';
import {enqueueSnackbar} from './action';
import apiV2 from "../../apiV2";
import {fromJS} from "immutable";

export const authSuccess = (user) => {
    return {
        type: actionType.AUTH_SUCCESS,
        user: user
    }
};

export const logout = () => {
    console.log("logout")
    return {
        type: actionType.AUTH_LOGOUT
    }
};

export const updateUser = (user) => {
    return {
        type: actionType.UPDATE_USER,
        user: user

    }
};

export const auth = (email, password) => {
    return dispatch => {
        apiV2.post('login', {email: email, password: password})
            .then(response => {
                const data = fromJS(response?.data.data)
                localStorage.setItem('token', data.get('token'));
                localStorage.setItem('refresh_token', data.get('refresh_token'));
                dispatch(authSuccess(data));
            })
            .catch(error => {
                console.log(error)
                let message = 'Errore interno,\nRiprovare!'
                if (error.response) {
                    if (error.response.status === 400) message = 'Campi mancanti'
                    if (error.response.status === 401) message = 'Email o password non validi'
                    if (error.response.status === 500) message = 'Errore interno'
                } else {
                    message = error.message
                }
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 2000,
                    },
                }))
            })
    }
};

export const authWithToken = (token) => {
    return (dispatch, getState) => {
        if (!localStorage.getItem('token')) return dispatch({type: actionType.AUTO_LOGIN_FAIL})

        apiV2.post('login_with_token')
            .then(response => {
                let data = fromJS(response.data.data)
                dispatch({type: actionType.AUTO_LOGIN_SUCCESS, data: data});
            })
            .catch(error => {
                console.log(error)
                let message = 'Errore interno,\nRiprovare!'
                if (error.response) {
                    if (error.response.status === 401) message = 'Token non valido'
                    if (error.response.status === 422) return dispatch({type: actionType.AUTO_LOGIN_FAIL})
                    if (error.response.status === 500) message = 'Errore interno'
                } else {
                    message = error.message
                }
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 2000,
                    },
                }))
                dispatch({type: actionType.AUTO_LOGIN_FAIL});
            })
    }
};

export const refreshToken = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch(process.env.REACT_APP_BASE_URL + '/refresh', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Device': 'device',
                    'Authorization': 'Bearer ' + localStorage.getItem("refresh_token")
                },
                redirect: 'follow',
                referrer: 'no-referrer',
            })
                .then(res => res.json())
                .then(res => {
                    const token = res.data.token
                    localStorage.setItem('token', token);
                    dispatch(updateUser(getState().auth.user.update('token', () => token)))
                    return resolve(token)
                })
                .catch(e => reject(e))
        })
    }
};

export const logoutUser = () => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        dispatch(logout())
    }
}

export const SignUp = (name, surname, email, password) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            apiV2.post('user', {name: name, surname: surname, email: email, password: password})
                .then(data => {
                    console.log(data);
                    resolve()
                })
                .catch(error => {
                    console.log(error);
                    let message = 'Errore interno,\nRiprovare!'
                    if (error.response) {
                        if (error.response.status === 400) message = 'Campi mancanti'
                        if (error.response.status === 409) message = 'Email gia` registrata'
                    } else {
                        message = error.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject()
                })
        })
    }
};


export const changePassword = (oldPassword, newPassword1, newPassword2) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            apiV2.post('changePassword', {old_password: oldPassword, new_password_1: newPassword1, new_password_2: newPassword2})
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    console.log(error);
                    let message = 'Errore interno,\nRiprovare!'
                    if (error.response) {
                        if (error.response.status === 403) message = 'Vecchia password non valida'
                        if (error.response.status === 409) message = 'Password 1 e password 2 non uguali'
                    } else {
                        message = error.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject()
                })
        })
    }
}