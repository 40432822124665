import * as actionType from "../actions/action";
import {updateObject} from "../utility";
import {Map} from 'immutable';

const initialState = {
    user: Map({}),
    autoLogin: 0,
    userLogged: false,
    loading: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.AUTH_SUCCESS:
            return updateObject(state, {
                user: action.user,
                userLogged: true,
            });
        case actionType.AUTH_LOGOUT:
            return updateObject(state, {
                userLogged: false,
                user: Map({}),
            });
        case actionType.UPDATE_USER:
            return updateObject(state, {
                user: action.user,
            });
        case actionType.AUTO_LOGIN_FAIL:
            return updateObject(state, {autoLogin: -1});
        case actionType.AUTO_LOGIN_SUCCESS:
            return updateObject(state, {
                autoLogin: 1,
                user: action.data,
                userLogged: true,
            });
        default:
            return state
    }
};

export default reducer;