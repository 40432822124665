export const DbKey = 'id';
export const JKey = 'j_key';
export const Sync = 'sync';
export const Day = 'day';

export const Nc = 'nc';
export const Start = 'start';
export const End = 'end';
export const KmAndata = 'km';
export const KmRitorno = 'km_ritorno';
export const Targa = 'plates';
export const Autostrada = 'highway';
