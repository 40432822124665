import React, {useState} from "react";
import './UsrData.css'
import StatisticBox from "../StatisticBox/StatisticBox";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddToPhotosRoundedIcon from "@mui/icons-material/AddToPhotosRounded";
import PublicIcon from "@mui/icons-material/Public";
import {Map} from "immutable";
import {ToggleButtonGroup, ToggleButton} from "@mui/material";
import YearIcon from "../Icons/YearIcon";
import MonthIcon from "../Icons/MonthIcon";
import {ResponsiveBar} from "@nivo/bar";
import {NivoTheme} from "../../Temi/NivoTheme";


const UsrData = (props) => {
    const [popupState, setPopupState] = useState(false)
    const [yearMonth, setYearMonth] = useState(1)
    let dataGrTotOre = null

    const data = [
        {name: 'Media giornaliere', value: Math.round(props.statistics.get('avg_tot_ore_work') * 10) / 10},
        {name: 'Ore totali', value: Math.round(props.statistics.get('tot_ore_work') * 10) / 10},
    ]

    const dataTypeHours = [
        {name: 'Ordinarie', value: Math.round(props.statistics.get('tot_ore_ord') * 10) / 10},
        {name: 'Straordinarie', value: Math.round(props.statistics.get('tot_ore_extra') * 10) / 10},
        {name: 'Viaggio', value: Math.round(props.statistics.get('tot_ore_trv') * 10) / 10},
    ]

    const dataAverages = [
        {name: 'Settimanale', value: Math.round(props.statistics.get('avg_ore_week_work') * 10) / 10},
        {name: 'Weekend', value: Math.round(props.statistics.get('avg_ore_weekend_work') * 10) / 10},
    ]

    const dataDays = [
        {name: 'Settimanali', value: Math.round(props.statistics.get('week_day') * 10) / 10},
        {name: 'Weekend', value: Math.round(props.statistics.get('weekend_day') * 10) / 10},
    ]

    if (yearMonth === 1) {
        if (props.statistics.get('ore_ord_ann')) {
            if (props.statistics.get('ore_ord_ann').size > 0) {
                dataGrTotOre = props.statistics.get('ore_ord_ann').map((e, index) => {
                    if (index > props.statistics.get('ore_ord_ann').size - 20) {
                        return Map({
                            'id': e.keys().next().value,
                            'oreOrd': Math.round(e.get(e.keys().next().value)),
                            // "oreOrdColor": "#E8A838",
                            'oreExtra': Math.round(props.statistics.getIn(['ore_extra_ann', props.statistics.get('ore_extra_ann').findIndex((item) => item.keys().next().value === e.keys().next().value), e.keys().next().value])),
                            // "oreExtraColor": "#61CDBB",
                            'oreTrv': Math.round(props.statistics.getIn(['ore_trv_ann', props.statistics.get('ore_trv_ann').findIndex((item) => item.keys().next().value === e.keys().next().value), e.keys().next().value])),
                            // "oreTrvColor": "#97E3D5",
                        })
                    }
                }).filter((value) => value !== undefined)
            }
        }
    } else if (yearMonth === 2) {
        if (props.statistics.get('ore_ord_mens')) {
            if (props.statistics.get('ore_ord_mens').size > 0) {
                dataGrTotOre = props.statistics.get('ore_ord_mens').map((e, index) => {
                    if (index > props.statistics.get('ore_ord_mens').size - 14) {
                        return Map({
                            'id': e.keys().next().value,
                            'oreOrd': Math.round(e.get(e.keys().next().value)),
                            // "oreOrdColor": "#E8A838",
                            'oreExtra': Math.round(props.statistics.getIn(['ore_extra_mens', props.statistics.get('ore_extra_mens').findIndex((item) => item.keys().next().value === e.keys().next().value), e.keys().next().value])),
                            // "oreExtraColor": "#61CDBB",
                            'oreTrv': Math.round(props.statistics.getIn(['ore_trv_mens', props.statistics.get('ore_trv_mens').findIndex((item) => item.keys().next().value === e.keys().next().value), e.keys().next().value])),
                            // "oreTrvColor": "#97E3D5",
                        })
                    }
                }).filter((value) => value !== undefined)
            }
        }
    }

    const handleYearMonth = (event, value) => {
        if (value) {
            setYearMonth(value)
        }
    }

    return (
        <div className={'usrData'}>
            <div className={'rawData'}>
                <StatisticBox
                    key={'UsrData1'}
                    color={'#d32f2f'}
                    title={'Ore totali'}
                    icon={TimelineIcon}
                    value={(Math.round(props.statistics.get('tot_ore_work'))).toString() + ' h'}
                    extra={dataTypeHours}
                    extraHeight={'200px'}
                />
                <StatisticBox
                    key={'UsrData2'}
                    color={'#8e24aa'}
                    title={'Media ore lavorate'}
                    icon={AddToPhotosRoundedIcon}
                    value={(Math.round(props.statistics.get('avg_tot_ore_work') * 10) / 10).toString() + ' h'}
                    extra={dataAverages}
                    extraHeight={'170px'}
                />
                <StatisticBox
                    key={'UsrData3'}
                    color={'#ef6c00'}
                    title={'Giorni lavorati'}
                    icon={PublicIcon}
                    value={(Math.round(props.statistics.get('day_tot') * 10) / 10).toString() + ' g'}
                    extra={dataDays}
                    extraHeight={'170px'}
                />
            </div>
            <div className={'graph'}>
                {/*<Paper variant={"outlined"} elevation={7}>*/}
                {/*    <ToggleButtonGroup*/}
                {/*        value={yearMonth}*/}
                {/*        exclusive*/}
                {/*        onChange={handleYearMonth}*/}
                {/*        aria-label="text alignment"*/}
                {/*        className={'yearMonth'}*/}
                {/*    >*/}
                {/*        <ToggleButton value={1} aria-label="left aligned">*/}
                {/*            <YearIcon/>*/}
                {/*        </ToggleButton>*/}
                {/*        <ToggleButton value={2} aria-label="centered">*/}
                {/*            <MonthIcon/>*/}
                {/*        </ToggleButton>*/}

                {/*    </ToggleButtonGroup>*/}
                    <div className={'divTotOre'}>
                        <ResponsiveBar
                            theme={NivoTheme}
                            data={dataGrTotOre? dataGrTotOre.toJS(): []}
                            keys={['oreOrd', 'oreExtra', 'oreTrv']}
                            indexBy="id"
                            margin={{top: 20, right: 20, bottom: 30, left: 50}}
                            padding={0.3}
                            colors={[
                                "#049DBF",
                                "#0468BF",
                                '#3B592D'
                            ]}
                            borderColor={'#546214'}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,

                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            // labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                            labelTextColor={'#FFFFFF'}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>
                {/*</Paper>*/}
            </div>
        </div>
    )

}

export default UsrData;