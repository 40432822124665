import React, {useState} from "react";
import './PopUpNewFdl.scss'
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Input,
    InputLabel,
    Paper,
    TextField
} from "@mui/material";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {checkValidity} from "../Utils/Utils";
import Typography from "@mui/material/Typography";
import {useTable} from "react-table";
import {List} from "immutable";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import Draggable from 'react-draggable';

function CommTextMask(props) {
    const {inputRef, ...other} = props;
    return (
        <MaskedInput
            {...other}
            inputRef={inputRef}
            // innerRef={inputRef}
            // ref={(ref) => {
            //     inputRef(ref ? ref.inputElement : null);
            // }}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '_', /\d/, /\d/]}
            placeholderChar={'\u002a'}
            showMask
            guide={false}
        />
    );
}

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{x: (window.innerWidth - 1300) / 2, y: -window.innerHeight / 1.5}}
        >
            <Paper {...props} style={{margin: 0, maxHeight: '100%'}}/>
        </Draggable>
    );
}

const PopUpNewFdl = ({state, users, addFDL, closePopUp, data, commissionCodes}) => {
    const [clientCode, setClientCode] = useState('');
    const [commissionCode, setCommissionCode] = useState('');
    const [integrationCode, setIntegrationCode] = useState('');
    const [fdlCode, setFdlCode] = useState('');
    const [emission, setEmission] = useState(moment());
    const [fdlNumber, setFdlNumber] = useState(1);
    const [technician, setTechnician] = useState([]);
    const [description, setDescription] = useState('');
    const [weekNumber, setWeekNumber] = useState(1);
    const [error, setError] = useState(false);
    const [clean, setClean] = useState(false)


    const FdlMask = React.forwardRef((props, ref) => {
        const {inputRef, ...other} = props;
        return (
            <MaskedInput
                {...other}
                getInputRef={ref}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                placeholderChar={'\u002a'}
                showMask
                guide={false}
            />
        );
    })

    const columns = React.useMemo(
        () => [
            {
                Header: 'Codice FDL',
                accessor: 'code',
                maxWidth: 110,
                minWidth: 50,
                // width: 100,
            },
            {
                Header: 'Commessa',
                accessor: d => `${d.client_code}${d.commission_code}_${d.integration_code}`,
                // maxWidth: 90,
                maxWidth: 120,
                minWidth: 60,
                // width: 105,
            },
            {
                Header: 'Tecnico',
                accessor: d => {
                    const user = users.find(u => u.get('id') === d.technician)
                    if (user) {
                        return `${user.get('name')} ${user.get('surname')}`
                    }
                },
                width: 190,
                maxWidth: 200,
                minWidth: 50,
            },
            {
                Header: 'Descrizione',
                accessor: 'description',
                width: 340,
                maxWidth: 400,
                minWidth: 150,
            },
        ],
        [users]
    )

    React.useMemo(() => {
        if (state && data) {
            setClientCode(data.client_code || '')
            setCommissionCode(data.commission_code || '')
            setIntegrationCode(data.integration_code || '')
            setFdlCode(data.code || '')
            setEmission(moment())
            setFdlNumber(1)
            setTechnician(data.technician ? [data.technician?.toString()] : [])
            setDescription(data.description || '')
            setWeekNumber(1)
            setError(false)
        }
        if (!state && clean) {
            setClientCode('')
            setCommissionCode('')
            setIntegrationCode('')
            setFdlCode('')
            setEmission(moment())
            setFdlNumber(1)
            setTechnician([])
            setDescription('')
            setWeekNumber(1)
            setError(false)
        }
        setClean(false)
    }, [state])

    let sendData = List([])
    let lastProgressive = parseInt(fdlCode.substring(fdlCode.length - 2))
    for (let i = 0; i < fdlNumber; i++) {
        sendData = sendData.merge(technician.map(t => {
            const _fdlCode = fdlCode.substring(0, fdlCode.length - 2) + (lastProgressive).toString().padStart(2, '0')
            lastProgressive += 1
            return {
                code: _fdlCode,
                client_code: clientCode,
                commission_code: commissionCode,
                emission: emission?.format('DD/MM/YYYY'),
                integration_code: integrationCode,
                technician: parseInt(t),
                description: description.replace('{x}', weekNumber + i)

            }
        }))
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: sendData.toJS(),
    })

    const checkFdlCode = checkValidity(fdlCode, {required: true, isFdl: true})
    const checkCommission =
        checkValidity(clientCode, {required: true, minLength: 4, maxLength: 4}) &&
        checkValidity(commissionCode, {required: true, minLength: 4, maxLength: 4}) &&
        checkValidity(integrationCode, {required: true, minLength: 2, maxLength: 2})
    const checkTechnician = technician.length > 0
    const checkDescription = description.length > 0


    return (
        <Dialog
            className={'popUpNewFdl'}
            open={state}
            maxWidth={"xl"}
            // onClose={() => closePopUp(false)}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop // Disable the backdrop color/image
            disableEnforceFocus // Let the user focus on elements outside the dialog
            style={{
                position: 'initial',
                height: 'fit-content',  // Ensures that the dialog is
                width: 'fit-content',   // exactly the same size as its contents
            }}
            disableAutoFocus
            // disableBackdropClick // Remove the backdrop click (just to be sure)

        >
            <DialogTitle className={'title'} disableTypography id="draggable-dialog-title" style={{cursor: 'move'}}>
                <Typography style={{width: 'max-content'}}>Aggiunta nuovo FDL</Typography>
                {/*<ToggleButtonGroup*/}
                {/*    value={popUpMode}*/}
                {/*    exclusive*/}
                {/*    onChange={(e, n) => {*/}
                {/*        if (n !== null) setPopUpMode(n)*/}
                {/*        if (technician.length > 1) setTechnician(technician.slice(0, 1))*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ToggleButton value={1}>*/}
                {/*        <PersonRoundedIcon/>*/}
                {/*    </ToggleButton>*/}
                {/*    <ToggleButton value={2}>*/}
                {/*        <PeopleAltRoundedIcon/>*/}
                {/*    </ToggleButton>*/}
                {/*</ToggleButtonGroup>*/}
            </DialogTitle>
            <Divider/>
            <DialogContent className={'content'}>
                <div className={'fields'}>
                    <div className={'row'}>

                        {/*<TextField*/}
                        {/*    variant={'standard'}*/}
                        {/*    value={fdlCode}*/}
                        {/*    // fullWidth*/}
                        {/*    onChange={e => setFdlCode(e.target.value)}*/}
                        {/*    label={'Codice FDL'}*/}
                        {/*    className={error && (!checkFdlCode || fdlCode.length === 0) ? 'textField error' : 'textField'}*/}
                        {/*    onAnimationEnd={() => setError(false)}*/}
                        {/*    color={"secondary"}*/}
                        {/*    error={!checkFdlCode && fdlCode.length > 0}*/}
                        {/*    style={{marginRight: '15px', width: '195px'}}*/}
                        {/*    required*/}
                        {/*    type={'text'}*/}
                        {/*/>*/}
                        <FormControl
                            id={'FdlCode'}
                            className={error && (!checkFdlCode || fdlCode.length === 0) ? 'textField error' : 'textField'}
                            style={{marginRight: '15px', width: '195px'}}
                            onAnimationEnd={() => setError(false)}>
                            <InputLabel required htmlFor="FdlCodeInput" color={'secondary'}>Codice FDL</InputLabel>
                            <Input
                                autoComplete={'off'}
                                required
                                value={fdlCode}
                                onChange={e => {
                                    setFdlCode(e.target.value)
                                }}
                                onBlur={e => {
                                    const code = e.target.value.split('-')
                                    if (code.length === 2 && code[1].length < 2) {
                                        setFdlCode(`${code[0]}-0${code[1]}`)
                                    }
                                }}
                                id="FdlCodeInput"

                                InputProps={{
                                    inputComponent: FdlMask,
                                }}
                                // inputComponent={FdlTextMask}
                                color={'secondary'}
                                error={!checkFdlCode && fdlCode.length > 0}
                            />
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                renderInput={(props) => <TextField color={"secondary"} size={"small"}
                                                                   margin={"dense"} {...props} />}
                                variant="inline"
                                inputVariant="standard"
                                className={'textField'}
                                ampm={false}
                                label={'Data di emissione'}
                                value={emission}
                                onChange={(date) => setEmission(moment(date))}
                                onError={console.log}
                                // disablePast
                                inputFormat="dd/MM/yyyy"
                                style={{marginRight: '0', width: '200px'}}

                                // error={errors['startTime']}
                                // helperText={errors['startTime']}
                            />
                        </LocalizationProvider>
                    </div>
                    {/*<FormControl*/}
                    {/*    className={error && (!checkCommission ||*/}
                    {/*        clientCode.length === 0 ||*/}
                    {/*        commissionCode.length === 0 ||*/}
                    {/*        integrationCode.length === 0) ? 'textField error' : 'textField'}*/}
                    {/*    onAnimationEnd={() => setError(false)}>*/}
                    {/*    <InputLabel required htmlFor="formatted-text-mask-input" color={'secondary'}>Codice*/}
                    {/*        commessa</InputLabel>*/}
                    {/*    <Input*/}
                    {/*        autoComplete={'off'}*/}
                    {/*        required*/}
                    {/*        value={`${clientCode}${commissionCode}${integrationCode}`}*/}
                    {/*        onChange={e => {*/}
                    {/*            const value = e.target.value*/}
                    {/*            setClientCode(value.substring(0, 4));*/}
                    {/*            setCommissionCode(value.substring(5, 9));*/}
                    {/*            setIntegrationCode(value.substring(10, 13));*/}
                    {/*        }}*/}
                    {/*        id="formatted-text-mask-input"*/}
                    {/*        inputComponent={TextMaskCustom}*/}
                    {/*        color={'secondary'}*/}

                    {/*        error={!checkCommission && (clientCode.length > 0 || commissionCode.length > 0 || integrationCode.length > 0)}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                    <Autocomplete
                        id={'commissionTextField'}
                        limitTags={2}
                        className={'textFilter'}
                        // style={{margin: '0 0 7px 0'}}
                        freeSolo
                        openOnFocus
                        autoHighlight
                        autoComplete
                        // multiple={true}
                        options={commissionCodes?.toJS()}
                        value={`${clientCode}${commissionCode}${clientCode && commissionCode ? '_' : ''}${integrationCode}`}
                        groupBy={(option) => option.substring(0, 4)}
                        onChange={(e, v, r) => {
                            setClientCode(v ? v.substring(0, 4) : '');
                            setCommissionCode(v ? v.substring(4, 8) : '');
                            setIntegrationCode(v ? v.substring(9, 12) : '');
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                color={"secondary"}
                                // className="textfield"
                                // style={{margin: '0'}}
                                // id={"Nc-" + this.props.workdata.get(cMap.DbKey)}
                                variant="standard"
                                margin="dense" size="small"
                                // error={!this.state.nc.valid && this.state.nc.value ? this.state.nc.value.length > 0 : false}
                                label={"Codice commessa"}
                                type="text"
                                // onChange={e => {
                                //     const v = e.target.value
                                //     setClientCode(v ? v.substring(0, 4) : '');
                                //     setCommissionCode(v ? v.substring(4, 8) : '');
                                //     setIntegrationCode(v ? v.substring(9, 12) : '');
                                // }}
                                // inputComponent={TextMaskCustom}
                                // InputProps={{
                                //     inputComponent: TextMaskCustom,
                                //     // inputProps: {component: SomeThirdPartyComponent},
                                // }}
                                // onBlur={(event) => (this.state.nc.valid || this.state.nc.value.length === 0) ? this.props.onPropsCommChangeHandler(this.state.nc.value, this.props.idx_day, this.props.idx_comm, cMap.Nc, this.props.workdata.get(cMap.DbKey)) : null}
                            />
                        )}
                    />
                    {/*<TextField*/}
                    {/*    id="standard-select-currency"*/}
                    {/*    select*/}
                    {/*    label="Tecnico"*/}
                    {/*    value={technician}*/}
                    {/*    onChange={e => setTechnician(e.target.value)}*/}
                    {/*    className={'textField'}*/}
                    {/*>*/}
                    {/*    {users.sortBy(u => u.get('surname') + u.get('name')).map((option) => (*/}
                    {/*        <MenuItem key={option.get('id')} value={option.get('id')}>*/}
                    {/*            {`${option.get('surname')} ${option.get('name')}`}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</TextField>*/}
                    <Autocomplete
                        required
                        multiple={true}
                        variant={'standard'}
                        id="combo-box-demo2"
                        className={error && (checkTechnician) ? 'textField error' : 'textField'}
                        onAnimationEnd={() => setError(false)}
                        style={{maxWidth: '410px'}}
                        fullWidth={true}
                        value={technician}
                        // options={users.sortBy(u => u.get('surname') + u.get('name')).toJS()}
                        options={users?.sortBy(u => u.get('surname') + u.get('name')).map(u => u.get('id')?.toString()).toJS()}
                        // getOptionLabel={(option) => `${option.surname} ${option.name}`}
                        onChange={(e, v, r) => setTechnician(v)}
                        getOptionLabel={(option) => {
                            const user = users.find(u => u.get('id')?.toString() === option)
                            return `${user?.get('surname')} ${user?.get('name')}`
                        }}
                        // style={{width: 300}}
                        color={"secondary"}
                        renderInput={(params) => <TextField {...params}
                                                            className={error && (!checkTechnician) ? 'error' : ''}
                                                            onAnimationEnd={() => setError(false)}

                                                            required
                                                            color={"secondary"}
                                                            variant={'standard'}
                                                            label="Tecnici"/>}
                    />
                    <div className={'row'}>
                        <TextField
                            color={"secondary"}
                            required
                            variant={"outlined"}
                            id="description"
                            label="Descrizione"
                            className={error && (!checkDescription) ? 'textField error' : 'textField'}
                            onAnimationEnd={() => setError(false)}
                            style={{marginRight: '10px', height: '100%'}}
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            helperText={'usare {x} per inserire la settimana'}
                        />
                        <div style={{width: 'min-content'}}>
                            <TextField
                                variant={'standard'}
                                value={fdlNumber}
                                type={'number'}
                                onChange={e => {
                                    const value = e.target.value
                                    setFdlNumber(value)
                                }}
                                onBlur={e => {
                                    const value = e.target.value
                                    if (value === null || value < 1) {
                                        setFdlNumber(1)
                                    }
                                }}
                                label={'Ripetizioni'}
                                className={'littleTextField'}
                                color={"secondary"}
                            />
                            <TextField
                                variant={'standard'}
                                value={weekNumber}
                                type={'number'}
                                onChange={e => setWeekNumber(parseInt(e.target.value))}
                                label={'Settimana'}
                                className={'littleTextField'}
                                color={"secondary"}
                            />
                        </div>
                    </div>
                </div>
                <Paper className={'tablePreview'}>
                    <table {...getTableProps()}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            minWidth: column.minWidth,
                                            maxWidth: column.maxWidth,
                                            width: column.width
                                        },
                                    })}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} style={{}}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps({
                                            style: {
                                                minWidth: cell.column.minWidth,
                                                maxWidth: cell.column.maxWidth,
                                                width: cell.column.width,
                                            },
                                        })}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </Paper>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => {
                    setClean(true)
                    closePopUp(false)
                }}
                        color={"secondary"}
                >Annulla</Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        if (!checkTechnician || !checkDescription || !checkFdlCode || !checkCommission) {
                            setError(true)
                            return
                        }
                        addFDL(sendData).then(() => {
                            setClean(true)
                            closePopUp(false)
                        })
                    }}
                >Inserisci FDL</Button>
            </DialogActions>
        </Dialog>
    )
};

export default PopUpNewFdl;
