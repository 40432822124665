import * as actionType from '../actions/action'
import {List} from 'immutable';
import {updateObject} from "../utility";


const initialState = {
    countries: List([]),
    regions: List([]),
    themes: List([]),

};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_COUNTRIES:
            return updateObject(state, {countries: action.data});
        case actionType.UPDATE_REGIONS:
            return updateObject(state, {regions: action.data});
        case actionType.UPDATE_THEMES:
            return updateObject(state, {themes: action.data});

        default:
            return state

    }
};

export default reducer;