import React, {useState} from "react";
import {Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import {Map} from 'immutable'
import './PopUpNewClient.css'
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const PopUpNewClient = (props) => {

    const [newCli, setNewCli] = useState(Map({
        name: '',
        code: 0
        // cliDate: new Date()
    }))

    const onChangeNewComm = (key, value) => {
        if ((value.length < 5) || key === 'name') {
            setNewCli((oldState) => oldState.update(key, val => value))
        }
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} className={'popUpNewCli'}>
            <DialogTitle className={'header'}>
                Creazione nuovo cliente
            </DialogTitle>
            <Divider/>
            <DialogContent className={'content'}>
                {/*<Paper variant={"outlined"} className={'general'}>*/}
                <div className={'general'}>
                    {/*<Typography>Generali</Typography>*/}
                    <TextField
                        id={'inputnome'}
                        value={newCli.get('name')}
                        label={'Nome cliente'}
                        onChange={(event) => onChangeNewComm('name', event.target.value)}
                        variant={'outlined'}
                        // size={"small"}
                        margin={"dense"}
                        required
                    />
                    <TextField
                        id={'inputPrgCli'}
                        value={newCli.get('code')}
                        label={'Codice cliente'}
                        onChange={(event) => onChangeNewComm('code', event.target.value)}
                        variant={'outlined'}
                        // size={"small"}
                        margin={"dense"}
                        type={'number'}
                        required
                        helperText={'es. nnnn'}
                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                    />
                </div>
                {/*</Paper>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Annulla</Button>
                <Button variant={"outlined"} style={{width: '100px'}}
                        onClick={() => props.onNewCli(newCli.get('code'), newCli.get('name'))}
                >Crea</Button>
            </DialogActions>
        </Dialog>


    )

}

export default PopUpNewClient;