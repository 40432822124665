import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import StatisticBox from "../StatisticBox/StatisticBox";
import TimelineIcon from "@mui/icons-material/Timeline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PublicIcon from "@mui/icons-material/Public";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ExploreIcon from "@mui/icons-material/Explore";
import * as PropTypes from "prop-types";
import AvatarTechnicians from "../AvatarTechnicians/AvatarTechnicians";
import './CommStat.css'

const CommStat = (props) => {

    let user_list = null
    if (props.statistics.getIn(['user_loads'])) {
        user_list = props.statistics.getIn(['user_loads']).map((tech, index) => {
            return (
                <AvatarTechnicians
                    key={'avatarTech' + tech.get('name')}
                    name={tech.get('name')}
                    surname={tech.get('surname')}
                    totHours={Math.round(tech.get('tot_hour')).toString() + 'h'}

                />
            )
        })
    }

    return <Paper variant={"outlined"} className={"CommStat"} transparent>
        <Typography>Statistiche</Typography>
        <Divider style={{margin: "0 0 10px 0"}}/>
        <Grid container spacing={2}>
            <Grid item xs>
                <StatisticBox
                    title={"Ore totali"}
                    value={props.statistics.getIn(["tot_hour"], 0) + " h"}
                    icon={TimelineIcon}
                    color={"#d32f2f"}
                    info={'Ore totali, incluse ore viaggio'}
                />
            </Grid>
            <Grid item xs>
                <StatisticBox
                    title={"Giorni totali"}
                    value={props.statistics.getIn(["unique_days"], 0) + " G"}
                    icon={DateRangeIcon}
                    color={"#303f9f"}
                    info={'Totale giorni singoli'}
                />
            </Grid>
            <Grid item xs>
                <StatisticBox
                    title={"Ore Lavoro"}
                    value={props.statistics.getIn(["work_hour"], 0)}
                    icon={TimelapseIcon}
                    color={"#0097a7"}
                />
            </Grid>
            <Grid item xs>
                <StatisticBox
                    title={"Ore viaggio"}
                    value={props.statistics.getIn(["travel_hour"], 0) + " h"}
                    icon={EmojiTransportationIcon}
                    color={"#388e3c"}
                />
            </Grid>
            <Grid item xs>
                <StatisticBox
                    title={"Giorni fuori sede"}
                    value={props.statistics.getIn(["fs_days"], 0) + " G"}
                    icon={ExploreIcon}
                    color={"#ef6c00"}
                />
            </Grid>
            <Grid item xs>
                <StatisticBox
                    title={"Giorni trasferta"}
                    value={props.statistics.getIn(["trs_days"], 0) + " G"}
                    icon={PublicIcon}
                    color={"#00796b"}
                />
            </Grid>
        </Grid>
        <div style={{display: "flex", flexDirection: "row", margin: "20px 0 0 10px"}}>
            {user_list}
        </div>
    </Paper>;
}

CommStat.propTypes = {
    statistics: PropTypes.any,
};

export default CommStat;