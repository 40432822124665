import * as actionType from '../actions/action'
import {List, Map} from 'immutable';
import {updateObject} from "../utility";
import {UPDATE_FDL_EXPORT_DATA} from "../actions/action";


const initialState = {
    machines: List([]),
    mng_data: List([]),
    clients: List([]),
    fdl: List([Map({})]),
    users: List([]),
    commissionCodes: List([]),
    workdataAggregation: List([]),
    workdataCompilation: Map({}),
    fdlExportData: List([])
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_MACHINES_LIST:
            return updateObject(state, {machines: action.machines});
        case actionType.UPDATE_MNG_DATA:
            return updateObject(state, {mng_data: action.data});
        case actionType.UPDATE_CLIENTS:
            return updateObject(state, {clients: action.data});
        case actionType.UPDATE_FDL:
            return updateObject(state, {fdl: action.data});
        case actionType.UPDATE_USERS:
            return updateObject(state, {users: action.data});
        case actionType.UPDATE_COMMISSION_CODES:
            return updateObject(state, {commissionCodes: action.data});
        case actionType.UPDATE_WORKDATA_AGGREGATION:
            return updateObject(state, {workdataAggregation: action.data});
        case actionType.UPDATE_WORKDATA_COMPILATION:
            return updateObject(state, {workdataCompilation: action.data});
        case actionType.UPDATE_FDL_EXPORT_DATA:
            return updateObject(state, {fdlExportData: action.data});

        default:
            return state

    }
};

export default reducer;