import React from "react";
import './FdlTable.scss'
import MaUTable from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useSortBy, useTable} from "react-table";
import {Checkbox, Typography} from "@mui/material";
import moment from "moment";
import {FixedSizeList} from 'react-window';
import {useBlockLayout} from "react-table/src/plugin-hooks/useBlockLayout";

const Cell = ({cell: {value, column}, row: {original}, onCellChange, editPermission}) => {
    // We need to keep and update the state of the cell normally
    const [actValue, setActValue] = React.useState(value)

    const onChange = e => {
        const v = e.target.checked
        setActValue(v)
        onCellChange(original.code, column.id, v)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setActValue(value)
    }, [value])

    if ((column.id === 'validated_date' || column.id === 'not_signed_date' || column.id === 'not_used_date') && value != null) return <Typography>{moment(value).format('DD/MM/YYYY')}</Typography>
    if (column.id === 'validated_date' || column.id === 'not_signed_date' || column.id === 'not_used_date') return <Typography>--</Typography>
    if (column.id !== 'validated' && column.id !== 'not_signed' && column.id !== 'not_used') return <span>{value}</span>;
    return <Checkbox
        checked={actValue}
        onChange={onChange}
        disabled={!editPermission}
        inputProps={{'aria-label': 'primary checkbox'}}
    />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: Cell,
}

const FdlTable = ({columns, data, onCellChange, height, editPermission}) => {



    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
            columns,
            data,
            initialState: {
                sortBy: [{
                    id: 'fdlCode',
                    desc: true
                }]
            },
            defaultColumn,
            onCellChange,
        },
        useBlockLayout,
        useSortBy,);

    const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps({
                                style: {
                                    minWidth: cell.column.minWidth,
                                    maxWidth: cell.column.maxWidth,
                                    width: cell.column.width,
                                },
                            })} className="td" >
                {cell.render('Cell', {editPermission:editPermission})}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

    return (
        <div className={'fdlTable'}>
            <MaUTable {...getTableProps()} >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps({
                                    style: {
                                        minWidth: column.minWidth,
                                        maxWidth: column.maxWidth,
                                        width: column.width,
                                    },
                                })}>
                                    {column.render('Header')}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()} style={{height: height ? height - 370 : 400}} className={'tbody'}>
                    <FixedSizeList
                        height={window.innerHeight - 435}
                        itemCount={rows.length}
                        itemSize={50}
                        // width={totalColumnsWidth + scrollBarSize -70}
                        width={window.innerWidth -80}
                    >
                        {RenderRow}
                    </FixedSizeList>
                    {/*{rows.map((row, i) => {*/}
                    {/*    prepareRow(row)*/}
                    {/*    return (*/}
                    {/*        <TableRow {...row.getRowProps()} >*/}
                    {/*            {row.cells.map(cell => {*/}
                    {/*                return (*/}
                    {/*                    <TableCell {...cell.getCellProps({*/}
                    {/*                        style: {*/}
                    {/*                            minWidth: cell.column.minWidth,*/}
                    {/*                            maxWidth: cell.column.maxWidth,*/}
                    {/*                            width: cell.column.width,*/}
                    {/*                        },*/}
                    {/*                    })}>*/}
                    {/*                        {cell.render('Cell')}*/}
                    {/*                    </TableCell>*/}
                    {/*                )*/}
                    {/*            })}*/}
                    {/*        </TableRow>*/}
                    {/*    )*/}
                    {/*})}*/}
                </TableBody>
            </MaUTable>
        </div>
    );
};

export default FdlTable;
