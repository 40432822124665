import React, {Component} from "react";
import './Commission.scss'
import {Card, InputAdornment} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";


const Commission = ({commission, onCommissionSelected}) =>  {

        let reporting = false
        if (commission.get('integrations')) {
            reporting = commission.get('integrations').some((intg) =>  intg.get('reporting'))
        }

        return (
            <React.Fragment>
                <Card className={'commissions' + (reporting ? ' reporting' : '')} variant={"elevation"} elevation={9}
                      id={commission.get('id')}>
                    <CardActionArea className={'actionArea'} onClick={onCommissionSelected}>
                        <div style={{height: '100%'}}>
                            <CardHeader
                                title={`${commission.get('client_code')}${commission.get('commission_code')}`}
                            />
                            <Divider variant={'middle'}/>
                            <CardContent className={'content'}>
                                <Paper variant={"outlined"} className={'paper'} transparent>
                                    <Typography align={"left"} className={'description'}>
                                        {commission.get('description')}
                                    </Typography>
                                </Paper>
                            </CardContent>
                        </div>
                    </CardActionArea>
                </Card>
            </React.Fragment>
        )
}

export default Commission;