export const NivoTheme = {
    background: 'transparent',
    fontFamily: 'sans-serif',
    fontSize: 11,
    textColor: '#ffffff',
    color: 'white',

    axis: {
        domain: {
            line: {
                stroke: 'transparent',
                strokeWidth: 1,
                textColor: '#ffffff',
                color: '#ffffff',

            },
        },
        ticks: {
            line: {
                stroke: '#ffffff',
                strokeWidth: 1,
            },
            text: {},
        },
        legend: {
            text: {
                fontSize: 12,
                textColor: '#ffffff',
                color: '#ffffff',


            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 1,
        },
        text: {
                textColor: '#ffffff',
                color: '#ffffff',

        }
    },
    legends: {
        text: {
            fill: '#ffffff',
                textColor: '#ffffff',
                color: '#ffffff',

        },
    },
    labels: {
        text: {
                textColor: '#ffffff',
                color: '#ffffff',


        },
    },
    markers: {
        lineColor: '#ffffff',
        lineStrokeWidth: 1,
        text: {
                textColor: '#ffffff',
                color: '#ffffff',

        },
    },
    dots: {
        text: {
                textColor: '#ffffff',
                color: '#ffffff',

        },
    },
    tooltip: {
        container: {
            background: 'black',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px',
                textColor: '#ffffff',
                color: '#ffffff',

        },
        basic: {
            whiteSpace: 'pre',
            display: 'flex',
            alignItems: 'center',
        },
        chip: {
            marginRight: 7,
        },
        table: {},
        tableCell: {
            padding: '3px 5px',
        },
    },
    crosshair: {
        line: {
            stroke: '#ffffff',
            strokeWidth: 1,
            strokeOpacity: 0.75,
            strokeDasharray: '6 6',
        },
    },
    annotations: {
        text: {
            fontSize: 13,
            outlineWidth: 2,
            outlineColor: '#ffffff',
                textColor: '#ffffff',
                color: '#ffffff',

        },
        link: {
            stroke: '#ffffff',
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: '#ffffff',
        },
        outline: {
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: '#ffffff',
        },
        symbol: {
            fill: '#ffffff',
            outlineWidth: 2,
            outlineColor: '#ffffff',
        },
    },
}