import React from 'react';
import {Avatar} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import './AvatarTechnicians.css'

const AvatarTechnicians = (props) => {

    return (
        <div className={'avatar_technicians'}>
            <Tooltip title={props.name + ' ' + props.surname + ' - Ore totali: ' + props.totHours}>
                <Avatar
                    style={{backgroundColor: '#00897b', color:'#FFF'}}>
                    {props.name.substring(0, 1) + props.surname.substring(0, 1)}
                </Avatar>
            </Tooltip>
        </div>
    )

}

export default AvatarTechnicians;