import React from "react";
import * as actionTypes from "./action";
import * as actionType from "./action";
import {enqueueSnackbar} from "./action";
import {fromJS, List, Map} from "immutable";
import apiV2 from "../../apiV2";
import {errorNotification} from "../utility";

const updateMachines = (res) => {
    return {
        type: actionTypes.UPDATE_MACHINES_LIST,
        machines: res
    };
};

const updateMngData = (data) => {
    return {
        type: actionTypes.UPDATE_MNG_DATA,
        data: data
    };
};

const UpdateClients = (data) => {
    return {
        type: actionType.UPDATE_CLIENTS,
        data: data
    };
};


// region Machines
export const get_machines_list = (day, bef_days) => {
    return (dispatch, getState) => {
        apiV2.get('machines').then(response => {
            dispatch(updateMachines(fromJS(response.data.data)))
        });
    }
}
// endregion

// region mng_data
export const get_mng_data = () => {
    return (dispatch, getState) => {
        apiV2.post('/advancedClients', {}).then(response => {
            dispatch(updateMngData(fromJS(response.data.data)))
        });
    }
}

// endregion

// region client
// export const get_clients = () => {
//     return (dispatch, getState) => {
//         axios.get('/clients').then(response => {
//             dispatch(UpdateClients(fromJS(response.data.data)))
//         });
//     }
// }

export const add_client = (client_code, cmp_name) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post('/clients', [{client_code: client_code, company_name: cmp_name}])
                .then(response => {
                    dispatch(updateMngData(getState().mng_data.mng_data.merge(fromJS(response.data.data))))
                    resolve()
                })
                .catch(err => {
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.data.code === 409) message = 'codice cliente già utilizzato'
                        if (err.response.data.code === 406) message = 'Lunghezza codice sbagliata (4 caratteri)'
                        if (err.response.status === 403) message = 'Permessi non sufficienti'

                    } else {
                        message = err.message
                    }
                    dispatch(
                        enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        })
                    )
                    reject()
                });
        })
    }
}

export const putClient = (client_code, client) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            client = client.delete('commissions')
            apiV2.put(`/clients/${client_code}`, client.toJS())
                .then((res)=>{
                    const data = fromJS(res.data)
                    const mngData = getState().mng_data.mng_data
                    dispatch(updateMngData(mngData.updateIn([mngData.findIndex(c=>c.get('client_code') === client_code)], (old)=>old.merge(data))))
                    resolve()
                })
                .catch((err) => {

                })

        })
    }

}
// endregion

// region commission
export const add_comm = (client_code, comm_code, description) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post(`clients/${client_code}/commissions`, [{
                client_code: client_code,
                commission_code: comm_code,
                description: description
            }])
                .then(response => {
                    const oldState = getState().mng_data.mng_data
                    dispatch(updateMngData(oldState.updateIn([oldState.findIndex(val => val.get('client_code') === client_code), 'commissions'], val => val.merge(fromJS(response.data.data)))))
                    // dispatch(newIntegration(client_code, response.data.data.id, 1))
                    //     .then(()=>resolve())
                    //     .catch(()=>resolve())

                    resolve();

                })
                .catch(err => {
                    console.error(err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.data.code === 409) message = 'codice commessa già utilizzato'
                        if (err.response.data.code === 406) message = 'Lunghezza campi non valida (4 caratteri)'
                        if (err.response.status === 403) message = 'Permessi non sufficienti'

                    } else {
                        message = err.message
                    }
                    dispatch(
                        enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        })
                    )
                    reject()
                });
        })
    }
}

export const update_comm = (commission_code, comm) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const client_code = comm.get('client_code')
            const client_index = getState().mng_data.mng_data.findIndex(c => c.get('client_code') === client_code)
            const commission_index = getState().mng_data.mng_data.getIn([client_index, 'commissions']).findIndex(c => c.get('commission_code') === commission_code)

            apiV2.put(
                `/clients/${client_code.toString()}/commissions/${commission_code.toString()}`, comm)
                // comm)
                .then((response) => {
                        const res = fromJS(response.data.data)
                        dispatch(updateMngData(getState().mng_data.mng_data.updateIn([client_index, 'commissions', commission_index], val => res)))
                        resolve(res)
                    }
                )
                .catch(err => {
                    console.error(`/update_comm`, err)
                    let message = 'Aggiornamento commessa fallito'
                    if (err.response) {
                        if (err.response.status === 403) {
                            message = 'Permessi non sufficienti'
                        }
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    }))
                    reject()
                })
        })
    }
}

export const deleteCommission = (client_code, comm_code) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const oldState = getState().mng_data.mng_data
            const cliIndex = oldState.findIndex(val => val.get('client_code') === client_code)
            const commIndex = oldState.getIn([cliIndex, 'commissions']).findIndex(val => val.get('commission_code') === comm_code)
            apiV2.delete(`clients/${client_code}/commissions/${comm_code}`)
                .then((response) => {
                    dispatch(updateMngData(oldState.deleteIn([cliIndex, 'commissions', commIndex])))
                    resolve()

                })
                .catch((err) => {
                    console.error(`/delete_commission`, err)
                    let message = 'Eliminazione commessa fallito'
                    if (err.response) {
                        if (err.response.status === 403) message = 'Permessi non sufficienti'
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    }))
                    reject()
                })
        })
    }
}

export const getCommissionCodes = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.get(`commissionsCode`).then(res => {
                dispatch({
                    type: actionType.UPDATE_COMMISSION_CODES,
                    data: fromJS(res.data.data.data)
                })
            })
        })
    }
}
// endregion

// region Integration
export const newIntegration = (client_code, commission_code, integration_code) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const oldState = getState().mng_data.mng_data
            const cliIndex = oldState.findIndex(val => val.get('client_code') === client_code)
            const commIndex = oldState.getIn([cliIndex, 'commissions']).findIndex(val => val.get('commission_code') === commission_code)
            apiV2.post(`clients/${client_code}/commissions/${commission_code}/integrations`, [{
                client_code: client_code,
                commission_code: commission_code,
                integration_code: integration_code
            }])
                .then(response => {
                    dispatch(updateMngData(oldState.updateIn([cliIndex, 'commissions', commIndex, 'integrations'], val => val.merge(fromJS(response.data.data)))))
                    const res = response.data.data[0];
                    resolve(`${res.client_code}${res.commission_code}_${res.integration_code}`)
                })
                .catch(err => {
                    console.error(err)
                    let message = 'Creazione integrazione fallita'
                    if (err.response) {
                        if (err.response.data.code === 409) message = 'Codice integrazione già utilizzato'
                        if (err.response.status === 403) message = 'Permessi non sufficienti'

                    } else {
                        message = err.message
                    }
                    dispatch(
                        enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        })
                    )
                    reject()
                });
        })
    }
}

export const deleteIntegration = (client_code, commission_code, integration_code) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const oldState = getState().mng_data.mng_data
            const cliIndex = oldState.findIndex(val => val.get('client_code') === client_code)
            const commIndex = oldState.getIn([cliIndex, 'commissions']).findIndex(val => val.get('commission_code') === commission_code)
            const intgIndex = oldState.getIn([cliIndex, 'commissions', commIndex, 'integrations']).findIndex(val => val.get('integration_code') === integration_code)
            console.log(intgIndex)
            apiV2.delete(`clients/${client_code}/commissions/${commission_code}/integrations/${integration_code}`)
                .then((response) => {
                    dispatch(updateMngData(oldState.deleteIn([cliIndex, 'commissions', commIndex, 'integrations', intgIndex])))
                    resolve()

                })
                .catch((err) => {
                    console.error(`/delete_integration`, err)
                    let message = 'Eliminazione integrazione fallito'
                    if (err.response) {
                        if (err.response.status === 403) message = 'Permessi non sufficienti'
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    }))
                    reject()
                })
        })
    }
}

export const sendIntegration = (client_code, commission_code, integration_code) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post(`sendIntegration/${client_code}${commission_code}_${integration_code}`)
                .then(res => {
                    const oldState = getState().mng_data.mng_data
                    const cliIndex = oldState.findIndex(val => val.get('client_code') === client_code)
                    const commIndex = oldState.getIn([cliIndex, 'commissions']).findIndex(val => val.get('commission_code') === commission_code)
                    const intgIndex = oldState.getIn([cliIndex, 'commissions', commIndex, 'integrations']).findIndex(val => val.get('integration_code') === integration_code)
                    dispatch(updateMngData(oldState.updateIn([cliIndex, 'commissions', commIndex, 'integrations', intgIndex, 'sent'], i => getState().auth.user.getIn(['userData', 'id']))))
                    dispatch(enqueueSnackbar({
                        message: `Nuova integrazione ${client_code}${commission_code}_${integration_code} inviata`,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                            autoHideDuration: 2000,
                        },
                    }))
                    resolve()
                })
                .catch(err => {
                    console.error(`/fdl`, err)
                    dispatch(errorNotification(err, '000000', {}, 'Errore invio FDL, controllare nelle email inviate'))
                    reject(err)
                })
        })
    }
}
// endregion

// region FDL
export const getAllFDL = (filters = {}) => { //from_emissions: '01/06/2021'
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post('fdls', filters)
                .then(res => {
                        let data = fromJS(res.data.data)
                        if (data.length === 0) data = List([Map()])
                        dispatch({
                            type: actionType.UPDATE_FDL,
                            data: data
                        })
                        resolve(data)
                    }
                )
                .catch(err => {
                    console.error(`/fdl`, err)
                    dispatch(errorNotification(err, '030701', {}, 'Errore get lista FDL'))
                    reject(err)
                })
        })
    }
}

export const addFDL = (newFdl) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post('fdl', newFdl)
                .then(res => {
                    let oldFdlList = getState().mng_data.fdl;
                    oldFdlList = oldFdlList.merge(fromJS(res.data.data))
                    dispatch({
                        type: actionType.UPDATE_FDL,
                        data: oldFdlList
                    })
                    resolve()
                })
                .catch(err => {
                    console.error(`/fdl`, err)
                    dispatch(errorNotification(err, '030702', {}, 'Errore aggiunta FDL'))
                    reject(err)
                })
        })
    }
}

export const deleteFDL = (fdlCode) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.delete(`fdl/${fdlCode}`,)
                .then(res => {
                    let oldFdlList = getState().mng_data.fdl;
                    oldFdlList = oldFdlList.delete(oldFdlList.findIndex(f => f.get('code') === fdlCode))
                    dispatch({
                        type: actionType.UPDATE_FDL,
                        data: oldFdlList
                    })
                    resolve()
                })
                .catch(err => {
                    console.error(`/fdl`, err)
                    dispatch(errorNotification(err, '030702', {}, 'Errore eliminazione FDL'))
                    reject(err)
                })
        })
    }
}

export const saveFdlChanges = (fdlChangeList) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            fdlChangeList.forEach(f => {
                apiV2.put(`fdl/${f.get('code')}`, f.toJS())
                    .then(res => {
                        const oldFdlList = getState().mng_data.fdl
                        const newFdl = fromJS(res.data.data)
                        dispatch({
                            type: actionType.UPDATE_FDL,
                            data: getState().mng_data.fdl.update(getState().mng_data.fdl.findIndex(f => f.get('code') === newFdl.get('code')), () => newFdl)
                        })
                    })
            })
            resolve()
            //     .then(res => {
            //         let oldFdlList = getState().mng_data.fdl;
            //         oldFdlList = oldFdlList.delete(oldFdlList.findIndex(f=>f.get('id')===fdlId))
            //         dispatch({
            //             type: actionType.UPDATE_FDL,
            //             data: oldFdlList
            //         })
            //     })
            //     .catch(err => {
            //         console.error(`/fdl`, err)
            //         dispatch(errorNotification(err, '030702', {}, 'Errore eliminazione FDL'))
            //         reject(err)
            //     })
        })
    }
}

export const updateFDL = (code, property, value) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const oldFdlList = getState().mng_data.fdl
            dispatch({
                type: actionType.UPDATE_FDL,
                data: oldFdlList.updateIn([oldFdlList.findIndex(f => f.get('code') === code), property], () => value)
            })
            resolve()
        })
    }
}


export const sendFDL = (code) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post(`sendFdl/${code}`)
                .then(res => {
                    const oldFdlList = getState().mng_data.fdl
                    dispatch({
                        type: actionType.UPDATE_FDL,
                        data: oldFdlList.updateIn([oldFdlList.findIndex(f => f.get('code') === code), 'sent'], () => res.data.data)
                    })
                    resolve()
                })
                .catch(err => {
                    console.error(`/fdl`, err)
                    dispatch(errorNotification(err, '030702', {}, 'Errore aggiunta FDL'))
                    reject(err)
                })
        })
    }
}


//endregion

// region users
export const getAllUsers = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.get('users')
                .then(res => {
                        resolve()
                        return dispatch({
                            type: actionType.UPDATE_USERS,
                            data: fromJS(res.data.data)
                        })
                    }
                )
                .catch(err => {
                    if (err.response.data.code === 403) {
                        dispatch({
                            type: actionType.UPDATE_USERS,
                            data: List([getState().auth.user.get('userData')])
                        })
                        return resolve()

                    }

                    dispatch(errorNotification(err, '030801', {}, 'Errore get lista utenti'))
                    reject(err)
                })
        })
    }
}
// endregion

// region test
export const getWorkdataAggregation = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.get('statistics/workdataAggregation')
                .then(res =>
                    dispatch({
                        type: actionType.UPDATE_WORKDATA_AGGREGATION,
                        data: fromJS(res.data.data)
                    })
                )
                .catch(err => {
                    reject(err)
                })
        })
    }
}
// endregion

// region test
export const getWorkdataCompilation = (year, month) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.get(`statistics/workdataCompilation?year=${year}&month=${month}`)
                .then(res =>
                    dispatch({
                        type: actionType.UPDATE_WORKDATA_COMPILATION,
                        data: fromJS({year, month, data:res.data.data})
                    })
                )
                .catch(err => {
                    reject(err)
                })
        })
    }
}
// endregion


// export const post_newComm = (comm) => {
//         return (dispatch, getState) => {
//         axios.post('/api/lista/commesse', {'comm':comm}).then(response => {
//             console.log(response)
//             dispatch(UpdateCommList(getState().mng.lista_commesse.push(fromJS(response.data.data))))
//         });
//     }
// }
//
//
//
// export const get_cliList = () => {
//     return (dispatch, getState) => {
//
//         axios.get('/api/lista/clienti').then(response => {
//             dispatch(UpdateCliList(fromJS(response.data.data)))
//         });
//     }
// }
//
// export const post_newCli = (cli) => {
//         return (dispatch, getState) => {
//         axios.post('/api/lista/clienti', {'cli':cli}).then(response => {
//             console.log(response)
//             dispatch(UpdateCliList(getState().mng.lista_clienti.push(fromJS(response.data.data))))
//         });
//     }
// }
//
//
//
// export const UpdateCommTask = (data, old_data) => {
//     return (dispatch, getState) => {
//
//         data.primo_giorno = reformatDate(data.primo_giorno);
//         axios.post('/api/task/edittasks', {
//             "id": old_data.id,
//             "task_id": data.task_id,
//             "descrizione": data.descrizione,
//             "tecnico": "",
//             "primo_giorno": data.primo_giorno.toLocaleDateString(),
//             "durata": data.durata,
//             "avanzamento": data.avanzamento,
//         }).then(response => {
//             dispatch(UpdateCommTasks(getState().mng.comm_task[getState().comm_task.indexOf(old_data)] = data))
//             console.log(response)
//         });
//     }
// };
//
// export const AddCommTask = (data, comm_index) => {
//     return (dispatch, getState) => {
//
//         data.primo_giorno = reformatDate(data.primo_giorno);
//         axios.post('/api/task/addtasks', {
//             "task_id": data.task_id,
//             "db_key": getState().mng.lista_commesse.getIn([comm_index, "id"]),
//             "descrizione": data.descrizione,
//             "tecnico": "",
//             "primo_giorno": data.primo_giorno.toLocaleDateString(),
//             "durata": data.durata,
//             "avanzamento": data.avanzamento,
//         }).then(response => {
//             dispatch(UpdateCommTasks(getState().mng.comm_task.push(data)))
//         });
//     }
// };
//
// export const DeleteCommTask = (old_data) => {
//     return (dispatch, getState) => {
//
//         axios.post('/api/task/deletetasks', {
//             "id": old_data.id,
//             "task_id": old_data.task_id,
//         }).then(response => {
//             dispatch(UpdateCommTasks(getState().mng.comm_task.splice([getState().comm_task.indexOf(old_data)], 1)))
//         });
//     }
// };