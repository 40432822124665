import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from "./store/reducer/auth";
import thunk from 'redux-thunk';
import dataReducer from "./store/reducer/WorkData";
import userData from "./store/reducer/userData";
import MngData from "./store/reducer/MngData";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {MediaQueryProvider} from "react-media-query-hoc";
import Meta from "./store/reducer/Meta";
import {LicenseInfo} from "@mui/x-license-pro";

// LicenseInfo.setLicenseKey('0a9738748108c11d37090923fbbfdc1bTz00NTk5NCxFPTE2ODczNTMyMDg1MTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
LicenseInfo.setLicenseKey('b11ebeb7749b2b49a2276f56e8648b14Tz1NVUktMTIzLEU9MTcwOTc2MTEzMTAwMCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// console.debug(
//             generateLicense({
//                 expiryDate: new Date(1709761131000),
//                 orderNumber: 'MUI-123',
//                 scope: 'premium',
//                 licensingModel: 'subscription',
//             })
//         )

const rootReducer = combineReducers({
    data: dataReducer,
    auth: authReducer,
    mng_data: MngData,
    userData: userData,
    meta: Meta

});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


const customQueries = {
    littleScreen: 'screen and (max-width: 600px)',
    mobile: 'screen and (min-width: 601px) and (max-width: 960px)',
    tablet: 'screen and (min-width: 961px) and (max-width: 1550px)',
    desktop: 'screen and (min-width: 1551px) and (max-width: 1800px)',
    largeDesktop: 'screen and (min-width: 1801px)',
    genPhone: 'screen and (max-width: 960px)',
    genPc: 'screen and (min-width: 961px)',
};


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MediaQueryProvider queries={customQueries}>
                <App/>
            </MediaQueryProvider>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();