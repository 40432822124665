import React, {Component} from "react";
import './CalendarView.css'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {connect} from "react-redux";
import {getEventWorktime} from "../../store/actions/WorkData";
import {List} from 'immutable'

class CalendarView extends Component {

    componentDidMount() {
        this.props.get_event_worktime()
    }


    render() {

        let events = List([])
        if (this.props.eventWorktime.size > 0) {
            events = this.props.eventWorktime.map((v) => {
                return {
                    id: v.get('id'),
                    title: v.get('nc'),
                    start: (v.get('day'))
                }
            })
        }


        return (
            <div className={'calendarView'}>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    height={'100%'}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    fixedWeekCount={true}
                    firstDay={1}
                    // dayMaxEvents={true}
                    events={events.toJS()}
                    // events={[
                    //     { // this object will be "parsed" into an Event Object
                    //         title: 'The Title', // a property!
                    //         start: '2020-12-06', // a property!
                    //         // end: '2020-12-07' // a property! ** see important note below about 'end' **
                    //     }
                    // ]
                    // }
                />
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {

        user: state.auth.user,
        eventWorktime: state.data.eventWorktime,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        get_event_worktime: () => dispatch(getEventWorktime()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);

