import React, {Component} from 'react'

class DragAndDropV2 extends Component {
    state = {
        drag: false
    };


    dropRef = React.createRef();
    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation()
    };
    handleDragIn = (e) => {
        if (isNaN(this.dragCounter)) {
            this.dragCounter = 0;
        }
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true})
        }
    };
    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({drag: false})
        }
    };
    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({drag: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0
        }
    };

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop)

    }

    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop)
    }


    render() {

        const dragStyle = {


        }

        const dropStyle = {
            border: 'dashed rgba(250,250,250,1) 2px',
            backgroundColor: 'rgba(3,155,229,0.3)',
            zIndex: 9999,
            height: '100%',
            width: '100%',

        }


        return (
            <div
                // style={{display: 'inline-block', position: 'relative'}}
                ref={this.dropRef}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                {/*{this.state.drag &&*/}
                {/*<div*/}
                {/*    style={{*/}
                {/*        border: 'dashed grey 2px',*/}
                {/*        backgroundColor: 'rgba(0,188,212,0.3)',*/}
                {/*        zIndex: 9999,*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            position: 'relative',*/}
                {/*            top: 0,*/}
                {/*            right: 0,*/}
                {/*            left: 0,*/}
                {/*            textAlign: 'center',*/}
                {/*            color: 'white',*/}
                {/*            fontSize: 36*/}
                {/*        }}*/}
                {/*    >*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*}*/}
                <div
                    style={this.state.drag ? dropStyle : dragStyle}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default DragAndDropV2