import React, {useEffect, useState} from 'react';
import style from './Themes.module.scss';
import Day from "../Library/Day/Day";
import {fromJS, List, Map} from 'immutable';
import WorkData from "../WorkList/WorkData/WorkData";
import {Button, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateTheme} from "../../store/actions/userData";
import {DataGridPro} from "@mui/x-data-grid-pro";

const Themes = () => {

    const dispatch = useDispatch()

    const userConfig = useSelector(state => state.auth.user.getIn(["userData", "config", "theme"]))
    const reduxThemes = useSelector(state => state.meta.themes)

    const [themes, setThemes] = useState(List([]))
    const [color1, setColor1] = useState('')
    const [color2, setColor2] = useState('')
    const [color3, setColor3] = useState('')

    useEffect(() => {
        setThemes(reduxThemes)
    }, [reduxThemes])

    useEffect(() => {
        setColor1(userConfig.get('primaryColor'))
        setColor2(userConfig.get('secondaryColor'))
        setColor3(userConfig.get('tertiaryColor'))
    }, [])

    return (
        <>
            <div className={style.workdata}>

                <div className={style.days}>
                    <Day
                        key={'1'}
                        first={false}
                        last={false}
                        forceToday={true}
                        day={Map({"day": "10-09-2022"})}
                        totDay={0}
                        totTravel={0}
                        selectedDay={'19-10-2022'}
                        onClick={() => ''}
                    />
                    <Day
                        key={'1'}
                        first={false}
                        last={false}
                        day={Map({"day": "10-09-2022"})}
                        totDay={0}
                        totTravel={0}
                        selectedDay={'09-10-2022'}
                        onClick={() => ''}
                    />
                    <Day
                        key={'1'}
                        first={false}
                        last={false}
                        forceToday={true}
                        day={Map({"day": "10-10-2022"})}
                        totDay={0}
                        totTravel={0}
                        selectedDay={'10-10-2022'}
                        onClick={() => ''}
                    />
                    <Day
                        key={'1'}
                        first={false}
                        last={false}
                        day={Map({"day": "09-12-2022"})}
                        totDay={0}
                        totTravel={0}
                        selectedDay={'11-10-2022'}
                        onClick={() => ''}
                    />
                </div>
                <WorkData
                    className={style.wt}
                    key={1}
                    hideTasks={true}
                    lockRemoveComm={true}
                    workdata={fromJS({
                        task: null,
                        interlocutor: '',
                        place: 'dfds',
                        client_nc: '',
                        locked: null,
                        fs: false,
                        fdl: '',
                        tasks: [],
                        user_id: 2,
                        last_update: null,
                        purchases: [
                            {
                                method: 1,
                                total: 0,
                                wd_id: 14350,
                                user_id: 2,
                                currency: 0,
                                note: '',
                                nc: '',
                                id: 565,
                                day: '2022-10-10',
                                description: ''
                            }
                        ],
                        note: '',
                        region: '',
                        nc: '',
                        trs: true,
                        travels: [
                            {
                                km: 0,
                                end: '00:00',
                                highway: null,
                                wd_id: 14350,
                                user_id: 2,
                                start: '00:00',
                                nc: '',
                                id: 2210,
                                day: '2022-10-10',
                                plates: null
                            }
                        ],
                        id: 14350,
                        day: '2022-10-10',
                        worktime: [
                            {
                                end: '00:00:00',
                                id: 16690,
                                start: '00:00:00',
                                task_description: '',
                                task_id: null,
                                user_id: 2,
                                wd_id: 14350
                            }
                        ]
                    })}
                    idx_day={-1}
                    idx_comm={1}
                    lockedData={false}
                    date={'11-10-2022'}
                    listWorkedCommissions={List([])}
                    mngData={List([])}
                    machines={List([])}
                    listUserFdl={List([])}
                    user={List([])}
                    onWDAdd={() => ''}
                    RemoveComm={() => ''}
                    onPropsCommChangeHandler={() => ''}
                    onWorkTimeAdd={() => ''}
                    onWorkTimeDelete={() => ''}
                    onWorkTimeChange={() => ''}
                    AddTravel={() => ''}
                    RemoveTravel={() => ''}
                    onPropsTravelChangeHandler={() => ''}
                    AddPurch={() => ''}
                    RemovePurch={() => ''}
                    onPropsPurchChangeHandler={() => ''}
                    onWtTaskAdd={() => ''}
                    onWtTaskDelete={() => ''}
                    onWtTaskChangeHandler={() => ''}
                    onPaste={() => ''}
                    countries={List([])}
                />
            </div>
            <div className={style.creation}>
                <TextField
                    label={'Primary color'}
                    defaultValue={'#'}
                    value={color1}
                    onChange={(e) => setColor1(e.target.value)}
                />
                <TextField
                    label={'Secondary color'}
                    defaultValue={'#'}
                    value={color2}
                    onChange={(e) => setColor2(e.target.value)}
                />
                <TextField
                    label={'Tertiary color'}
                    defaultValue={'#'}
                    value={color3}
                    onChange={(e) => setColor3(e.target.value)}
                />
                <Button onClick={() => {
                    dispatch(updateTheme(color1, color2, color3))
                }}
                >Applica</Button>
            </div>

        </>
    )
}

export default Themes
