import React from "react";
import './UserPopUp.scss'
import {ClickAwayListener, Paper} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


const UserPopUp = (props) => {

    const [popover, setPopover] = React.useState(false)


    const openPopover = () => {
        setPopover(!popover);
    }

    const closePopover = () => {
            setPopover(false);
    }

    return (
        <ClickAwayListener
            mouseEvent={"onMouseUp"}
            onClickAway={closePopover}>
            <div className={'userPopUp'}>
                <Avatar className={'avatar'} color={'secondary'}
                        onClick={openPopover}
                >{props.user.getIn(['userData', 'name'])[0]}</Avatar>

                <Paper
                    id="mouse-over-popover"
                    className={popover ? 'popUp popUpOpen' : 'popUp popUpClose'}
                    style={{zIndex: 3000}}
                >
                    <div className={'container'}>
                        <Avatar
                            className={'avatar'}>{props.user.getIn(['userData', 'name'])[0]}</Avatar>
                        <Typography
                            className={'username'}>{props.user.getIn(['userData', 'name']) + props.user.getIn(['userData', 'surname'])}</Typography>
                        <Typography
                            className={'email'}>{props.user.getIn(['userData', 'email'])}</Typography>
                        <Button className={'logoutUser'} variant="outlined"
                                onClick={props.logout}>Logout</Button>
                    </div>
                </Paper>
            </div>
        </ClickAwayListener>
    )
}

export default UserPopUp;