import React from "react";
import './EditWorkData.scss'
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Map} from "immutable";
import WorkData from "../../WorkList/WorkData/WorkData";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {onForceEditWorkdata, onWorkDataChange, weekWorkDataAdd} from "../../../store/actions/WorkData";

const purch = Map({
    currency: 0,
    description: "",
    id: -1,
    method: 0,
    nc: "",
    total: 0,
})


const trv = Map({
    km: 0,
    end: "00:00",
    highway: false,
    start: "00:00",
    nc: "",
    id: -1,
    plates: null,
})

const worktime = Map({
    end: "00:00",
    start: "00:00",
    id: -1,
    user_id: null,
    task_description: "",
    task_id: null,
    wd_id: null
})

const task = Map({
    description: "",
    duration: "00:00",
    id: -1,
    task_id: null,
    task_name: "",
    user_id: null,
    wk_id: null
})

class EditWorkData extends React.Component {

    state = {
        workdata: Map({})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && prevProps.open !== this.props.open) {
            this.setState({workdata: this.props.workdata})
        }
    }

    onPropsCommChangeHandler = (value, _, idx_comm, key) => {
        return new Promise((resolve, reject) => {
            if (key === 'fdl' && value !== '') {
                const integration = this.props.fdls.find(f => f.get('code') === value).get('integration')
                const nc = `${integration.get('client_code')}${integration.get('commission_code')}_${integration.get('integration_code')}`
                const clientNc = integration.get('client_comm')
                this.setState((oldState) => {
                    return {
                        workdata: oldState.workdata.update(c => c.merge({
                            [key]: value,
                            nc: nc,
                            client_nc: clientNc || oldState.workdata.getIn(['client_comm'])
                        }))
                    }
                })
                resolve(Map({nc: nc, client_nc: clientNc || ''}))
            }
            this.setState((oldState) => {
                return {workdata: oldState.workdata.updateIn([key], () => value)}
            })
            resolve(Map({}))
        })
    }
    onWorkTimeAdd = (_, idxComm) => {
        this.setState((oldState) => {
            return {
                workdata: oldState.workdata.updateIn(['worktime'], wt => wt.push(worktime.merge({
                    id: `new${new Date().getTime()}`,
                    user_id: this.state.workdata.get('user_id'),
                    wd_id: this.state.workdata.get('id'),
                })))
            }
        })
    }
    onWorkTimeDelete = (_, idxComm, idxWt) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['worktime'], wt => wt.delete(idxWt))}
        })
    }
    onWorkTimeChange = (value, _, idx_comm, idxWt, key) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['worktime', idxWt, key], () => value)}
        })
    }
    AddTravel = (_, idxComm) => {
        this.setState((oldState) => {
            return {
                workdata: oldState.workdata.updateIn(['travels'], t => t.push(trv.merge({
                    id: `new${new Date().getTime()}`,
                    user_id: this.state.workdata.get('user_id'),
                    wd_id: this.state.workdata.get('id'),
                })))
            }
        })
    }
    RemoveTravel = (_, idxComm, idxTrv) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['travels'], t => t.delete(idxTrv))}
        })
    }
    onPropsTravelChangeHandler = (value, _, idx_comm, idxTrv, key) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['travels', idxTrv, key], () => value)}
        })
    }

    AddPurch = (_, idxComm) => {
        this.setState((oldState) => {
            return {
                workdata: oldState.workdata.updateIn(['purchases'], p => p.push(purch.merge({
                    id: `new${new Date().getTime()}`,
                    user_id: this.state.workdata.get('user_id'),
                    wd_id: this.state.workdata.get('id'),
                })))
            }
        })
    }
    RemovePurch = (_, idxComm, idxPurch) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['purchases'], p => p.delete(idxPurch))}
        })
    }
    onPropsPurchChangeHandler = (value, _, idx_comm, idxPurch, key) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['purchases', idxPurch, key], () => value)}
        })
    }

    addTask = (_, idxComm) => {
        this.setState((oldState) => {
            return {
                workdata: oldState.workdata.updateIn(['tasks'], t => t.push(task.merge({
                    id: `new${new Date().getTime()}`,
                    user_id: this.state.workdata.get('user_id'),
                    wk_id: this.state.workdata.get('id'),
                })))
            }
        })
    }
    removeTasks = (_, idxComm, idxTask) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['tasks'], t => t.delete(idxTask))}
        })
    }
    onPropsTaskChangeHandler = (value, _, idx_comm, idxTask, key) => {
        this.setState((oldState) => {
            return {workdata: oldState.workdata.updateIn(['tasks', idxTask, key], () => value)}
        })
    }

    onSendData = () => {
        return new Promise(resolve => {

            let wd = this.state.workdata
            wd = wd.delete('last_update')
            wd = wd.update('worktime', (old) => old.map(wt => {
                if (wt.get('id').toString().includes('new')) {
                    wt = wt.delete('id')
                }
                return wt
            }))
            wd = wd.update('purchases', (old) => old.map(prc => {
                if (prc.get('id').toString().includes('new')) {
                    prc = prc.delete('id')
                }
                return prc.update('day', () => wd.get('day'))
            }))
            wd = wd.update('travels', (old) => old.map(trv => {
                if (trv.get('id').toString().includes('new')) {
                    trv = trv.delete('id')
                }
                return trv.update('day', () => wd.get('day'))
            }))
            wd = wd.update('tasks', (old) => old.map(ts => {
                if (ts.get('id').toString().includes('new')) {
                    ts = ts.delete('id')
                }
                return ts
            }))
            this.props.onForceEditWorkdata(wd).then(() =>
                resolve()
            )
        })
    }

    render() {

        return (
            <Dialog open={this.props.open} className={'weekCompilationPopUp'}
                    onClose={this.props.handleClose}
            >
                <DialogTitle>Forzatura modifica</DialogTitle>
                <Divider/>
                <DialogContent className={'content'}>
                    <div className={'wtData'}>
                        {this.state.workdata.get('id') &&
                        <WorkData
                            className={'wt'}
                            key={this.state.workdata.get('id')}
                            hideTasks={false}
                            hideCommCmd={true}
                            lockedData={false}
                            lockRemoveComm={this.state.workdata.size <= 1}
                            workdata={this.state.workdata}
                            idx_day={-1}
                            idx_comm={-1}
                            date={this.props.day}
                            listWorkedCommissions={this.props.listWorkedCommissions}
                            mngData={this.props.mngData}
                            machines={this.props.machines}
                            listUserFdl={this.props.fdls}
                            user={this.props.user}
                            onWDAdd={this.AddComm}
                            RemoveComm={this.RemoveComm}
                            onPropsCommChangeHandler={this.onPropsCommChangeHandler}
                            onWorkTimeAdd={this.onWorkTimeAdd}
                            onWorkTimeDelete={this.onWorkTimeDelete}
                            onWorkTimeChange={this.onWorkTimeChange}
                            AddTravel={this.AddTravel}
                            RemoveTravel={this.RemoveTravel}
                            onPropsTravelChangeHandler={this.onPropsTravelChangeHandler}
                            AddPurch={this.AddPurch}
                            RemovePurch={this.RemovePurch}
                            onPropsPurchChangeHandler={this.onPropsPurchChangeHandler}
                            onWtTaskAdd={this.addTask}
                            onWtTaskDelete={this.removeTasks}
                            onWtTaskChangeHandler={this.onPropsTaskChangeHandler}
                            countries={this.props.countries}
                        />
                        }
                    </div>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant={"text"} onClick={this.props.onClose}>Annulla</Button>
                    <Button variant={"contained"}
                            onClick={() => this.onSendData().then(() => this.props.onClose())}>Salva</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        listWorkedCommissions: state.userData.list_worked_commissions,
        mngData: state.mng_data.mng_data,
        machines: state.mng_data.machines,
        fdls: state.userData.fdls,
        countries: state.meta.countries,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        weekWorkTimeAdd: (...args) => dispatch(weekWorkDataAdd(...args)),
        onPropsCommChangeHandler: (...args) => dispatch(onWorkDataChange(...args)),
        onForceEditWorkdata: (...args) => dispatch(onForceEditWorkdata(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkData)