export const DbKey = 'id';
export const JKey = 'j_key';
export const Sync = 'sync';
export const Day = 'day';

export const Nc = 'nc';
export const Descrizione = 'description';
export const Note = 'note';
export const Totale = 'total';
export const Metodo = 'method';
export const Valuta = 'currency';

