import React, {useState} from "react";
import style from './MonthPicker.module.scss'
import {ButtonBase, Paper, Popper, Typography} from "@mui/material";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import YearPicker from "../YearPicker/YearPicker";

const MonthPicker = ({value, onChange, onClose}) => {

    const _value = value.clone()

    const [popperState, setPopperState] = useState(false)
    const [popperAnchor, setPopperAnchor] = useState(null)


    return (
        <>
            <ButtonBase
                className={style.monthPicker}
                onClick={e => {
                    setPopperState(old => !old)
                    setPopperAnchor(e.currentTarget)
                }}
            >
                <Paper variant={"outlined"} className={style.text}>
                    <CalendarMonthRoundedIcon className={style.icon}/>
                    <Typography className={style.value}>{_value.format('MMMM YYYY')}</Typography>

                </Paper>
            </ButtonBase>
            <Popper
                className={style.popper}
                open={popperState}
                anchorEl={popperAnchor}
                placement={'bottom-end'}>
                <Paper style={{backgroundImage:'none'}}>
                     <YearPicker
                         value={value}
                            onYearChange={(v) => {
                                const c = _value.year(v)
                                onChange(c)
                            }}
                            onMonthChange={(v) => {
                                onChange(_value.month(v))
                                setPopperState(false)
                            }}
                        />
                </Paper>
            </Popper>
        </>
    )
}

export default MonthPicker;