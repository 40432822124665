import React from "react";
import './UsrCommission.css'
import {ButtonBase, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";

const UsrCommission = (props) => {


    return (
            <ButtonBase className={'usrCommission'} onClick={() => props.handleChangeComm(props.comm.get('nc'))}>
                <Paper className={'usrCommissionPaper'}>
                    <Typography className={'code'}>{props.comm.get('nc')}</Typography>
                    <Typography className={'description'}>{props.clientDetails.getIn([0, 'company_name'])}</Typography>
                    <Typography className={'description'}
                                style={{color: !props.comm.get('description') ? '#ffab00' : '#FFF'}}>{props.comm.get('description') || 'Nessuna descrizione disponibile'}</Typography>


                </Paper>
            </ButtonBase>
    )
}

export default UsrCommission;